import Loader from "common/Loader"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { toast } from "react-toastify"
import { axiosInstance } from "ConfigAxioxinstance"
import BootstrapTable from "react-bootstrap-table-next"
import { Link, useLocation } from "react-router-dom"
import { ticketStatus } from "constants/ConstantFields"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ReactSelect from "constants/ReactSelect"
import AttachmentPreview from "./Components/AttachmentPreview"
import ActionModal from "./Components/ActionModal"
import AuditTrail from "common/AuditTrail/AuditTrail"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const DataChangeRequests = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)

  const [addUpdateModal, setAddUpdateModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [reAssignModal, setReAssignModal] = useState(false)
  const [isReview, setIsReview] = useState(false)

  const [formData, setFormData] = useState({
    subject: "",
    description: "",
  })
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [internalTeam, setInternalTeam] = useState()
  const [selectedAssigneeId, setSelectedAssigneeId] = useState()
  const [actionModal, setActionModal] = useState("")
  const [isActionModalOpen, setIsActionModalOpen] = useState(false)
  const [isEditTicket, setIsEditTicket] = useState(false)
  const [resetFileInput, setResetFileInput] = useState(0)
  const [ticketStatusFilter, setTicketStatusFilter] = useState()
  const [searchTicketFilter, setSearchTicketFilter] = useState()
  const [assigneeFilter, setAssigneeFilter] = useState()
  const [createdByFilter, setCreatedByFilter] = useState()
  const [deleteImages, setDeleteImages] = useState([])
  const [ticketReviewStatus, setTicketReviewStatus] = useState(null)
  const [rejectionReason, setRejectionReason] = useState(null)
  const [reopenReason, setReopenReason] = useState(null)
  const [reopenModal, setReopenModal] = useState(false)

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  // Audit Logs State
  const [selectedLogId, setSelectedLogId] = useState("")
  const [auditTrailModal, setAuditTrailModal] = useState(false)

  const localUser = JSON.parse(localStorage.getItem("userInfo"))

  const filterUrl = `${
    ticketStatusFilter?.id ? `&status=${ticketStatusFilter.id}` : ""
  }${
    searchTicketFilter && searchTicketFilter.length > 3
      ? `&search=${searchTicketFilter}`
      : ""
  }${assigneeFilter ? `&assignedToId=${assigneeFilter.id}` : ""}${
    createdByFilter ? `&createdById=${createdByFilter.id}` : ""
  }${
    localUser?.permissions !== "Admin"
      ? `&$or[0][createdById]=${localUser?.id}&$or[1][assignedToId]=${localUser?.id}`
      : ""
  }`

  const getUrl = `tickets?$sort[created_at]=-1&$limit=${limit}&$skip=${skip}${filterUrl}`

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const ticketIdFromUrl = queryParams.get("ticket")

  const masterData = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(getUrl)
      if (response) {
        setData(response.data?.data || response.data)

        setTotal(response.data.total)

        if (ticketIdFromUrl) {
          const ticketData = response.data?.data.find(
            item => item.ticket_id === `#${ticketIdFromUrl}`
          )
          const isAdmin = localUser?.permissions === "Admin"
          if (ticketData) {
            if (isAdmin && ticketData.status === "OPEN") {
              setIsReview(true)
            }
            toggleViewModal(ticketData)
          }
        }

        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    masterData()
  }, [limit, skip, ticketStatusFilter, assigneeFilter, createdByFilter])

  useEffect(() => {
    getInternalUsersList()
  }, [])

  useEffect(() => {
    if (searchTicketFilter && searchTicketFilter.length > 3) {
      masterData()
    }
  }, [searchTicketFilter])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "ticketStatus":
        setTicketStatusFilter(e)
        break
      case "search":
        setSearchTicketFilter(e?.target?.value)
        break
      case "createdBy":
        setCreatedByFilter(e)
        break
      case "assignedTo":
        setAssigneeFilter(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const handleFileChange = e => {
    const newFiles = Array.from(e.target.files)

    const totalFiles = isEditTicket
      ? (selectedTicket?.ticket_files?.length || 0) +
        (selectedFiles?.length || 0) +
        newFiles.length
      : (selectedFiles || []).length + newFiles.length

    if (totalFiles > 5) {
      toast.warning("You can only upload a maximum of 5 files.")

      const remainingSlots = isEditTicket
        ? 5 -
          (selectedTicket?.ticket_files?.length || 0) -
          (selectedFiles?.length || 0)
        : 5 - (selectedFiles || []).length

      const limitedNewFiles = newFiles.slice(0, remainingSlots)

      setSelectedFiles(prevFiles => {
        const currentFiles = prevFiles || []
        return [...currentFiles, ...limitedNewFiles]
      })
    } else {
      setSelectedFiles(prevFiles => {
        const currentFiles = prevFiles || []
        return [...currentFiles, ...newFiles]
      })
    }
  }

  const DataColumns = () => [
    {
      dataField: "",
      isDummyField: true,
      text: "Actions",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const createdByMe = localUser?.email === row?.createdBy?.email
        const isAdmin = localUser?.permissions === "Admin"
        const isAssignedToUser = localUser?.email === row?.assignedTo?.email
        const canAssign = row.status === "OPEN"

        return (
          <div className="d-flex gap-3">
            {createdByMe && row.status === "OPEN" && (
              <>
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    toggleUpdateModal(row)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                </Link>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </>
            )}
            {createdByMe && row.status === "OPEN" && (
              <>
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    toggleActionModal("delete", row)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deleteToolTip"
                  />
                </Link>
                <UncontrolledTooltip placement="top" target="deleteToolTip">
                  Delete
                </UncontrolledTooltip>
              </>
            )}
            {createdByMe && row.status === "RESOLVED" && (
              <Link to="#" className="text-success">
                <Button
                  type="button"
                  color="success"
                  outline
                  className="btn-md btn-rounded"
                  onClick={() => toggleReopenModal(row)}
                >
                  Re-Open
                </Button>
              </Link>
            )}
            {isAdmin && (
              <>
                {canAssign && (
                  <Link to="#" className="text-success">
                    <Button
                      type="button"
                      color="warning"
                      outline
                      className="btn-md btn-rounded"
                      onClick={() => {
                        toggleViewModal(row)
                        setIsReview(true)
                        setRejectionReason(null)
                        setSelectedAssigneeId(null)
                        setTicketReviewStatus(null)
                      }}
                    >
                      Review
                    </Button>
                  </Link>
                )}
                {["IN_PROGRESS", "RESOLVED"].includes(row.status) && (
                  <Link to="#" className="text-success">
                    <Button
                      type="button"
                      color="danger"
                      outline
                      className="btn-md btn-rounded"
                      onClick={() => {
                        toggleViewModal(row)
                        setIsReview(true)
                        setTicketReviewStatus("reject")
                      }}
                    >
                      Reject
                    </Button>
                  </Link>
                )}
                {/* {row.status === "APPROVED" && (
                  <Link to="#" className="text-success">
                    <Button
                      type="button"
                      color="primary"
                      outline
                      className="btn-md btn-rounded"
                      onClick={() => {
                        toggleReAssignModal(row)
                      }}
                    >
                      Assign
                    </Button>
                  </Link>
                )} */}
                {/* {row.status === "RESOLVED" && (
                  <Link to="#" className="text-success">
                    <Button
                      type="button"
                      color="success"
                      outline
                      className="btn-md btn-rounded"
                      onClick={() => {
                        toggleActionModal("close", row)
                      }}
                    >
                      Close
                    </Button>
                  </Link>
                )} */}
              </>
            )}
            {isAssignedToUser && (
              <>
                {row.status === "IN_PROGRESS" && (
                  <Link to="#" className="text-success">
                    <Button
                      type="button"
                      color="warning"
                      outline
                      className="btn-md btn-rounded"
                      onClick={() => toggleActionModal("resolve", row)}
                    >
                      Mark As Resolved
                    </Button>
                  </Link>
                )}
                {row.status === "IN_PROGRESS" && (
                  <Link to="#" className="text-success">
                    <Button
                      type="button"
                      color="success"
                      outline
                      className="btn-md btn-rounded"
                      onClick={() => toggleReAssignModal(row)}
                    >
                      Re-assign
                    </Button>
                  </Link>
                )}
              </>
            )}
          </div>
        )
      },
    },
    {
      dataField: "ticket_id",
      text: "Ticket Id",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        return (
          <Link
            onClick={() => {
              toggleViewModal(row)
              setIsReview(false)
              setTicketReviewStatus(null)
            }}
          >
            {cellContent}
          </Link>
        )
      },
    },
    {
      dataField: "subject",
      text: "Subject",
      sort: true,
      formatter: (cellContent, row) =>
        row.subject.length > 50
          ? row.subject.substring(0, 50) + "..."
          : row.subject,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      formatter: (cellContent, row) =>
        row.description.length > 50
          ? row.description.substring(0, 50) + "..."
          : row.description,
    },
    {
      dataField: "assignedTo.user_name",
      text: "Assigned To Name",
      sort: true,
    },
    {
      dataField: "assignedTo.email",
      text: "Assigned To Email",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = ticketStatus.find(obj => obj.id == cellContent)
        return (
          <span
            className={`badge badge-pill font-size-12 badge-soft-${
              status ? status?.colorClassName : "secondary"
            }`}
          >
            {status?.statusText || cellContent}
          </span>
        )
      },
    },
    {
      dataField: "createdBy.user_name",
      text: "Created By Name",
      sort: true,
      formatter: (cellContent, row) => row?.createdBy?.user_name || "Admin",
    },
    {
      dataField: "createdBy.email",
      text: "Created By Email",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "rejected_reason",
      text: "Rejected Reason",
      sort: true,
      formatter: (cellContent, row) =>
        row?.rejected_reason?.length > 50
          ? row.rejected_reason.substring(0, 50) + "..."
          : row.rejected_reason,
    },
    {
      dataField: "reopen_reason",
      text: "Re-Open Reason",
      sort: true,
      formatter: (cellContent, row) =>
        row?.reopen_reason?.length > 50
          ? row.reopen_reason.substring(0, 50) + "..."
          : row.reopen_reason,
    },
    {
      dataField: "",
      text: "Logs",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (localUser?.permissions === "Admin") {
          return (
            <>
              <Link
                to="#"
                onClick={() => {
                  setSelectedLogId(row.id)
                  setAuditTrailModal(!auditTrailModal)
                }}
              >
                <i
                  className="mdi mdi-content-paste font-size-18"
                  id="logsToolTip"
                />
              </Link>
              <UncontrolledTooltip placement="top" target="logsToolTip">
                Logs
              </UncontrolledTooltip>
            </>
          )
        } else {
          return "N/A"
        }
      },
    },
  ]

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleAddButtonClick = () => {
    setFormData({
      subject: "",
      description: "",
    })
    setSelectedFiles([])
    toggleAddModal()
  }

  const uploadAttachmentHandler = async files => {
    try {
      const formData = new FormData()
      files.forEach(file => {
        formData.append("file", file)
      })

      const uploadResponse = await axiosInstance.post(
        `/file-upload/?type=support_portal`,
        formData
      )
      return uploadResponse.data.map((fileData, index) => ({
        file_url: fileData.name,
        file_name: files[index].name,
        mime_type: files[index].type,
        file_size: files[index].size,
      }))
    } catch (error) {
      toast.error(error?.message)
      throw error
    }
  }

  const handleValidSubmit = async (e, values) => {
    try {
      setLoading(true)

      const uploadedAttachments = selectedFiles?.length
        ? await uploadAttachmentHandler(selectedFiles)
        : []

      const payload = {
        subject: formData.subject,
        description: formData.description,
        ...(uploadedAttachments.length && { attachments: uploadedAttachments }),
      }

      if (selectedTicket && deleteImages?.length) {
        await Promise.all(deleteImages.map(item => deleteAttachment(item)))
        setDeleteImages([])
      }

      const response = selectedTicket
        ? await axiosInstance.patch(`/tickets/${selectedTicket?.id}`, payload)
        : await axiosInstance.post("/tickets", payload)

      if (response) {
        toast.success(
          selectedTicket
            ? "Ticket Updated Successfully"
            : "Ticket Created Successfully"
        )

        masterData()
        toggleAddModal()
        setSelectedTicket()
        setSelectedFiles([])
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "An error occurred"
      )
    } finally {
      setLoading(false)
    }
  }

  const updateTicketStatusHandler = async type => {
    try {
      setLoading(true)
      let response = {}

      switch (type) {
        case "re-assign":
          response = await axiosInstance.patch(
            `/tickets/${selectedTicket?.id}`,
            {
              status: "IN_PROGRESS",
              assignedToId: selectedAssigneeId,
              type: "re-assign",
              reassignByName: selectedTicket?.assignedTo?.user_name,
              reassignByEmail: selectedTicket?.assignedTo?.email,
            }
          )
          break

        case "assign":
          response = await axiosInstance.patch(
            `/tickets/${selectedTicket?.id}`,
            {
              status: "IN_PROGRESS",
              assignedToId: selectedAssigneeId,
              type: "assign",
            }
          )
          break

        case "re-open":
          response = await axiosInstance.patch(
            `/tickets/${selectedTicket?.id}`,
            {
              status: "IN_PROGRESS",
              type: "re-open",
              reopen_reason: reopenReason,
            }
          )
          break

        case "close":
          response = await axiosInstance.patch(
            `/tickets/${selectedTicket?.id}`,
            {
              status: "CLOSED",
            }
          )
          break

        case "resolve":
          response = await axiosInstance.patch(
            `/tickets/${selectedTicket?.id}`,
            {
              status: "RESOLVED",
            }
          )
          break

        case "reject":
          response = await axiosInstance.patch(
            `/tickets/${selectedTicket?.id}`,
            {
              status: "REJECTED",
              rejected_reason: rejectionReason,
            }
          )
          break

        default:
          break
      }

      if (response) {
        toast.success("Ticket Status Updated")
        masterData()

        switch (type) {
          case "re-assign":
            toggleReAssignModal()
            break

          case "close":
            toggleActionModal("close")
            break

          case "resolve":
            toggleActionModal("resolve")
            break

          case "re-open":
            toggleReopenModal()
            break

          case "assign":
            toggleViewModal()
            setIsReview(false)
            break

          case "reject":
            toggleViewModal()
            setIsReview(false)
            break

          default:
            break
        }

        setSelectedTicket(null)
        setSelectedFiles([])
      }
    } catch (error) {
      toast.error(error?.message || "An error occurred")
    } finally {
      setLoading(false)
    }
  }

  const deleteTicketHandler = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.delete(
        `/tickets/${selectedTicket?.id}`
      )
      if (response) {
        toast.success("Ticket Deleted Successfully")
        masterData()
        setSelectedTicket(null)
        toggleActionModal("delete")
      }
    } catch (error) {
      toast.error(error?.message || "An error occurred")
    } finally {
      setLoading(false)
    }
  }

  const removeAttachment = item => {
    setDeleteImages(prev => [...prev, item])
    const updatedTicketFiles = selectedTicket.ticket_files.filter(
      file => file.id !== item.id
    )
    setSelectedTicket({
      ...selectedTicket,
      ticket_files: updatedTicketFiles,
    })
  }

  const deleteAttachment = async item => {
    try {
      await axiosInstance.delete(`/ticket-files/${item.id}`)
    } catch (error) {
      toast.error(error?.message)
      throw error
    }
  }

  const removeSelectedFile = async item => {
    setSelectedFiles(selectedFiles.filter(f => f !== item))
    setResetFileInput(prev => prev + 1)
  }

  const getInternalUsersList = async () => {
    try {
      setLoading(true)
      const getResp = await axiosInstance.get(`/users?permissions=OPS_USER&`)
      if (getResp) {
        const userResponse = getResp.data?.data || getResp.data
        const withoutDistributor = userResponse.filter(
          item => item.role.department !== "Distributor"
        )
        setInternalTeam(withoutDistributor)
        setLoading(false)
      }
    } catch (error) {}
  }

  const toggleUpdateModal = async ticket => {
    setAddUpdateModal(!addUpdateModal)
    setSelectedTicket(ticket)
    setIsEditTicket(!isEditTicket)
    setFormData({
      subject: ticket.subject,
      description: ticket.description,
    })
    if (ticket && ticket?.ticket_files && ticket?.ticket_files?.length > 0) {
      setSelectedTicket(ticket)
    }
  }

  const toggleAddModal = () => {
    setIsEditTicket(false)
    setSelectedTicket()
    setAddUpdateModal(!addUpdateModal)
  }

  const toggleViewModal = async ticket => {
    if (ticket && ticket?.ticket_files?.length > 0) {
      setSelectedTicket(ticket)
    } else {
      setSelectedTicket(ticket)
    }
    setViewModal(!viewModal)
  }

  const toggleReAssignModal = row => {
    setReAssignModal(!reAssignModal)
    setSelectedTicket(row)
  }

  const toggleActionModal = (modalType, ticket = null) => {
    setSelectedTicket(ticket)
    setActionModal(modalType)
    setIsActionModalOpen(!isActionModalOpen)
  }

  const toggleReopenModal = (ticket = null) => {
    setSelectedTicket(ticket)
    setReopenModal(!reopenModal)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.SUPPORT_PORTAL_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <ToolkitProvider
                  keyField="id"
                  data={data}
                  columns={DataColumns()}
                  bootstrap4
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2 row justify-content-between">
                        <Col md={2}>
                          <select
                            className="form-select w-75"
                            value={limit}
                            onChange={e => handleFilterChange(e, "limit")}
                          >
                            {[10, 30, 50, 100].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                              </option>
                            ))}
                          </select>
                        </Col>

                        {accessControlData?.permissions?.SUPPORT_PORTAL_ADD && (
                          <div className="col-auto">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                              onClick={handleAddButtonClick}
                            >
                              <i className="mdi mdi-plus me-1" />{" "}
                              {
                                accessControlData?.permissions
                                  ?.SUPPORT_PORTAL_ADD
                              }
                            </Button>
                          </div>
                        )}
                      </Row>
                      <Card>
                        <CardBody>
                          <Row className="mb-2 row">
                            <Col md={3}>
                              <div className="position-relative">
                                <label>Search Ticket</label>
                                <input
                                  onChange={e =>
                                    handleFilterChange(e, "search")
                                  }
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Ticket Subject or Description...`}
                                  value={searchTicketFilter || ""}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Ticket Status</label>
                                <ReactSelect
                                  users={ticketStatus}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "ticketStatus")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["statusText"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Created By</label>
                                <ReactSelect
                                  users={internalTeam}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "createdBy")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["user_name", "email"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Assigned To</label>
                                <ReactSelect
                                  users={internalTeam}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "assignedTo")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["user_name", "email"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}`}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
        <Modal
          isOpen={addUpdateModal}
          toggle={isEditTicket ? toggleUpdateModal : toggleAddModal}
        >
          <ModalHeader
            toggle={isEditTicket ? toggleUpdateModal : toggleAddModal}
            tag="h4"
          >
            {isEditTicket ? "Edit Ticket" : "Add New Ticket"}
          </ModalHeader>
          <ModalBody>
            <AvForm onValidSubmit={handleValidSubmit}>
              <Row form>
                <Col className="col-12 mb-3">
                  <AvField
                    name="subject"
                    label="Subject"
                    type="text"
                    errorMessage="Invalid value"
                    validate={{
                      required: { value: true },
                    }}
                    value={formData.subject}
                    onChange={e =>
                      setFormData({ ...formData, subject: e.target.value })
                    }
                  ></AvField>
                </Col>
                <Col className="col-12 mb-3">
                  <AvField
                    name="description"
                    label="Description"
                    type="textarea"
                    errorMessage="Invalid value"
                    validate={{
                      required: { value: true },
                    }}
                    value={formData.description}
                    onChange={e =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                    rows={5}
                  ></AvField>
                </Col>
                <Col className="col-12 mb-3">
                  <AvField
                    key={resetFileInput}
                    className="form-control"
                    type="file"
                    id="formFile"
                    validate={{
                      required: {
                        value: false,
                      },
                    }}
                    name="Attachments"
                    label="Select File"
                    multiple
                    onChange={handleFileChange}
                  ></AvField>
                  <p className="mt-2">Maximum 5 files are allowed</p>
                </Col>
                {isEditTicket &&
                  selectedTicket?.ticket_files &&
                  selectedTicket?.ticket_files.length > 0 && (
                    <AttachmentPreview
                      readOnly={false}
                      onFileDelete={removeAttachment}
                      files={selectedTicket?.ticket_files}
                    />
                  )}
                {selectedFiles && selectedFiles?.length > 0 && (
                  <AttachmentPreview
                    readOnly={false}
                    onFileDelete={removeSelectedFile}
                    files={selectedFiles}
                  />
                )}
              </Row>
              <Col className="mt-3 col-12">
                <div className="d-flex justify-content-end gap-2">
                  <Button
                    color="secondary"
                    outline
                    onClick={isEditTicket ? toggleUpdateModal : toggleAddModal}
                  >
                    Cancel
                  </Button>
                  <button type="submit" className="btn btn-success">
                    {isEditTicket ? "Update" : "Submit"}
                  </button>
                </div>
              </Col>
            </AvForm>
          </ModalBody>
        </Modal>
        {/* View and Review Ticket Modal */}
        <Modal isOpen={viewModal} toggle={toggleViewModal}>
          <ModalHeader toggle={toggleViewModal} tag="h4">
            {isReview ? "Review Ticket" : "View Ticket"}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-12">
                <h6>Subject</h6>
                <p>{selectedTicket?.subject}</p>
              </Col>
              <Col className="col-12">
                <h6>Description</h6>
                <p>{selectedTicket?.description}</p>
              </Col>
              {selectedTicket?.ticket_files &&
                selectedTicket?.ticket_files.length > 0 && (
                  <Col className="col-12">
                    <h6>Attachments</h6>
                    <AttachmentPreview
                      files={selectedTicket?.ticket_files}
                      readOnly
                    />
                  </Col>
                )}
              <Col className="col-12">
                <h6>Created By</h6>
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <p className="mb-0">
                      {selectedTicket?.createdBy?.user_name || "Admin"}
                    </p>
                    <p>{selectedTicket?.createdBy?.email}</p>
                  </div>
                  <p>
                    {moment(selectedTicket?.created_at).format(
                      "DD MMM YYYY HH:mm:ss"
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              {selectedTicket?.rejected_reason && (
                <>
                  <h6>Rejected Reason</h6>
                  <p>{selectedTicket?.rejected_reason}</p>
                </>
              )}
              {!selectedTicket?.rejected_reason &&
                selectedTicket?.reopen_reason && (
                  <>
                    <h6>Re-Open Reason</h6>
                    <p>{selectedTicket?.reopen_reason}</p>
                  </>
                )}
              {isReview && (
                <Col
                  className={`${
                    !["APPROVED", "IN_PROGRESS", "RESOLVED"].includes(
                      selectedTicket?.status
                    ) && "mb-3 col-12"
                  }`}
                >
                  {!["APPROVED", "IN_PROGRESS", "RESOLVED"].includes(
                    selectedTicket?.status
                  ) && (
                    <>
                      <h5>Please choose an appropriate action</h5>
                      <div className="d-flex align-items-center mt-3">
                        <div className="me-3 d-flex align-items-center">
                          <input
                            type="radio"
                            name="ticket_review"
                            id="approve"
                            onChange={() => setTicketReviewStatus("approve")}
                          />
                          <label htmlFor="approve" className="mb-0 ms-1">
                            Approve
                          </label>
                        </div>
                        <div className="d-flex align-items-center">
                          <input
                            type="radio"
                            name="ticket_review"
                            id="reject"
                            onChange={() => setTicketReviewStatus("reject")}
                          />
                          <label htmlFor="reject" className="mb-0 ms-1">
                            Reject
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                  {ticketReviewStatus === "approve" && (
                    <>
                      <AvForm className="mt-3">
                        <label>Assign</label>
                        <ReactSelect
                          setSelectedOption={e => setSelectedAssigneeId(e?.id)}
                          users={internalTeam?.filter(
                            item =>
                              item.email !== selectedTicket?.createdBy?.email
                          )}
                          multiOptionLabel={true}
                          optionLabelKeys={["user_name", "email"]}
                          isClearable={true}
                        />
                      </AvForm>
                      <div className="col-auto text-end mt-3">
                        <Button
                          type="button"
                          onClick={() => updateTicketStatusHandler("assign")}
                          color="success"
                          className={`btn-md save-user`}
                          disabled={!selectedAssigneeId}
                        >
                          Save
                        </Button>
                      </div>
                    </>
                  )}
                  {ticketReviewStatus === "reject" && (
                    <>
                      <AvForm
                        className={`${
                          !["APPROVED", "IN_PROGRESS", "RESOLVED"].includes(
                            selectedTicket?.status
                          ) && "mt-3"
                        }`}
                      >
                        <AvField
                          name="description"
                          label="Reason"
                          type="textarea"
                          errorMessage="Invalid value"
                          validate={{
                            required: { value: true },
                          }}
                          value={rejectionReason || ""}
                          onChange={e => setRejectionReason(e.target.value)}
                          rows={3}
                        ></AvField>
                      </AvForm>
                      <div className="col-auto text-end mt-3">
                        <Button
                          type="button"
                          color="success"
                          className={`btn-md save-user`}
                          onClick={() => updateTicketStatusHandler("reject")}
                          disabled={
                            rejectionReason?.length === 0 || !rejectionReason
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </>
                  )}
                  {/* <div className="d-flex justify-content-end gap-2">
                      <Button
                        color="danger"
                        outline={
                          !["APPROVED", "IN_PROGRESS"].includes(
                            selectedTicket?.status
                          )
                        }
                        onClick={() => updateTicketStatusHandler("reject")}
                      >
                        Reject
                      </Button>
                      {!["APPROVED", "IN_PROGRESS"].includes(
                        selectedTicket?.status
                      ) && (
                        <Button
                          color="success"
                          onClick={() => updateTicketStatusHandler("approve")}
                        >
                          Approve
                        </Button>
                      )}
                    </div> */}
                </Col>
              )}
            </Row>
          </ModalBody>
        </Modal>
        {/* Reassign Ticket Modal */}
        <Modal isOpen={reAssignModal} toggle={toggleReAssignModal}>
          <ModalHeader toggle={toggleReAssignModal} tag="h4">
            Re-Assign Ticket
          </ModalHeader>
          <ModalBody>
            <AvForm
              onValidSubmit={() => updateTicketStatusHandler("re-assign")}
            >
              <div className="mb-3">
                <label>Select User</label>
                <ReactSelect
                  setSelectedOption={e => setSelectedAssigneeId(e?.id)}
                  users={internalTeam?.filter(
                    item =>
                      item.email !== selectedTicket?.createdBy?.email &&
                      item.email !== selectedTicket?.assignedTo?.email
                  )}
                  multiOptionLabel={true}
                  optionLabelKeys={["user_name", "email"]}
                  isClearable={true}
                />
              </div>
              <div className="col-auto text-end">
                <Button
                  type="submit"
                  color="success"
                  className={`btn-md save-user`}
                >
                  Save
                </Button>
              </div>
            </AvForm>
          </ModalBody>
        </Modal>

        {/* ReOpen Ticket Modal */}
        <Modal isOpen={reopenModal} toggle={toggleReopenModal}>
          <ModalHeader toggle={toggleReopenModal} tag="h4">
            Re-Open Ticket
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-12">
                <h6>Subject</h6>
                <p>{selectedTicket?.subject}</p>
              </Col>
              <Col className="col-12">
                <h6>Description</h6>
                <p>{selectedTicket?.description}</p>
              </Col>
              {selectedTicket?.ticket_files &&
                selectedTicket?.ticket_files.length > 0 && (
                  <Col className="col-12">
                    <h6>Attachments</h6>
                    <AttachmentPreview
                      files={selectedTicket?.ticket_files}
                      readOnly
                    />
                  </Col>
                )}
              <Col className="col-12">
                <h6>Created By</h6>
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <p className="mb-0">
                      {selectedTicket?.createdBy?.user_name || "Admin"}
                    </p>
                    <p>{selectedTicket?.createdBy?.email}</p>
                  </div>
                  <p>
                    {moment(selectedTicket?.created_at).format(
                      "DD MMM YYYY HH:mm:ss"
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <AvForm>
                <AvField
                  name="description"
                  label="Reason"
                  type="textarea"
                  errorMessage="Invalid value"
                  validate={{
                    required: { value: true },
                  }}
                  value={reopenReason || ""}
                  onChange={e => setReopenReason(e.target.value)}
                  rows={3}
                ></AvField>
              </AvForm>
              <div className="d-flex justify-content-end gap-2 mt-3">
                <button
                  type="submit"
                  onClick={() => updateTicketStatusHandler("re-open")}
                  className="btn btn-success"
                >
                  Save
                </button>
              </div>
            </Row>
          </ModalBody>
        </Modal>
        <AuditTrail
          recordId={selectedLogId}
          modal={auditTrailModal}
          setModal={setAuditTrailModal}
        />
        <ActionModal
          modalType={actionModal}
          isOpen={isActionModalOpen}
          toggleModal={toggleActionModal}
          updateTicketStatusHandler={updateTicketStatusHandler}
          deleteTicketHandler={deleteTicketHandler}
          ticketStatus={ticketStatus}
        />
      </div>
    </React.Fragment>
  )
}

export default DataChangeRequests
