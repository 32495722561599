import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import { Link } from "@material-ui/core"
import { csvDownloadData, generateFinancialYearList } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"
import ReactSelect from "constants/ReactSelect"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ReactMultiSelect from "constants/ReactMultiSelect"
import DatePicker from "react-multi-date-picker"
import Toolbar from "react-multi-date-picker/plugins/toolbar"
import DatePanel from "react-multi-date-picker/plugins/date_panel"

const QuarterValues = [
  {
    id: 1,
    value: 1,
    label: "First Quarter (Q1)",
  },
  {
    id: 2,
    value: 2,
    label: "Second Quarter (Q2)",
  },
  {
    id: 3,
    value: 3,
    label: "Third Quarter (Q3)",
  },
  {
    id: 4,
    value: 4,
    label: "Fourth Quarter (Q4)",
  },
]

const DistributorTDSCertificate = () => {
  const [loading, setLoading] = useState(false)
  const [tableData, setTableData] = useState([])

  const [limit, setLimit] = useState(10)
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [file, setFile] = useState()
  const [uploadModal, setUploadModal] = useState(false)
  const [mapModal, setMapModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [emailModalNumber, setEmailModalNumber] = useState(1)
  const [selectedUserOption, setSelectedUserOption] = useState("")
  const [selectedDistributor, setSelectedDistributor] = useState("")
  const [userFilter, setUserFilter] = useState(null)
  const [quarterFilter, setQuarterFilter] = useState(null)
  const [emailSentFilter, setEmailSentFilter] = useState(null)
  const [financialYearFilter, setFinancialYearFilter] = useState(null)
  const [emailData, setEmailData] = useState()
  const [uploadedZipData, setUploadedZipData] = useState()
  const [accessControlData, setAccessControlData] = useState([])
  const [uploadType, setUploadType] = useState()
  const [distributorList, setDistributorList] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [review, setReview] = useState(false)
  const [emailConsent, setEmailConsent] = useState(false)
  const [faultyPanNos, setFaultyPanNos] = useState([])
  const [selectedUploadDate, setSelectedUploadDate] = useState([])
  const [sendEmailData, setSendEmailData] = useState()
  const [excludedUsers, setExcludedUsers] = useState([])
  const [selectedUserToSendEmail, setSelectedUserToSendEmail] = useState([])
  const [filteredSendEmailData, setFilteredSendEmailData] = useState(null)

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isDist = localUser?.role?.department == "Distributor"

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const defaultSorted = [
    {
      dataField: "created_at",
      order: "desc",
    },
  ]
  // Table Columns
  const TableColumns = () => {
    let columns = []
    if (!isDist) {
      columns.push({
        dataField: "",
        isDummyField: true,
        text: "Action",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          !row.user &&
          accessControlData?.permissions?.DISTRIBUTOR_TDS_CERTIFICATE_MAP ? (
            <Button
              type="button"
              color="primary"
              outline
              className="btn-md btn-rounded"
              onClick={() => {
                toggleMapModal()
                getDistributorUsers(row)
                setSelectedRow(row)
              }}
            >
              {accessControlData?.permissions?.DISTRIBUTOR_TDS_CERTIFICATE_MAP}
            </Button>
          ) : (
            <span className="d-block text-center">N/A</span>
          ),
      })
    }
    if (!isDist) {
      columns.push({
        dataField: "legal_entity_name",
        text: "Legal Entity Name",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => order?.user?.distributor_kyc?.name,
      })
    }
    columns.push({ dataField: "pan_number", text: "Pan Number", sort: true })
    if (!isDist) {
      columns.push(
        { dataField: "email_sent", text: "Email Sent", sort: true },
        { dataField: "user.email", text: "Email", sort: true },
        { dataField: "user.phone", text: "Phone", sort: true }
      )
    }
    columns.push(
      {
        dataField: "quarter",
        text: "Quarter",
        sort: true, // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => `Q${cellContent}`,
      },
      {
        dataField: "year",
        text: "Assessment Year",
      },
      {
        dataField: "file_url",
        text: "File",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          if (cellContent) {
            return (
              <Link
                to="#"
                onClick={() => {
                  getSignedUrlHandler(cellContent)
                }}
              >
                <i className="mdi mdi-file-document font-size-20" />
              </Link>
            )
          } else return ""
        },
      }
    )
    if (!isDist) {
      columns.push({
        dataField: "user.user_name",
        text: "User Name",
        sort: true,
      })
    }
    columns.push({
      dataField: "upload_date",
      text: "Upload Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) =>
        moment(order.upload_date).format("DD MMM Y"),
    })
    return columns
  }

  useEffect(async () => {
    masterData()
  }, [limit, skip, quarterFilter, financialYearFilter, emailSentFilter])

  useEffect(() => {
    if (userFilter?.length == 0 || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }
  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }
  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const filterUrl = `${userFilter?.length >= 3 ? `&name=${userFilter}` : ``}${
    quarterFilter ? `&quarter=${quarterFilter}` : ``
  }${financialYearFilter ? `&year=${financialYearFilter}` : ``}${
    emailSentFilter ? `&email_sent=${emailSentFilter?.value}` : ``
  }`

  const masterData = async () => {
    setTableData([])
    setLoading(true)

    const getUrl = `/distributor-tds-certificate?${
      isDist ? `userId=${localUser.id}&` : ""
    }$sort[created_at]=-1&$limit=${limit}&$skip=${skip}${filterUrl}`

    try {
      const response = await axiosInstance.get(getUrl)

      if (response) {
        setTotal(response.data.total)
        const data = response?.data?.data || response?.data
        setTableData(data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "user":
        setUserFilter(e?.target?.value)
        break
      case "quarter":
        setQuarterFilter(e?.value)
        break
      case "email_sent":
        setEmailSentFilter(e)
        break
      case "financialyear":
        let newStr = ""
        if (e?.id) {
          let temp = e?.id.split("-")
          newStr = `${temp[0] + "-" + temp[1].slice(2, 4)}`
        }
        setFinancialYearFilter(newStr)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const getSignedUrlHandler = async file => {
    try {
      setLoading(true)
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      window.open(response?.data)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const onSelectFile = e => {
    if (uploadType === "zip") {
      setFile(e.target.files[0])
    } else {
      setFile(Array.from(e.target.files))
    }
  }

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal)
  }

  const toggleMapModal = () => {
    setMapModal(!mapModal)
  }

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal)
  }

  const handleValidUpload = async () => {
    try {
      setLoading(true)
      const formData = new FormData()
      if (uploadType === "zip") {
        formData.append("file", file)
      } else {
        file.forEach(item => {
          formData.append("file", item)
        })
      }
      const resp = await axiosInstance.post(
        "/distributor-tds-certificate",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      if (resp) {
        if (resp.data.faultyPans.length !== 0) {
          setFaultyPanNos(resp.data.faultyPans)
        }
        setEmailData(resp.data.emailObj)
        delete resp.data.emailObj
        delete resp.data.faultyPans
        setUploadedZipData(Object.values(resp.data))
        setReview(true)
        toast.success("Successfully Uploaded")
        masterData()
        setFile(null)
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleMapHandler = async () => {
    try {
      setLoading(true)
      const resp = await axiosInstance.patch(
        `/distributor-tds-certificate/${selectedRow.id}`,
        {
          userId: selectedDistributor.id,
        }
      )
      if (resp) {
        toast.success("Successfully Mapped!")
        masterData()
        toggleMapModal()
        setSelectedRow()
        setSelectedDistributor()
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const downloadData = async () => {
    try {
      setLoading(true)

      const getUrl = `/distributor-tds-certificate?${filterUrl}`
      const response = await axiosInstance.get(getUrl)
      if (response) {
        const data = response?.data?.data || response?.data
        const csvTableHeaders = TableColumns().filter(
          item =>
            item.text !== "Email" &&
            item.text !== "Phone" &&
            item.text !== "Action"
        )
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)

        arr.forEach(el => {
          el.quarter = `Q${el.quarter}`
          el.legal_entity_name = el?.user?.distributor_kyc?.name || ""
          return el
        })

        const downloadableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downloadableArr, "Distributor TDS Certificate", true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const getDistributorUsers = async row => {
    try {
      setLoading(true)
      const opsUsersRes = await axiosInstance.get(
        `/users?$sort[created_at]=-1&permissions=OPS_USER`
      )
      if (opsUsersRes) {
        const opsUsers = opsUsersRes.data.data
        setDistributorList(
          opsUsers?.filter(
            user =>
              user?.role?.department === "Distributor" &&
              user?.distributor_kyc?.pan_number === row?.pan_number
          )
        )
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDoc = async file => {
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  const handleUploadButtonClick = type => {
    toggleUploadModal()
    setUploadType(type)
    setEmailData()
    setUploadedZipData()
    setReview(false)
    setEmailConsent(false)
  }

  const sendEmailsHandler = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.patch(
        `/send-email/${emailData.templateId}`,
        emailData
      )
      if (response) {
        toast.success(
          `${emailData?.messageVersions?.length} ${
            emailData?.messageVersions?.length === 1 ? "Email" : "Emails"
          } sent successfully `
        )
        toggleUploadModal()
        masterData()
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSelectedExecutionDates = async (index, values) => {
    try {
      setSelectedUploadDate(values.validatedValue)
      setExcludedUsers([])
      setSelectedUserToSendEmail([])
      if (!values?.validatedValue?.length) {
        setSendEmailData(null)
        return
      }

      setLoading(true)
      let filter = ``
      if (values.validatedValue.length > 0) {
        filter += `&upload_date=${values.validatedValue
          .map(date => date)
          .join(",")}`
      }
      const res = await axiosInstance.get(
        `/distributor-tds-certificate?$sort[created_at]=-1${filter}&email_sent=false&userId=not_null`
      )
      setSendEmailData({
        data: res.data.data,
        template_id: 731,
      })
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }
  const handleSendEmailSubmit = async () => {
    try {
      if (emailModalNumber == 1) {
        if (selectedUploadDate.length == 0) {
          toast.error("Select Uploaded Dates")
          return
        }
        if (
          selectedUserOption == "selectUser" &&
          selectedUserToSendEmail.length == 0
        ) {
          toast.error("Select user")
          return
        }

        const excludedUserId = excludedUsers.map(item => item.id)

        const filteredEmailData = {
          ...sendEmailData,
          data:
            selectedUserOption == "excludeUser"
              ? sendEmailData.data.filter(
                  item => !excludedUserId.includes(item.id)
                )
              : selectedUserOption == "selectUser"
              ? selectedUserToSendEmail
              : sendEmailData.data,
        }
        setFilteredSendEmailData(filteredEmailData)
        setEmailConsent(false)

        setEmailModalNumber(2)
      } else if (emailModalNumber == 2) {
        setLoading(true)
        const emailData = {
          templateId: 731,
          ids: filteredSendEmailData.data.map(item => item.id),
          messageVersions: filteredSendEmailData.data.map(item => ({
            to: [
              {
                email: item.user.email,
              },
            ],
            params: {
              DISPLAY_NAME: item.user.user_name,
              QUARTER: `Q${item.quarter}`,
              YEAR: item.year,
              RM_NAME: item.user.relManager.name,
              RM_EMAIL: item.user.relManager.email,
              RM_PHONE: item.user.relManager.phone,
              userId: item.userId,
            },
            attachment: [
              {
                name: item.file_name,
                url: item.file_url,
              },
            ],
          })),
        }

        const response = await axiosInstance.patch(
          `/send-email/${emailData.templateId}`,
          emailData
        )

        if (response) {
          toast.success(
            `${emailData?.messageVersions?.length} ${
              emailData?.messageVersions?.length === 1 ? "Email" : "Emails"
            } sent successfully`
          )
          toggleShowEmailModal()
          masterData()
        }
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const openSendEmail = () => {
    setEmailModalNumber(1)
    setSendEmailData()
    toggleShowEmailModal()
    setEmailConsent(false)
    setSelectedUserOption("all")
    setExcludedUsers([])
    setSelectedUserToSendEmail([])
    setFilteredSendEmailData()
    setSelectedUploadDate([])
  }

  const downloadEmailSummary = (data, type = "upload") => {
    const columns = [
      {
        dataField: "legal_entity_name",
        text: "Legal Entity Name",
      },
      {
        dataField: "pan_number",
        text: "Pan Number",
      },
      {
        dataField: "email_sent",
        text: "Email Sent",
      },
      {
        dataField: "quarter",
        text: "Quarter",
      },
      {
        dataField: "year",
        text: "Assessment Year",
      },
      {
        dataField: "user_name",
        text: "User Name",
      },
      {
        dataField: "upload_date",
        text: "Upload Date",
      },
    ]

    if (type === "upload") {
      const updatedData = data
        .map(item => {
          const messageVersion = emailData.messageVersions.find(
            el => el.params.userId === item.userId
          )
          return {
            ...item,
            email: messageVersion?.to[0]?.email || "",
            user_name: messageVersion?.params?.DISPLAY_NAME || "",
            quarter: `Q${item.quarter}`,
            legal_entity_name: messageVersion?.params?.legal_entity_name || "",
            user_name: messageVersion?.params?.DISPLAY_NAME || "",
            upload_date: item.upload_date,
          }
        })
        .filter(item => item.userId)

      JSONToCSVConvertor(
        csvDownloadData(columns, updatedData),
        "Distributor TDS Certificate Email Summary",
        true
      )
    } else {
      data.forEach(el => {
        el.quarter = `${el.quarter}`
        el.legal_entity_name = el?.user?.distributor_kyc?.name || ""
        el.user_name = el?.user?.user_name || ""
        return el
      })
      JSONToCSVConvertor(
        csvDownloadData(columns, data),
        "Distributor TDS Certificate Email Summary",
        true
      )
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>

        {accessControlData?.permissions?.TDS_CERTIFICATES_VIEW ||
        accessControlData?.permissions?.DISTRIBUTOR_TDS_CERTIFICATE_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  {!isDist && (
                    <div className="col-auto">
                      {(accessControlData?.permissions
                        ?.DISTRIBUTOR_TDS_CERTIFICATE_UPLOAD ||
                        localUser?.permissions === "Admin") && (
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-2"
                          onClick={() => {
                            handleUploadButtonClick("pdf")
                          }}
                          style={{ marginRight: "3px" }}
                        >
                          <i className="mdi mdi-arrow-up-bold-circle"></i>{" "}
                          Upload Pdf
                        </Button>
                      )}
                      {(accessControlData?.permissions
                        ?.DISTRIBUTOR_TDS_CERTIFICATE_UPLOAD ||
                        localUser?.permissions === "Admin") && (
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-2"
                          onClick={() => {
                            handleUploadButtonClick("zip")
                          }}
                          style={{ marginRight: "3px" }}
                        >
                          <i className="mdi mdi-arrow-up-bold-circle"></i>{" "}
                          Upload Zip
                        </Button>
                      )}
                      {(accessControlData?.permissions
                        ?.DISTRIBUTOR_TDS_CERTIFICATE_UPLOAD ||
                        localUser?.permissions === "Admin") && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded  mb-2 me-2"
                          onClick={openSendEmail}
                        >
                          <i className="mdi mdi-email"></i> Send Email
                        </Button>
                      )}
                      {(accessControlData?.permissions
                        ?.DISTRIBUTOR_TDS_CERTIFICATE_DOWNLOAD ||
                        localUser?.permissions === "Admin") && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded mb-2 me-2"
                          onClick={downloadData}
                          style={{ marginRight: "3px" }}
                        >
                          <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                          Download
                        </Button>
                      )}
                    </div>
                  )}
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={tableData}
                      columns={TableColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-4 row">
                            {!isDist && (
                              <Col md={3}>
                                <div className="position-relative">
                                  <label>Distributor Name</label>
                                  <input
                                    onChange={e =>
                                      handleFilterChange(e, "user")
                                    }
                                    id="search-bar-0"
                                    type="text"
                                    className="form-control rounded custom-input-height"
                                    placeholder={`Search by Pan Number, Name, Email or Phone`}
                                    value={userFilter || ""}
                                  />
                                </div>
                              </Col>
                            )}
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Assessment Year</label>
                                <ReactSelect
                                  users={generateFinancialYearList(
                                    2021,
                                    new Date(
                                      moment().add(1, "year").format("Y-MM-DD")
                                    )
                                  )}
                                  setSelectedOption={e => {
                                    handleFilterChange(e, "financialyear")
                                  }}
                                  multiOptionLabel={true}
                                  optionLabelKeys={["id"]}
                                  isClearable={true}
                                  // isDisabled={!selectedProject}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="position-relative">
                                <label>Quarter</label>
                                <ReactSelect
                                  users={QuarterValues}
                                  setSelectedOption={e => {
                                    handleFilterChange(e, "quarter")
                                  }}
                                  multiOptionLabel={false}
                                  optionLabelKeys={["label"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Email Sent</label>
                                <ReactSelect
                                  users={[
                                    {
                                      id: 1,
                                      label: "True",
                                      value: true,
                                    },
                                    {
                                      id: 2,
                                      label: "False",
                                      value: false,
                                    },
                                  ]}
                                  setSelectedOption={e => {
                                    handleFilterChange(e, "email_sent")
                                  }}
                                  multiOptionLabel={true}
                                  optionLabelKeys={["label"]}
                                  isClearable={true}
                                  // isDisabled={!selectedProject}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={TableColumns()}
                                data={tableData}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>

                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                 `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {/* SSP Pagination End */}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={uploadModal} toggle={toggleUploadModal}>
              <ModalHeader toggle={toggleUploadModal} tag="h4">
                {review
                  ? "Email Summary"
                  : `Upload ${uploadType === "zip" ? "ZIP" : "PDF"}`}
              </ModalHeader>
              <ModalBody>
                {review ? (
                  <div>
                    <p className="font-size-15 text-muted mb-3">
                      Uploaded Date: <b>{moment().format("DD MMMM Y")}</b>
                      <br />
                      Total Emails: <b>{emailData?.messageVersions?.length}</b>
                      <br />
                      <br />
                      {faultyPanNos?.length !== 0 && (
                        <>
                          <p className="m-0 p-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="20"
                              width="20"
                              viewBox="0 0 24 24"
                              fill="#FFC007"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
                            </svg>
                            <span className="ms-1 d-inline-block">
                              {`Users not found by following PAN Numbers: ${faultyPanNos.join(
                                ","
                              )}`}
                            </span>
                          </p>
                          <br />
                        </>
                      )}
                      {emailData?.messageVersions?.length !== 0 && (
                        <>
                          <p
                            onClick={() =>
                              downloadEmailSummary(uploadedZipData)
                            }
                            className="font-size-15"
                          >
                            <a className="link">Download Summary (CSV)</a>
                          </p>
                          <div className="d-flex gap-1">
                            <input
                              type="checkbox"
                              id="emailConsent"
                              checked={emailConsent}
                              onChange={event =>
                                setEmailConsent(event.target.checked)
                              }
                            />
                            <label
                              className="mb-0 font-size-14 text-muted"
                              htmlFor="emailConsent"
                            >
                              I have reviewed the email summary
                            </label>
                          </div>
                        </>
                      )}
                    </p>
                    <br />
                    <div
                      className={
                        "d-flex justify-content-end align-items-center gap-2"
                      }
                    >
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={toggleUploadModal}
                      >
                        Close
                      </button>
                      {emailData?.messageVersions?.length !== 0 && (
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                          disabled={!emailConsent}
                          onClick={sendEmailsHandler}
                        >
                          Send Emails
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="mb-3">
                      <Label htmlFor="formFile" className="form-label">
                        Upload {uploadType === "zip" ? "File" : "File"}
                      </Label>
                      <Input
                        className="form-control"
                        type="file"
                        accept={uploadType === "zip" ? ".zip" : ".pdf"}
                        id="formFile"
                        multiple={uploadType === "pdf"}
                        validate={{
                          required: { value: true },
                        }}
                        onChange={onSelectFile}
                      />
                    </div>
                    <div className="col-auto text-end">
                      <Button
                        type="submit"
                        color="success"
                        className={`btn-md save-user`}
                        onClick={handleValidUpload}
                        disabled={!file}
                      >
                        Upload
                      </Button>
                    </div>
                  </>
                )}
              </ModalBody>
            </Modal>
            <Modal isOpen={mapModal} toggle={toggleMapModal}>
              <ModalHeader toggle={toggleMapModal} tag="h4">
                Map TDS Certificate with Distributor
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <div>Pan Number</div>
                      <p className="fw-medium">{selectedRow?.pan_number}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <div>File Name</div>
                      <p className="fw-medium">{selectedRow?.file_name}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <div>Quarter</div>
                      <p className="fw-medium">Q{selectedRow?.quarter}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <div>Assessment Year</div>
                      <p className="fw-medium">{selectedRow?.year}</p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <div>TDS Certificate</div>
                      <Link
                        to="#"
                        className="text-primary d-inline-block align-items-center mt-2"
                        onClick={() => handleDoc(selectedRow?.file_url)}
                      >
                        <div
                          className="mb-3 py-1 px-2 rounded-pill border d-inline-block border-primary text-primary font-size-12"
                          style={{ cursor: "pointer" }}
                        >
                          <svg viewBox="0 0 24 24" width="18px" fill="#556ee6">
                            <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                          </svg>{" "}
                          {selectedRow?.file_name}
                        </div>
                      </Link>
                    </div>
                  </Col>
                </Row>
                {distributorList.length === 0 ? (
                  !loading && (
                    <div className="d-flex align-items-center text-danger">
                      <i className="mdi mdi-close font-size-16 me-1"></i>
                      <p className="mb-0">
                        No Distributor found with this Pan Number
                      </p>
                    </div>
                  )
                ) : (
                  <>
                    <div className="mb-3">
                      <Label htmlFor="formFile" className="form-label">
                        Select Distributor
                      </Label>
                      <ReactSelect
                        users={distributorList}
                        setSelectedOption={e => setSelectedDistributor(e)}
                        multiOptionLabel={false}
                        optionLabelKeys={[
                          "distributor_kyc?.pan_number",
                          "distributor_kyc?.name",
                          "email",
                          "phone",
                        ]}
                        isClearable={true}
                      />
                    </div>
                    <p className="text-muted">
                      The selected distributor will receive the TDS Certificate
                      via email.
                    </p>
                  </>
                )}
                <div className="col-auto text-end">
                  <Button
                    type="submit"
                    color="success"
                    className={`btn-md save-user`}
                    onClick={handleMapHandler}
                    disabled={!selectedDistributor}
                  >
                    Save
                  </Button>
                </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={showEmailModal} toggle={toggleShowEmailModal}>
              <ModalHeader toggle={toggleShowEmailModal} tag="h4">
                {emailModalNumber == 1 ? "Send Email" : "Email Summary"}{" "}
              </ModalHeader>
              <ModalBody>
                <AvForm onValidSubmit={handleSendEmailSubmit}>
                  <Row form>
                    {emailModalNumber == 2 ? (
                      <>
                        {filteredSendEmailData?.data?.length === 0 ? (
                          <p className="font-size-15 text-muted mb-2">
                            No data found!
                          </p>
                        ) : (
                          <Col className="col-12">
                            <p className="font-size-15 text-muted mb-3 text-capitalize">
                              Execution Dates:{" "}
                              <b>
                                {selectedUploadDate.map((date, index) => {
                                  if (index != selectedUploadDate.length - 1) {
                                    return (
                                      moment(date).format("DD MMMM, YYYY") +
                                      "; "
                                    )
                                  } else {
                                    return moment(date).format("DD MMMM, YYYY")
                                  }
                                })}
                              </b>
                              <br />
                              Total emails :{" "}
                              <b>{filteredSendEmailData?.data?.length}</b>
                            </p>
                            <p
                              onClick={() =>
                                downloadEmailSummary(
                                  filteredSendEmailData?.data,
                                  "send_email"
                                )
                              }
                              className="mb-5 font-size-15"
                            >
                              <a className="link">Download Summary (CSV)</a>
                            </p>
                            <div className="d-flex gap-1 mb-3">
                              <input
                                type="checkbox"
                                id="emailConsent"
                                checked={emailConsent}
                                onChange={e =>
                                  setEmailConsent(e.target.checked)
                                }
                              />{" "}
                              <label
                                className="mb-0 font-size-14 text-muted"
                                htmlFor="emailConsent"
                              >
                                I have reviewed the email summary.
                              </label>
                            </div>
                          </Col>
                        )}
                      </>
                    ) : (
                      <Col className="col-12">
                        <div className="mb-3">
                          <label>Select Uploaded Dates</label>
                          <DatePicker
                            multiple
                            id="multiDatePicker"
                            value={selectedUploadDate}
                            onChange={(index, values) =>
                              handleSelectedExecutionDates(index, values)
                            }
                            plugins={[
                              <DatePanel key="datePanel" />,
                              <Toolbar
                                position="bottom"
                                sort={["deselect", "close"]}
                                names={{
                                  deselect: "Clear",
                                  close: "Close",
                                }}
                                key="toolbar"
                              />,
                            ]}
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              height: "38px",
                            }}
                            containerStyle={{
                              width: "100%",
                            }}
                            placeholder="YYYY/MM/DD"
                          />
                        </div>
                        <div className="d-flex gap-3 mb-3">
                          <AvField
                            type="radio"
                            name="userOptions"
                            id="all"
                            label="Send to All"
                            value="all"
                            checked={selectedUserOption === "all"}
                            style={{ marginLeft: "10px" }}
                            onChange={e => {
                              setSelectedUserOption(e.target.value)
                            }}
                          />
                          <AvField
                            type="radio"
                            name="userOptions"
                            label="Select Users"
                            id="selectUser"
                            value="selectUser"
                            checked={selectedUserOption === "selectUser"}
                            style={{ marginLeft: "10px" }}
                            onChange={e => {
                              setSelectedUserOption(e.target.value)
                            }}
                          />
                          <AvField
                            type="radio"
                            name="userOptions"
                            label="Exclude Users"
                            value="excludeUser"
                            id="excludeUser"
                            checked={selectedUserOption === "excludeUser"}
                            style={{ marginLeft: "10px" }}
                            onChange={e => {
                              setSelectedUserOption(e.target.value)
                            }}
                          />
                        </div>
                        {selectedUserOption == "excludeUser" ? (
                          <div className="mb-3">
                            <label>Exclude Users</label>
                            <ReactMultiSelect
                              options={sendEmailData?.data}
                              multiOptionLabel={false}
                              optionLabelKeys={[
                                "user?.user_name",
                                "user?.email",
                                "quarter",
                                "year",
                              ]}
                              isDisabled={!sendEmailData}
                              selectedOption={excludedUsers}
                              setSelectedOption={setExcludedUsers}
                            />
                          </div>
                        ) : null}
                        {selectedUserOption == "selectUser" ? (
                          <div className="mb-3">
                            <label>Select Users</label>
                            <ReactMultiSelect
                              options={sendEmailData?.data}
                              multiOptionLabel={false}
                              optionLabelKeys={[
                                "user?.user_name",
                                "user?.email",
                                "quarter",
                                "year",
                              ]}
                              isDisabled={!sendEmailData}
                              selectedOption={selectedUserToSendEmail}
                              setSelectedOption={setSelectedUserToSendEmail}
                            />
                          </div>
                        ) : null}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      {emailModalNumber == 1 ? (
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            Submit
                          </button>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                  {emailModalNumber == 2 ? (
                    <ModalFooter className="d-block p-0 pt-2">
                      <div
                        className={
                          "w-100 d-flex justify-content-between align-items-center"
                        }
                      >
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            setEmailModalNumber(1)
                            setFilteredSendEmailData()
                          }}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                          disabled={!emailConsent}
                        >
                          Send Emails
                        </button>
                      </div>
                    </ModalFooter>
                  ) : null}
                </AvForm>
              </ModalBody>
            </Modal>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default DistributorTDSCertificate
