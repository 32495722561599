import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import getBase64 from "../../base64"
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import Toolbar from "react-multi-date-picker/plugins/toolbar"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Label,
  Input,
  ModalFooter,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"

import Loader from "common/Loader"
import ReactSelect from "constants/ReactSelect"
// import { PaymentTypeForRepayment } from "constants/ConstantFields"
import {
  getOptionLabel,
  humanize,
  usersData,
  getTimestamp,
} from "constants/common"
import { showToastError } from "utils"
import {
  paymentStatusForTransactions,
  transactionTypeForRepayment,
} from "constants/ConstantFields"
import { investmentData, csvDownloadData } from "constants/common"
import ReactMultiSelect from "constants/ReactMultiSelect"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const Repayment = () => {
  const [orders, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [userInvestment, setUserInvestment] = useState([])
  const [modal, setModal] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [file, setFile] = useState(null)
  const [selectedOption, setSelectedOption] = useState()
  const [base64, setBase64] = useState("")
  const [upload, setUpload] = useState(false)
  const [errorData, setErrorData] = useState([])
  const [errorModal, setErrorModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [selectedInvLot, setSelectedInvLot] = useState(null)
  const [userInvTopupInInvLot, setUserInvTopupInInvLot] = useState([])

  const [uploadTransactionModal, setUploadTransactionModal] = useState(false)
  const [uploadTransactionData, setUploadTransactionData] = useState({})
  const [selectedInvestment, setSelectedInvestment] = useState(null)
  const [investments, setInvestments] = useState([])
  const [transactionConfirm, setTransactionConfirm] = useState(false)
  const [includedUserInvestmentList, setIncludedUserInvestmentList] = useState(
    []
  )
  const [excludedUserInvestmentList, setExcludedUserInvestmentList] = useState(
    []
  )
  const [selectedUserInvestments, setSelectedUserInvestments] = useState([])

  const [investmentLot, setInvestmentLot] = useState([])
  const [selectedInvestmentLot, setSelectedInvestmentLot] = useState([])

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [transactionTypeFilter, setTransactionTypeFilter] = useState({})
  const [userFilter, setUserFilter] = useState("")
  const [total, setTotal] = useState(0)
  const [paymentStatusFilter, setPaymentStatusFilter] = useState({})
  const [selectedUser, setSelectedUser] = useState()
  const [users, setUsers] = useState([])
  const [searchValue, setSearchValue] = useState()
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [investmentLotPerInv, setInvestmentLotPerInv] = useState([])
  const [investmentLotFilter, setInvestmentLotFilter] = useState({})
  const [transactionDateFilter, setTransactionDateFilter] = useState("")

  const [isDistributorEmail, setIsDistributorEmail] = useState(false)
  // States for Send Email
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [sendEmailModal, setSendEmailModal] = useState(1)
  const [transactionDate, setTransactionDate] = useState(
    moment().format("YYYY-MM-DD")
  )
  const [selectedProject, setSelectedProject] = useState()
  const [transactionType, setTransactionType] = useState("")

  const [transactionEmailData, setTransactionEmailData] = useState([])
  const [emailData, setEmailData] = useState([])

  const [emailConsent, setEmailConsent] = useState(false)
  const [payoutEmailData, setPayoutEmailData] = useState()
  const [excludedUsers, setExcludedUsers] = useState([])

  const [transactionDates, setTransactionDates] = useState([])
  const [isBulkEmail, setIsBulkEmail] = useState(false)
  const [channel, setChannel] = useState("")

  const [selectedUserOption, setSelectedUserOption] = useState("all")
  const [selectedUserToSendComm, setSelectedUserToSendComm] = useState([])

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  // const getUrl = `admin-transaction-listing?$sort[created_at]=-1&$or[0][transaction_type]=Interest_Payment&$or[1][transaction_type]=Tds&$or[2][transaction_type]=Principal_Repayment&$or[3][transaction_type]=Principal_Prepayment`

  const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${
    investmentLotFilter?.id ? `&investmentLotId=${investmentLotFilter?.id}` : ""
  }${
    transactionTypeFilter?.id
      ? `&$or[0][transaction_type]=${transactionTypeFilter?.id}`
      : `&$or[0][transaction_type]=Interest_Payment&$or[1][transaction_type]=Tds&$or[2][transaction_type]=Principal_Repayment&$or[3][transaction_type]=Principal_Prepayment`
  }${
    paymentStatusFilter?.id ? `&payment_status=${paymentStatusFilter?.id}` : ""
  }${transactionDateFilter ? `&transaction_date=${transactionDateFilter}` : ""}`

  const getUrl = `admin-transaction-listing?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`

  const masterData = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(getUrl)
      if (response) {
        setTotal(response.data.total)
        setData(response?.data?.data || response?.data)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFilters = async () => {
    const investment = await investmentData("Approved", "Closed", "Exited")
    if (investment) {
      setInvestments(investment?.data?.data)
    } else {
      toast.error("something went wrong")
    }
  }

  const getInvLot = async () => {
    // get inv lots
    if (investmentFilter?.id) {
      const invLotRes = await axiosInstance.get(
        `investment-lot?$sort[created_at]=-1&investmentId=${investmentFilter.id}`
      )
      if (invLotRes) {
        setInvestmentLotPerInv(invLotRes?.data?.data || invLotRes?.data)
      }
    }
  }

  useEffect(() => {
    getInvLot()
  }, [investmentFilter])

  useEffect(() => {
    getFilters()
  }, [])

  useEffect(async () => {
    masterData()
  }, [
    limit,
    skip,
    transactionTypeFilter,
    paymentStatusFilter,
    investmentFilter,
    investmentLotFilter,
    transactionDateFilter,
  ])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])
  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        setInvestmentLotFilter({})
        break
      case "investmentLot":
        setInvestmentLotFilter(e)
        break

      case "transactionType":
        setTransactionTypeFilter(e)
        break
      case "paymentStatus":
        setPaymentStatusFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      case "transaction_date":
        setTransactionDateFilter(e)
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  const repaymentColumns = toggleModal => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            {accessControlData?.permissions?.REPAYMENTS_EDIT ? (
              <Link
                to="#"
                className="text-success"
                onClick={() => handleOrderClick(order)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {accessControlData?.permissions?.REPAYMENTS_EDIT}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
            {accessControlData?.permissions?.REPAYMENTS_DELETE ? (
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDeleteOrder(order)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {accessControlData?.permissions?.REPAYMENTS_DELETE}
                </UncontrolledTooltip>
              </Link>
            ) : (
              "NA"
            )}
          </div>
        </>
      ),
    },
    {
      dataField: "created_at",
      text: "Transaction Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "investment_lot_name",
      text: "Investment Lot",
      sort: true,
    },

    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },

    {
      dataField: "email",
      text: "User Email",
      sort: true,
    },

    {
      dataField: "phone",
      text: "User Phone",
      sort: true,
    },
    {
      dataField: "pan_number",
      text: "PAN Number",
      sort: true,
    },

    {
      dataField: "transaction_amount",
      text: "Amount (In Rs)",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleAmount(row.transaction_amount),
    },

    {
      dataField: "payment_type",
      text: "Payment Type",
      sort: true,
    },

    {
      dataField: "transaction_type",
      text: "Transaction Type",
      sort: true,
    },

    {
      dataField: "payment_status",
      text: "Payment Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = paymentStatusForTransactions.find(
          obj => obj.id == cellContent
        )
        return (
          <div className="text-end">
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              }`}
            >
              {status?.statusText}
            </span>
          </div>
        )
      },
    },
    {
      dataField: "email_sent",
      text: "Email Sent",
      sort: true,
    },
    {
      dataField: "whatsapp_sent",
      text: "Whatsapp Sent",
      sort: true,
    },
    {
      dataField: "distributor_email_sent",
      text: "Distributor Notified(Email)",
      sort: true,
    },
    {
      dataField: "distributor_whatsapp_sent",
      text: "Distributor Notified(Whatsapp)",
      sort: true,
    },
    {
      dataField: "txn_ref_id",
      text: "Transaction Reference Id",
      sort: true,
    },

    {
      dataField: "parent_pan_number",
      text: "Parent Pan Number",
      sort: true,
    },
    {
      dataField: "parent_user_name",
      text: "Parent User Name",
      sort: true,
    },
    { dataField: "parent_email", text: "Parent Email", sort: true },
    { dataField: "parent_phone", text: "Parent Phone", sort: true },
    { dataField: "parentId", text: "Parent Id", sort: true },
    {
      dataField: "razorpay_order_id",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "razorpay_payment_id",
      text: "Payment Id",
      sort: true,
    },

    {
      dataField: "userInvestmentId",
      text: "User Investment Id",
      sort: true,
    },
    {
      dataField: "investmentLotId",
      text: "Investment Lot Id",
      sort: true,
    },
    {
      dataField: "userId",
      text: "User Id",
      sort: true,
    },
    {
      dataField: "due_date",
      text: "Due Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.due_date ? handleValidDate(row.due_date) : null,
    },

    {
      dataField: "updated_at",
      text: "Updated On",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updated_at),
    },
  ]

  const EmailDataColumns = toggleModal => {
    if (isDistributorEmail) {
      return [
        {
          dataField: "user_name",
          text: "Distributor Name",
          sort: true,
        },
        {
          dataField: "email",
          text: "Distributor Email",
          sort: true,
        },
        {
          dataField: "pan_number",
          text: "PAN Number",
          sort: true,
        },
      ]
    }
    let cols = [
      {
        dataField: "user_name",
        text: "User Name",
        sort: true,
      },
      {
        dataField: "email",
        text: "User Email",
        sort: true,
      },
      {
        dataField: "phone",
        text: "User Phone",
        sort: true,
      },
      {
        dataField: "pan_number",
        text: "PAN Number",
        sort: true,
      },
      {
        dataField: transactionType,
        text: humanize(transactionType),
        sort: true,
      },
      {
        dataField: "created_at",
        text: "Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row.created_at),
      },
      {
        dataField: "due_date",
        text: "Due Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => handleValidDate(row.due_date),
      },
    ]
    if (transactionType === "Interest_Payment") {
      let col1 = {
        dataField: "Tds",
        text: "TDS",
        sort: true,
        isNumber: true,
      }
      let col2 = {
        dataField: "gross_interest",
        text: "Gross Interest",
        sort: true,
        isNumber: true,
      }
      cols.splice(4, 0, col1)
      cols.splice(5, 0, col2)
    }

    if (!isDistributorEmail) {
      cols.push(
        {
          dataField: "parent_pan_number",
          text: "Parent Pan Number",
          sort: true,
        },
        {
          dataField: "parent_user_name",
          text: "Parent User Name",
          sort: true,
        },
        { dataField: "parent_email", text: "Parent Email", sort: true },
        { dataField: "parent_phone", text: "Parent Phone", sort: true },
        { dataField: "parentId", text: "Parent Id", sort: true }
      )
    }

    return cols
  }

  const BulkEmailDataColumns = toggleModal => {
    if (isDistributorEmail) {
      return [
        {
          dataField: "distributor_name",
          text: "Distributor Name",
          sort: true,
        },
        {
          dataField: "distributor_email",
          text: "Distributor Email",
          sort: true,
        },
        {
          dataField: "distributor_phone",
          text: "Distributor Phone",
          sort: true,
        },
        {
          dataField: "total",
          text: "Total Payout Amount",
          sort: true,
        },
        {
          dataField: "no_of_investors",
          text: "No. of Investors",
          sort: true,
        },
        {
          dataField: "Interest_Payment",
          text: "Total Interest",
          sort: true,
        },
        {
          dataField: "Tds",
          text: "Total TDS",
          sort: true,
        },
        {
          dataField: "Principal_Repayment",
          text: "Total Principal Repayment",
          sort: true,
        },
        {
          dataField: "Principal_Prepayment",
          text: "Total Principal Prepayment",
          sort: true,
        },
        {
          dataField: "rm_name",
          text: "Distributor RM Name",
          sort: true,
        },
      ]
    }
    let cols = [
      {
        dataField: "created_at",
        text: "Transaction Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          moment(row.created_at).format("DD MMM YYYY"),
      },
      {
        dataField: "user_name",
        text: "Investor Name",
        sort: true,
      },
      {
        dataField: "pan_number",
        text: "PAN Number",
        sort: true,
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "phone",
        text: "Phone",
        sort: true,
      },
      {
        dataField: "show_individual",
        text: "Show Individual Transactions",
        sort: true,
      },
      {
        dataField: "Interest_Payment",
        text: "Net Interest",
        sort: true,
        isNumber: true,
      },
      {
        dataField: "Tds",
        text: "TDS",
        sort: true,
        isNumber: true,
      },
      {
        dataField: "gross_interest",
        text: "Gross Interest",
        sort: true,
        isNumber: true,
      },
      {
        dataField: "Principal_Repayment",
        text: "Principal Repayment",
        sort: true,
        isNumber: true,
      },
      {
        dataField: "Principal_Prepayment",
        text: "Principal Prepayment",
        sort: true,
        isNumber: true,
      },
      {
        dataField: "show_family_account",
        text: "Show Family Account",
        sort: true,
      },
      {
        dataField: "Total_Interest_Payment",
        text: "Total Net Interest",
        sort: true,
        isNumber: true,
      },
      {
        dataField: "Total_Tds",
        text: "Total TDS",
        sort: true,
        isNumber: true,
      },
      {
        dataField: "Total_gross_interest",
        text: "Total Gross Interest",
        sort: true,
        isNumber: true,
      },
      {
        dataField: "Total_Principal_Repayment",
        text: "Total Principal Repayment",
        sort: true,
        isNumber: true,
      },
      {
        dataField: "Total_Principal_Prepayment",
        text: "Total Principal Prepayment",
        sort: true,
        isNumber: true,
      },

      {
        dataField: "distributor_name",
        text: "Distributor Legal Entity Name",
        sort: true,
      },
      {
        dataField: "distributor_phone",
        text: "Distributor Phone",
        sort: true,
      },
      {
        dataField: "distributor_email",
        text: "Distributor Email",
        sort: true,
      },
      {
        dataField: "rm_user_name",
        text: "RM Name",
        sort: true,
      },
      {
        dataField: "rm_phone",
        text: "RM Phone",
        sort: true,
      },
      {
        dataField: "rm_email",
        text: "RM Email",
        sort: true,
      },
      {
        dataField: "userId",
        text: "User Id",
        sort: true,
      },
      {
        dataField: "distributorId",
        text: "Distributor Id",
        sort: true,
      },
      {
        dataField: "relManagerId",
        text: "Relationship Manager Id",
        sort: true,
      },
      {
        dataField: "due_date",
        text: "Due Date",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) =>
          moment(row.due_date).format("DD MMM YYYY"),
      },
      // {
      //   dataField: "total_payout",
      //   text: "Total Payout",
      //   sort: true,
      // },
      // {
      //   dataField: "current_bond_price",
      //   text: "Current Bond Price",
      //   sort: true,
      // },
      // {
      //   dataField: "additional_bonds_alpha",
      //   text: "Additional Bonds Alpha",
      //   sort: true,
      // },
      {
        dataField: "whtsup_tnc",
        text: "Whatsapp tnc",
        sort: true,
      },
    ]

    return cols
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    const amountInRupees = amount / 100
    return dollarIndianLocale.format(amountInRupees)
  }

  const handleDeleteOrder = async order => {
    setLoading(true)

    var r = confirm(`Are you sure want to delete`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(`transaction/${order.id}`)
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }

  const handleOrderClick = async arg => {
    const order = arg
    setSelectedOption(null)
    setUpload(false)
    setChangeDate(false)
    setBase64("")
    await handleSelectedUserInvestment(
      { investmentId: order.investmentId },
      order.investmentLotId
    )
    setOrderList({
      id: order.id,
      payment_status: order.payment_status,
      email: order.email,
      user_name: order.user_name,
      phone: order.phone,
      project_name: order.investmentId,
      transaction_amount: Math.abs(order.transaction_amount),
      transaction_type: order.transaction_type,
      // payment_type: order.payment_type || "Bank_Transfer",
      payment_status: order.payment_status,
      project_name_retain: order.project_name,
      txn_ref_id: order.txn_ref_id,
      remark: order.remark,
      created_at: moment(order.created_at).format("YYYY-MM-DD"),
      due_date: moment(order.due_date).format("YYYY-MM-DD"),
    })
    setIsEdit(true)
    toggle()
  }

  const handleOrderClicks = async () => {
    setSelectedOption(null)
    setSelectedUser()
    setUsers([])
    setSearchValue()
    setOrderList("")
    setUpload(false)
    setBase64("")
    setIsDistributorEmail(false)
    setIsEdit(false)
    setSelectedInvLot(null)
    setUserInvTopupInInvLot([])
    setInvestmentLot([])
    toggle()
  }

  const handleSendEmailClick = async () => {
    setIsDistributorEmail(false)
    setIsBulkEmail(false)
    sendEmailState()
  }

  const handleSendBulkEmailClick = async () => {
    setChannel("")
    setSelectedUserToSendComm([])
    setSelectedUserOption("all")
    setIsDistributorEmail(false)
    setIsBulkEmail(true)
    sendEmailState()
  }

  const sendEmailState = () => {
    setLoading(true)
    setUpload(false)
    setIsEdit(false)
    setSendEmailModal(1)
    setSelectedProject()
    setTransactionType("")
    setTransactionDate(moment(new Date()).format("YYYY-MM-DD"))
    setEmailConsent(false)
    toggleEmailModal()
    setExcludedUsers([])
    setPayoutEmailData()
    setTransactionDates([])
    setLoading(false)
  }

  const handleDistributorEmail = () => {
    setIsDistributorEmail(true)
    setIsBulkEmail(true)
    sendEmailState()
    setChannel("")
    setSelectedUserToSendComm([])
    setSelectedUserOption("all")
  }
  const handleProjectName = inv => {
    const currentInv = investments.find(val => val.id == inv?.id)
    setSelectedProject(currentInv)
  }

  const handleTransactionDate = e => {
    const date = e.target.value
    setTransactionDate(date)
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders)
      setIsEdit(false)
    }
  }, [orders])

  const toggleEmailModal = () => {
    setShowEmailModal(!showEmailModal)
  }
  const toggle = () => {
    setModal(!modal)
  }
  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await usersData("VERIFIED", e)
        if (usersRes) {
          const users = usersRes?.data?.data || usersRes?.data
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedProject?.id && transactionType && transactionDate) {
      getPayoutEmailData()
    }
  }, [selectedProject, transactionType, transactionDate])
  const getPayoutEmailData = async () => {
    try {
      setExcludedUsers([])
      setPayoutEmailData()
      setLoading(true)
      const payload = {
        type: transactionType,
        investmentId: selectedProject.id,
        date: moment(transactionDate),
      }
      const response = await axiosInstance.post(
        isDistributorEmail
          ? `distributor-transaction-email-data`
          : `transaction-email-data`,
        payload
      )
      if (response) {
        setPayoutEmailData(
          response.data?.data?.map(item => ({ ...item, id: item.userId }))
        )
        if (
          !isDistributorEmail &&
          !isBulkEmail &&
          !response?.data?.data?.length
        ) {
          toast.warn("No transactions found!")
        }
        setTransactionEmailData(response.data)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (transactionDates.length && channel) {
      getBulkPayoutEmailData()
    }
  }, [channel, transactionDates])

  const getBulkPayoutEmailData = async () => {
    try {
      setSelectedUserOption("all")
      setExcludedUsers([])
      setPayoutEmailData()
      setLoading(true)
      const payload = {
        transaction_dates: transactionDates
          .sort((a, b) => new Date(a) - new Date(b))
          .map(date => moment(date)),
      }
      let URL =
        channel == "whatsapp"
          ? `bulk-transaction-whatsapp-data`
          : `bulk-transaction-email-data`

      if (isDistributorEmail) {
        URL = `distributor-bulk-payout-data?channel=${channel}`
      }
      const response = await axiosInstance.post(URL, payload)

      if (response) {
        console.log(`System validated ${channel} summary successfully`)

        if (isDistributorEmail) {
          setPayoutEmailData(
            response.data?.data?.map(item => ({
              ...item,
              id: item.distributorId,
              user_name: item.distributor_name,
              email: item.distributor_email,
              phone: item.distributor_phone,
              userId: item.distributorId,
            }))
          )
        } else {
          setPayoutEmailData(
            response.data?.data?.map(item => ({ ...item, id: item.userId }))
          )
        }
        setTransactionEmailData(response.data)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEdit) {
      if (!selectedInvLot?.id) {
        toast.error("Select investment lot")
        setLoading(false)
        return
      }
      const updateOrder = {
        userInvestmentId: selectedOption?.id,
        transaction_amount: `-${values.transaction_amount}`,
        // payment_type: values.payment_type,
        transaction_type: values.transaction_type,
        payment_status: values.payment_status,
        txn_ref_id: values.txn_ref_id,
        remark: values.remark,
        investmentLotId: selectedInvLot?.id,
        created_at: changeDate
          ? getTimestamp(values.created_at)
          : getTimestamp(orderList.created_at),
        due_date: getTimestamp(values.due_date),
      }
      try {
        const response = await axiosInstance.patch(
          `transaction/${orderList.id}`,
          updateOrder
        )
        if (response) {
          toast.success("Successfully Updated")
          masterData()
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
      toggle()
    } else if (upload) {
      const dataToUpload = {
        transactions: base64.replace("data:text/csv;base64,", ""),
      }
      if (base64) {
        try {
          const uploadResponse = await axiosInstance.post(
            "/upload-base-64?type=transactions",
            dataToUpload
          )
          if (uploadResponse) {
            masterData()
            toast.success("Sucessfully Uploaded")
            setUpload(false)
            setBase64("")
          }
        } catch (error) {
          setErrorData(error?.response?.data?.data)
          const msg = error?.response?.data.message
          setErrorMsg(msg)
          setErrorModal(true)
        }
      } else {
        showToastError("Please select valid file")
        setLoading(false)
        return false
      }
      toggle()
    } else if (showEmailModal) {
      try {
        if (sendEmailModal === 1) {
          if (
            selectedUserOption == "selectUser" &&
            selectedUserToSendComm.length == 0
          ) {
            toast.error("Select user")
            setLoading(false)
            return
          }
          const excludedUserId = excludedUsers.map(item => item.userId)

          const filteredTransactionEmailData = {
            ...transactionEmailData,
            data:
              selectedUserOption == "excludeUser"
                ? payoutEmailData.filter(
                    item => !excludedUserId.includes(item.userId)
                  )
                : selectedUserOption == "selectUser"
                ? selectedUserToSendComm
                : payoutEmailData,
          }
          filteredTransactionEmailData.total =
            filteredTransactionEmailData.data.length

          filteredTransactionEmailData.transaction_ids =
            filteredTransactionEmailData.data.flatMap(
              obj => obj.transaction_ids
            )

          if (filteredTransactionEmailData) {
            setTransactionEmailData(filteredTransactionEmailData)

            let tableHeaders = isBulkEmail
              ? BulkEmailDataColumns()
              : EmailDataColumns()
            let tableData = JSON.parse(
              JSON.stringify(filteredTransactionEmailData.data)
            )
            const emailData = csvDownloadData(tableHeaders, tableData)
            setEmailData(emailData)

            if (isBulkEmail || isDistributorEmail) {
              setEmailConsent(false)
              setSendEmailModal(2)
            } else {
              JSONToCSVConvertor(emailData, "Payout Summary", true)
              setShowEmailModal(false)
            }
          } else {
            toast.error("Something went wrong!")
          }
        } else if (sendEmailModal === 2) {
          const mailObj = transactionEmailData
          const sendEmailResponse = await axiosInstance.post(
            channel == "whatsapp" ? "send-whatsapp" : `send-email`,
            mailObj
          )
          if (sendEmailResponse) {
            toast.success(`${sendEmailResponse?.data?.message}`)
          }
          masterData()
          toggleEmailModal()
        }
      } catch (error) {
        toast.error(error?.message)
      }
    } else {
      if (!selectedInvLot?.id) {
        toast.error("Select investment lot")
        setLoading(false)
        return
      }
      const newOrder = {
        userInvestmentId: selectedOption?.id,
        userId: selectedOption?.userId,
        transaction_amount: `-${values.transaction_amount}`,
        payment_type: "Bank_Transfer",
        transaction_type: values.transaction_type,
        payment_status: values.payment_status,
        txn_ref_id: values.txn_ref_id,
        remark: values.remark,
        investmentLotId: selectedInvLot?.id,
        created_at: getTimestamp(values.created_at),
        due_date: getTimestamp(values.due_date),
      }
      // save new order

      try {
        const response = await axiosInstance.post(`transaction`, newOrder)
        if (response) {
          masterData()
          toast.success("Repayment Successfully Added")
        }
      } catch (error) {
        const msg = error?.response?.data?.errors[0]?.message
        toast.error(msg)
      }
      toggle()
    }
    setLoading(false)
  }

  const handleUploadTransactionClick = async () => {
    setSelectedInvestment(null)
    setInvestmentLot([])
    setSelectedInvestmentLot([])
    setSelectedUserInvestments([])
    setIncludedUserInvestmentList([])
    setExcludedUserInvestmentList([])
    setUploadTransactionData({})
    setUpload(false)
    uploadTransactionsToggle()
  }

  const toggleConfirm = () => {
    setTransactionConfirm(!transactionConfirm)
  }

  const uploadTransactionsToggle = () => {
    setUploadTransactionModal(!uploadTransactionModal)
  }

  const handleTransactionSubmit = (e, values) => {
    if (selectedInvestment?.id && selectedInvestmentLot.length) {
      setUploadTransactionData(values)
      toggleConfirm()
      uploadTransactionsToggle()
    } else {
      toast.error("Please select a project and investment lot")
    }
  }

  const handleValidTransactionSubmit = async () => {
    setLoading(true)
    const newOrder = {
      investmentId: selectedInvestment?.id,
      investmentLotId: selectedInvestmentLot.map(el => el.id),
      user_investment_ids: selectedUserInvestments?.map(
        element => element?.userInvestmentId
      ),
      actual_interest_amount: uploadTransactionData?.interestAmount
        ? `-${uploadTransactionData?.interestAmount}`
        : "0",
      accrued_interest_amount: uploadTransactionData?.accruedInterestAmount
        ? `-${uploadTransactionData?.accruedInterestAmount}`
        : "0",
      tds_percent: uploadTransactionData?.tdsPercent
        ? `${uploadTransactionData?.tdsPercent}`
        : "0",
      principal_repayment: uploadTransactionData?.principalRepayment
        ? `-${uploadTransactionData?.principalRepayment}`
        : "0",
      principal_prepayment: uploadTransactionData?.principalPrepayment
        ? `-${uploadTransactionData?.principalPrepayment}`
        : "0",
      payment_type: "Bank_Transfer",
      payment_status: "success",
      txn_ref_id: uploadTransactionData.txnRefId,
      remark: uploadTransactionData.remark,
      created_at: getTimestamp(uploadTransactionData.createdAt),
      due_date: getTimestamp(uploadTransactionData.dueDate),
      ex_interest_date: selectedInvestment?.ex_interest_date,
    }
    // save new order
    try {
      const response = await axiosInstance.post(
        `bond-level-transactions`,
        newOrder
      )
      if (response) {
        const { data } = response
        if (Object.keys(data).find(el => data[el] !== 0)) {
          toast.success(
            `${Object.keys(data).map(
              (key, index) =>
                key &&
                (index !== 0 ? " " : "") + data[key] + " " + humanize(key)
            )} Entries Successfully Added`
          )
          masterData()
        } else {
          toast.warn("No Repayment Added")
        }
      }
      toggleConfirm()
    } catch (error) {
      const msg = error?.response?.data?.errors[0]?.message
      toast.error(msg)
      toggleConfirm()
    }
    setLoading(false)
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const downloadData = async fileName => {
    try {
      setLoading(true)

      const url = `admin-transaction-listing?$sort[created_at]=-1${filterUrl}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data || res.data
        const csvTableHeaders = repaymentColumns()
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr?.forEach(item => {
          const AmountInRS = item?.transaction_amount / 100
          item["transaction_amount"] = AmountInRS
        })
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  const handleUplaodExcel = e => {
    setUpload(true)
    toggle()
    setErrorData([])
  }
  const onSelectFile = e => {
    setFile(e.target.files[0])
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      new Promise((resolve, reject) => {
        getBase64(file, data => resolve(data))
      }).then(result => {
        setBase64(result)
        setLoading(false)
      })
    }
  }, [file])

  useEffect(async () => {
    if (selectedUser?.id) {
      setLoading(true)
      try {
        setSelectedOption(null)
        setInvestmentLot([])
        setSelectedInvLot(null)
        const userInvestmentGetUrl = `admin-user-investment-listing?$sort[created_at]=-1&inv_stage=Completed&userId=${selectedUser.id}`
        const userInvestmentData = await axiosInstance.get(userInvestmentGetUrl)
        if (userInvestmentData) {
          const _inv =
            userInvestmentData?.data?.data || userInvestmentData?.data
          setUserInvestment(_inv)
        }
      } catch (error) {
        toast.error(error?.message)
      }
      setLoading(false)
    }
  }, [selectedUser])
  const [changeDate, setChangeDate] = useState(false)
  const handleDateChange = () => {
    setChangeDate(true)
  }

  const dateTime = () => {
    return (
      <div className="row">
        <div className="mb-3 col-md-6">
          <AvField
            name="created_at"
            label="Payment Made On (IST)"
            type="date"
            errorMessage="Invalid Value"
            onChange={handleDateChange}
            validate={{
              required: { value: true },
            }}
            disabled={selectedInvLot?.id && !userInvTopupInInvLot.length}
            value={orderList.created_at || moment().format("YYYY-MM-DD")}
          ></AvField>
        </div>
        <div className="mb-3 col-md-6">
          <AvField
            name="due_date"
            label="Payment Due On"
            type="date"
            errorMessage="Invalid Value"
            validate={{
              required: { value: true },
            }}
            disabled={selectedInvLot?.id && !userInvTopupInInvLot.length}
            value={orderList.due_date}
          ></AvField>
        </div>
      </div>
    )
  }

  const handleCsvDownload = (json, projectName) => {
    JSONToCSVConvertor(json, `Repayment,${projectName}`, true)
  }

  const sampleCsv = [
    {
      pan_num: "GGYPK7946F",
      userInvestmentId: "683b27c0-be7f-4250-9a4c-5b605afc9016",
      investmentLotId: "683b27c0-be7f-4250-9a4c-5b605afc9016",
      userId: "a2ae90a6-3ac0-45ba-a830-c77b6f1bcd03",
      transaction_type: "Interest_Payment",
      payment_type: "Online",
      transaction_amount: -2000,
      payment_status: "success",
      created_at: "2023-12-31",
      due_date: "2023-12-31",
      txn_ref_id: 123,
    },
  ]

  const handleInvestmentChange = async inv => {
    setSelectedInvestment(inv)
    setIncludedUserInvestmentList([])
    setExcludedUserInvestmentList([])
    setSelectedUserInvestments([])
    setSelectedInvestmentLot([])
    try {
      setLoading(true)
      const url = `investment-lot?$sort[created_at]=-1&investmentId=${inv.id}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res?.data?.data || res?.data
        setInvestmentLot(
          data.map(item => {
            if (item.status) {
              return {
                ...item,
                investment_lot_name: item.investment_lot_name + " (Active)",
              }
            }
            return item
          })
        )
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }
  const handleInvLotChange = async lot => {
    setSelectedInvLot(lot)
    try {
      setLoading(true)
      const url = `admin-user-investment-listing?$sort[created_at]=-1&inv_stage=Completed&investmentLotId=${lot.id}&topup=true`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res?.data?.data || res?.data
        setUserInvTopupInInvLot(data)
        if (!data.length) {
          toast.error("No user investment topup found for this investment lot")
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(true)
      toast.error(error?.message)
    }
  }
  const handleSelectedUserInvestment = async (userInv, lotId = "") => {
    setSelectedOption(userInv)
    setSelectedInvLot(null)
    setInvestmentLot([])
    try {
      setLoading(true)
      const url = `investment-lot?$sort[created_at]=-1&investmentId=${userInv.investmentId}`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res?.data?.data || res?.data
        if (lotId) {
          setSelectedInvLot(data.find(item => item.id == lotId))
        }
        setInvestmentLot(
          data.map(item => {
            if (item.status) {
              return {
                ...item,
                investment_lot_name: item.investment_lot_name + " (Active)",
              }
            }
            return item
          })
        )
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const handleInvestmentLotChange = async invLot => {
    setSelectedInvestmentLot(invLot)
    setIncludedUserInvestmentList([])
    setExcludedUserInvestmentList([])
    setSelectedUserInvestments([])
    try {
      setLoading(true)
      const ids = invLot.map(el => el.id)
      const url = `admin-user-investment-listing?$sort[created_at]=-1&inv_stage=Completed&investmentId=${selectedInvestment.id}&investmentLotIds=${ids}&topup=true`
      const res = await axiosInstance.get(url)
      if (res) {
        const data = res?.data?.data || res?.data
        // const uniqueUit = {}
        // data.forEach(obj => {
        //   uniqueUit[obj.userInvestmentId] = obj
        // })
        const userInvestmentList = Object.values(data)
        userInvestmentList.forEach(item => {
          if (
            moment(item.created_at).isBefore(
              selectedInvestment.ex_interest_date
            )
          ) {
            setIncludedUserInvestmentList(prev => [...prev, item])
          } else {
            setExcludedUserInvestmentList(prev => [...prev, item])
          }
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(true)
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    setExcludedUsers([])
    setSelectedUserToSendComm([])
  }, [selectedUserOption])

  const handleSelectionChange = e => {
    const previouslySelected = selectedUserInvestments || []
    const newSelected = e.filter(
      item => !previouslySelected.some(prev => prev.id === item.id)
    )
    const newUnselected = previouslySelected.filter(
      item => !e.some(selected => selected.id === item.id)
    )

    if (newSelected && newSelected.length > 0) {
      setExcludedUserInvestmentList(prev => [...prev, ...newSelected])
      setIncludedUserInvestmentList(prev =>
        prev.filter(
          item => !newSelected.some(selected => selected.id === item.id)
        )
      )
    }

    if (newUnselected && newUnselected.length > 0) {
      setIncludedUserInvestmentList(prev => [...prev, ...newUnselected])
      setExcludedUserInvestmentList(prev =>
        prev.filter(
          item => !newUnselected.some(unselected => unselected.id === item.id)
        )
      )
    }

    setSelectedUserInvestments(e)
  }

  const DownloadUserInvestmentList = type => {
    try {
      setLoading(true)
      const data =
        type === "include"
          ? includedUserInvestmentList
          : excludedUserInvestmentList

      const csvTableHeaders = [
        {
          text: "Investment Date",
          dataField: "created_at",
        },
        {
          text: "Project Name",
          dataField: "project_name",
        },
        {
          text: "Tranche",
          dataField: "investment_lot_name",
        },
        {
          text: "User Name",
          dataField: "user_name",
        },
        {
          text: "No. of Bonds",
          dataField: "lot_size",
        },
        {
          text: "Investor Pan Name",
          dataField: "name_on_pan",
        },
      ]
      const downladableArr = csvDownloadData(csvTableHeaders, data)
      JSONToCSVConvertor(
        downladableArr,
        type === "include"
          ? "Included User Investment Topups"
          : "Excluded User Investment Topups",
        true
      )
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>

        {accessControlData?.permissions?.REPAYMENTS_VIEW ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2} className="mb-3">
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {accessControlData?.permissions?.REPAYMENTS_ADD ? (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                        onClick={handleOrderClicks}
                      >
                        <i className="mdi mdi-plus me-1" />{" "}
                        {accessControlData?.permissions?.REPAYMENTS_ADD}
                      </Button>
                    ) : null}
                    {accessControlData?.permissions
                      ?.REPAYMENTS_UPLOAD_BULK_TRANSACTIONS ? (
                      <Button
                        type="button"
                        color="danger"
                        className="btn-rounded  mb-2 me-2"
                        onClick={handleUploadTransactionClick}
                      >
                        <i className="mdi mdi-arrow-up-bold-circle"></i>{" "}
                        {
                          accessControlData?.permissions
                            ?.REPAYMENTS_UPLOAD_BULK_TRANSACTIONS
                        }
                      </Button>
                    ) : null}
                    {accessControlData?.permissions?.REPAYMENTS_UPLOAD ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={handleUplaodExcel}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-up-bold-circle"></i>{" "}
                        {accessControlData?.permissions?.REPAYMENTS_UPLOAD}
                      </Button>
                    ) : null}
                    {accessControlData?.permissions
                      ?.REPAYMENTS_SEND_PAYOUT_INFO ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded mb-2 me-2"
                        onClick={handleSendBulkEmailClick}
                      >
                        <i className="mdi mdi-telegram me-1" />{" "}
                        {
                          accessControlData?.permissions
                            ?.REPAYMENTS_SEND_PAYOUT_INFO
                        }
                      </Button>
                    ) : null}
                    {accessControlData?.permissions
                      ?.REPAYMENTS_DISTRIBUTOR_EMAILS ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded mb-2 me-2"
                        onClick={handleDistributorEmail}
                      >
                        <i className="mdi mdi-telegram me-1" />{" "}
                        {
                          accessControlData?.permissions
                            ?.REPAYMENTS_DISTRIBUTOR_EMAILS
                        }
                      </Button>
                    ) : null}
                    {accessControlData?.permissions?.REPAYMENTS_SAMPLE_CSV ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={() =>
                          handleCsvDownload(sampleCsv, "Sample", true)
                        }
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        {accessControlData?.permissions?.REPAYMENTS_SAMPLE_CSV}
                      </Button>
                    ) : null}
                    {accessControlData?.permissions
                      ?.REPAYMENTS_REVIEW_PAYOUT_DATA ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded mb-2 me-2"
                        onClick={handleSendEmailClick}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        {
                          accessControlData?.permissions
                            ?.REPAYMENTS_REVIEW_PAYOUT_DATA
                        }
                      </Button>
                    ) : null}
                    {accessControlData?.permissions?.REPAYMENTS_DOWNLOAD ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn-rounded  mb-2 me-2"
                        onClick={() => downloadData("Repayments")}
                        style={{ marginRight: "3px" }}
                      >
                        <i className="mdi mdi-arrow-down-bold-circle"></i>{" "}
                        {accessControlData?.permissions?.REPAYMENTS_DOWNLOAD}
                      </Button>
                    ) : null}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={repaymentColumns(toggle)}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row justify-content-start">
                            <Col md={3}>
                              {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                              <div className="position-relative">
                                <label>User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Search by User Name, Email or Phone `}
                                  value={userFilter || ""}
                                />
                                {/* <i className="bx bx-search-alt"></i> */}
                              </div>
                              {/* </div> */}
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Project Name</label>
                                <ReactSelect
                                  users={investments}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "projectType")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["project_name"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Investment Lot</label>
                                <ReactSelect
                                  users={investmentLotPerInv}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "investmentLot")
                                  }
                                  selectedOption={
                                    investmentLotFilter?.id
                                      ? investmentLotFilter
                                      : null
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["investment_lot_name"]}
                                  isClearable={true}
                                  isDisabled={!investmentFilter?.id}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Transaction Type</label>
                                <ReactSelect
                                  users={transactionTypeForRepayment}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "transactionType")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["statusText"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-4">
                                <Label>Transaction Date</Label>
                                <Input
                                  name="date"
                                  type="date"
                                  value={transactionDateFilter}
                                  onChange={e => {
                                    setTransactionDateFilter(e.target.value)
                                  }}
                                  className="custom-input-height"
                                />
                              </div>
                            </Col>
                            {/* <Col md={3}>
                            <div className="mb-3">
                              <label>Payment Status</label>
                              <ReactSelect
                                users={paymentStatusForTransactions}
                                setSelectedOption={e =>
                                  handleFilterChange(e, "paymentStatus")
                                }
                                multiOptionLabel={true}
                                optionLabelKeys={["statusText"]}
                                isClearable={true}
                              />
                            </div>
                          </Col> */}
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                columns={repaymentColumns(toggle)}
                                data={orders}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              {Array.isArray(errorData) && errorData?.length ? (
                                <Modal
                                  isOpen={errorModal}
                                  toggle={() => setErrorModal(false)}
                                >
                                  <ModalHeader
                                    toggle={() => setErrorModal(false)}
                                    tag="h4"
                                  >
                                    Error Listing
                                  </ModalHeader>
                                  <ModalBody>
                                    {Array.isArray(errorData) &&
                                      errorData?.length &&
                                      errorData?.map((item, index) => {
                                        return (
                                          <div className="mb-3" key={index}>
                                            <ul>
                                              {errorMsg
                                                ? errorMsg
                                                : "Something Went Wrong"}
                                              <li>
                                                Pan Number :- {item?.pan_num}
                                              </li>
                                              <li>
                                                User Investment Id :-
                                                {item?.userInvestmentId}
                                              </li>
                                              <li>
                                                Payment Type :-{" "}
                                                {item?.payment_type}
                                              </li>
                                              <li>
                                                Transaction Amount:-{" "}
                                                {item?.transaction_amount}
                                              </li>
                                              <li>
                                                {" "}
                                                Transaction Ref Id :-{" "}
                                                {item?.txn_ref_id}
                                              </li>
                                            </ul>
                                          </div>
                                        )
                                      })}
                                  </ModalBody>
                                </Modal>
                              ) : null}

                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {isEdit
                                    ? "Update Repayment"
                                    : upload
                                    ? "Upload Repayments"
                                    : "Add Repayment"}
                                </ModalHeader>
                                <ModalBody>
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        {upload ? (
                                          <>
                                            <div className="mt-2 mb-3">
                                              {loading && <Loader />}
                                              <Label
                                                htmlFor="formFile"
                                                className="form-label"
                                              >
                                                Upload Repayments
                                              </Label>
                                              <Input
                                                className="form-control"
                                                type="file"
                                                accept=".csv"
                                                id="formFile"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                onChange={onSelectFile}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {!isEdit ? (
                                              <>
                                                <div className="mb-3">
                                                  <label>User</label>
                                                  <ReactSelect
                                                    users={users}
                                                    searchValue={searchValue}
                                                    setSearchValue={
                                                      handleUserSearch
                                                    }
                                                    selectedOption={
                                                      selectedUser
                                                    }
                                                    setSelectedOption={
                                                      setSelectedUser
                                                    }
                                                    multiOptionLabel={false}
                                                    optionLabelKeys={[
                                                      "user_pan?.name",
                                                      "email",
                                                      "phone",
                                                      "user_pan?.pan_number",
                                                    ]}
                                                  />
                                                </div>
                                                <div className="mb-3">
                                                  <label>Project Name</label>
                                                  <ReactSelect
                                                    isDisabled={
                                                      !selectedUser?.id
                                                    }
                                                    users={userInvestment}
                                                    setSelectedOption={
                                                      handleSelectedUserInvestment
                                                    }
                                                    selectedOption={
                                                      selectedOption
                                                    }
                                                    transaction={true}
                                                    multiOptionLabel={false}
                                                    optionLabelKeys={[
                                                      "project_name",
                                                    ]}
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              <div className="mb-3">
                                                <label>
                                                  User Investment Info
                                                </label>
                                                <ReactSelect
                                                  users={userInvestment}
                                                  selectedOption={{
                                                    user_name:
                                                      orderList.user_name,
                                                    email: orderList.email,
                                                    phone: orderList.phone,
                                                    project_name:
                                                      orderList.project_name_retain,
                                                  }}
                                                  setSelectedOption={
                                                    setSelectedOption
                                                  }
                                                  transaction={true}
                                                  isDisabled={true}
                                                />
                                              </div>
                                            )}
                                            <div className="mb-3">
                                              <label>Investment Lot</label>
                                              <ReactSelect
                                                users={investmentLot}
                                                multiOptionLabel={true}
                                                optionLabelKeys={[
                                                  "investment_lot_name",
                                                ]}
                                                selectedOption={selectedInvLot}
                                                setSelectedOption={
                                                  handleInvLotChange
                                                }
                                                isDisabled={
                                                  !investmentLot.length
                                                }
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="transaction_amount"
                                                label="Transaction Amount (In Paisa)"
                                                type="number"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  orderList.transaction_amount ||
                                                  ""
                                                }
                                                disabled={
                                                  selectedInvLot?.id &&
                                                  !userInvTopupInInvLot.length
                                                }
                                              ></AvField>
                                            </div>

                                            {/* <div className="mb-3">
                                                <AvField
                                                  name="payment_type"
                                                  label="Payment Type"
                                                  type="select"
                                                  className="form-select"
                                                  errorMessage="Invalid value"
                                                  sort="true"
                                                  value={
                                                    orderList?.payment_type ||
                                                    ""
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Select
                                                  </option>
                                                  {PaymentTypeForRepayment?.map(
                                                    (item, index) => (
                                                      <option
                                                        key={index}
                                                        value={item}
                                                      >
                                                        {humanize(item)}
                                                      </option>
                                                    )
                                                  )}
                                                </AvField>
                                              </div> */}

                                            <div className="mb-3">
                                              <AvField
                                                name="transaction_type"
                                                label="Transaction Type"
                                                type="select"
                                                className="form-select"
                                                errorMessage="Invalid value"
                                                sort="true"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  orderList.transaction_type
                                                }
                                                disabled={
                                                  selectedInvLot?.id &&
                                                  !userInvTopupInInvLot.length
                                                }
                                              >
                                                <option disabled value="">
                                                  Select
                                                </option>
                                                <option value="Interest_Payment">
                                                  Interest Payment
                                                </option>
                                                <option value="Tds">TDS</option>
                                                <option value="Principal_Repayment">
                                                  Principal Repayment
                                                </option>
                                                <option value="Principal_Prepayment">
                                                  Principal Prepayment
                                                </option>
                                              </AvField>
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="payment_status"
                                                label="Payment Status"
                                                type="select"
                                                className="form-select"
                                                errorMessage="Invalid value"
                                                sort="true"
                                                value={
                                                  orderList.payment_status || ""
                                                }
                                                disabled={
                                                  selectedInvLot?.id &&
                                                  !userInvTopupInInvLot.length
                                                }
                                              >
                                                <option disabled value="">
                                                  Select
                                                </option>
                                                <option value="initiated">
                                                  Initiated
                                                </option>
                                                <option value="failed">
                                                  Failed
                                                </option>
                                                <option value="success">
                                                  Success
                                                </option>
                                              </AvField>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="txn_ref_id"
                                                label="Transaction Reference Id"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={
                                                  orderList.txn_ref_id || ""
                                                }
                                                disabled={
                                                  selectedInvLot?.id &&
                                                  !userInvTopupInInvLot.length
                                                }
                                              ></AvField>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="remark"
                                                label="Remarks"
                                                type="text"
                                                errorMessage="Invalid value"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                disabled={
                                                  selectedInvLot?.id &&
                                                  !userInvTopupInInvLot.length
                                                }
                                                value={orderList.remark || ""}
                                              ></AvField>
                                            </div>
                                            {dateTime()}
                                          </>
                                        )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                            disabled={
                                              selectedInvLot?.id &&
                                              !userInvTopupInInvLot.length
                                            }
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                              <Modal
                                isOpen={uploadTransactionModal}
                                toggle={uploadTransactionsToggle}
                                className="modal-lg"
                                backdrop="static"
                                keyboard={false}
                              >
                                <ModalHeader
                                  toggle={uploadTransactionsToggle}
                                  tag="h4"
                                >
                                  Upload Bulk Transactions
                                </ModalHeader>
                                <ModalBody>
                                  <AvForm
                                    onValidSubmit={handleTransactionSubmit}
                                  >
                                    <Row form>
                                      <Col className="row">
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <label>Project Name</label>
                                            <ReactSelect
                                              users={investments.filter(
                                                el => el.status != "Exited"
                                              )}
                                              selectedOption={
                                                selectedInvestment
                                              }
                                              setSelectedOption={
                                                handleInvestmentChange
                                              }
                                              multiOptionLabel={true}
                                              optionLabelKeys={[
                                                "project_name",
                                                "location",
                                              ]}
                                              isDisabled={transactionConfirm}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <label>Investment Lot</label>
                                            <ReactMultiSelect
                                              options={investmentLot}
                                              selectedOption={
                                                selectedInvestmentLot
                                              }
                                              setSelectedOption={
                                                handleInvestmentLotChange
                                              }
                                              multiOptionLabel={true}
                                              optionLabelKeys={[
                                                "investment_lot_name",
                                              ]}
                                              isDisabled={
                                                transactionConfirm ||
                                                !selectedInvestment?.id
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                          <div className="d-flex justify-content-between">
                                            <label>
                                              Exclude User Investment Topup
                                            </label>
                                            {selectedInvestmentLot.length >
                                              0 && (
                                              <div className="d-flex">
                                                <Link
                                                  onClick={() =>
                                                    DownloadUserInvestmentList(
                                                      "include"
                                                    )
                                                  }
                                                  className="me-4"
                                                >
                                                  <i className="fa fa-download" />{" "}
                                                  Included Topups
                                                </Link>
                                                <Link
                                                  onClick={() =>
                                                    DownloadUserInvestmentList(
                                                      "exclude"
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-download" />{" "}
                                                  Excluded Topups
                                                </Link>
                                              </div>
                                            )}
                                          </div>
                                          <ReactMultiSelect
                                            options={includedUserInvestmentList}
                                            setSelectedOption={
                                              handleSelectionChange
                                            }
                                            selectedOption={
                                              selectedUserInvestments
                                            }
                                            multiOptionLabel={true}
                                            optionLabelKeys={[
                                              "created_at",
                                              "project_name",
                                              "user_name",
                                              "lot_size",
                                              "email",
                                              "phone",
                                            ]}
                                            isDisabled={
                                              !selectedInvestmentLot.length ||
                                              transactionConfirm
                                            }
                                          />
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="createdAt"
                                              disabled={transactionConfirm}
                                              label="Payment Made On"
                                              type="date"
                                              errorMessage="Invalid Value"
                                              onChange={handleDateChange}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                uploadTransactionData?.createdAt ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="dueDate"
                                              disabled={transactionConfirm}
                                              label="Payment Due On"
                                              type="date"
                                              errorMessage="Invalid Value"
                                              onChange={handleDateChange}
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                uploadTransactionData?.dueDate ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="interestAmount"
                                              disabled={transactionConfirm}
                                              label="Gross Interest amount payable (In Rs)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              value={
                                                uploadTransactionData?.interestAmount ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="accruedInterestAmount"
                                              disabled={transactionConfirm}
                                              label="Accrued Interest Payment Amount (In Rs)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              value={
                                                uploadTransactionData?.accruedInterestAmount ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="tdsPercent"
                                              disabled={transactionConfirm}
                                              label="TDS Amount (%)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              min={0}
                                              max={100}
                                              value={
                                                uploadTransactionData?.tdsPercent ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="principalRepayment"
                                              disabled={transactionConfirm}
                                              label="Principal Repayment Amount (In Rs)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              value={
                                                uploadTransactionData?.principalRepayment ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="principalPrepayment"
                                              disabled={transactionConfirm}
                                              label="Principal Prepayment Amount (In Rs)"
                                              type="number"
                                              errorMessage="Invalid value"
                                              value={
                                                uploadTransactionData?.principalPrepayment ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="txnRefId"
                                              disabled={transactionConfirm}
                                              label="Transaction Reference Id"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                uploadTransactionData?.txnRefId ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="remark"
                                              disabled={transactionConfirm}
                                              label="Remarks"
                                              type="text"
                                              errorMessage="Invalid value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={
                                                uploadTransactionData?.remark ||
                                                ""
                                              }
                                            ></AvField>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-danger save-user"
                                            disabled={
                                              !includedUserInvestmentList.length
                                            }
                                          >
                                            Upload
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                              <Modal
                                isOpen={transactionConfirm}
                                toggle={toggleConfirm}
                                className="modal-lg"
                                backdrop="static"
                                keyboard={false}
                                scrollable={true}
                              >
                                <div className="modal-header">
                                  <h4 className="modal-title mt-0">
                                    Please verify the details before proceeding!
                                  </h4>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      toggleConfirm()
                                      uploadTransactionsToggle()
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <ModalBody className="p-4 pb-0">
                                  <Row>
                                    <Col className="row">
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>Project Name</div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {getOptionLabel({
                                              option:
                                                investments.find(
                                                  option =>
                                                    option.id ===
                                                    selectedInvestment?.id
                                                ) || "",
                                              investment: true,
                                            })}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>Investment Lot Name</div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {selectedInvestmentLot.map(el => (
                                              <div key={el.id}>
                                                {el.investment_lot_name}
                                              </div>
                                            ))}
                                          </p>
                                        </div>
                                      </div>
                                      {selectedUserInvestments &&
                                        selectedUserInvestments.length > 0 && (
                                          <div className="col-md-12">
                                            <div className="mb-3">
                                              <div>Exclude User Investment</div>
                                              <p
                                                className="fw-medium font-size-15"
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {selectedUserInvestments.map(
                                                  el => (
                                                    <>
                                                      {getOptionLabel({
                                                        option: el,
                                                      })}
                                                      <br />
                                                    </>
                                                  )
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>Payment Made On</div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {uploadTransactionData.createdAt}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>Payment Due On</div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {uploadTransactionData.dueDate}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>
                                            Gross Interest amount payable (In
                                            Rs)
                                          </div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {uploadTransactionData?.interestAmount
                                              ? `-${uploadTransactionData?.interestAmount}`
                                              : "0"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>
                                            Accrued Interest Payment Amount (In
                                            Rs)
                                          </div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {uploadTransactionData?.accruedInterestAmount
                                              ? `-${uploadTransactionData?.accruedInterestAmount}`
                                              : "0"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>TDS Amount (%)</div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {uploadTransactionData?.tdsPercent
                                              ? `${uploadTransactionData?.tdsPercent}`
                                              : "0"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>
                                            Principal Repayment Amount (In Rs)
                                          </div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {uploadTransactionData?.principalRepayment
                                              ? `-${uploadTransactionData?.principalRepayment}`
                                              : "0"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>
                                            Principal Prepayment Amount (In Rs)
                                          </div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {uploadTransactionData?.principalPrepayment
                                              ? `-${uploadTransactionData?.principalPrepayment}`
                                              : "0"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>Transaction Reference Id</div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {uploadTransactionData.txnRefId}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div>Remarks</div>
                                          <p
                                            className="fw-medium font-size-15"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            {uploadTransactionData.remark}
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          onClick={() => {
                                            toggleConfirm()
                                            uploadTransactionsToggle()
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={handleValidTransactionSubmit}
                                        >
                                          Confirm
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </ModalBody>
                              </Modal>
                              <Modal
                                isOpen={showEmailModal}
                                toggle={toggleEmailModal}
                              >
                                <ModalHeader toggle={toggleEmailModal} tag="h4">
                                  {sendEmailModal === 1
                                    ? isDistributorEmail
                                      ? "Send Distributor Emails"
                                      : isBulkEmail
                                      ? "Send Payout Confirmation"
                                      : "Review Payout Data"
                                    : `${
                                        channel == "whatsapp"
                                          ? "Whatsapp"
                                          : "Email"
                                      } Summary`}
                                </ModalHeader>
                                <AvForm onValidSubmit={handleValidOrderSubmit}>
                                  <ModalBody>
                                    <Row form>
                                      {sendEmailModal === 1 ? (
                                        <Col className="col-12">
                                          <div className="row">
                                            {!isBulkEmail ? (
                                              <>
                                                <div className="mb-3">
                                                  <label>Project Name</label>
                                                  <ReactSelect
                                                    users={investments}
                                                    setSelectedOption={
                                                      handleProjectName
                                                    }
                                                    multiOptionLabel={true}
                                                    optionLabelKeys={[
                                                      "project_name",
                                                      "location",
                                                    ]}
                                                    selectedOption={
                                                      selectedProject
                                                    }
                                                  />
                                                </div>
                                                <div className="mb-3">
                                                  <AvField
                                                    name="transaction_type"
                                                    label="Transaction Type"
                                                    type="select"
                                                    className={`form-select custom-input-height`}
                                                    // className={`form-select custom-input-height ${
                                                    //   transactionType === ""
                                                    //     ? "text-muted"
                                                    //     : ""
                                                    // }`}
                                                    errorMessage="Invalid value"
                                                    sort="true"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    value={transactionType}
                                                    onChange={e =>
                                                      setTransactionType(
                                                        e.target.value
                                                      )
                                                    }
                                                  >
                                                    <option disabled value="">
                                                      Select...
                                                    </option>
                                                    <option value="Interest_Payment">
                                                      Interest Payment
                                                    </option>
                                                    <option value="Principal_Repayment">
                                                      Principal Repayment
                                                    </option>
                                                    <option value="Principal_Prepayment">
                                                      Principal Prepayment
                                                    </option>
                                                    {/* {repaymentEmailTemplate.map(
                                                (item, index) => (
                                                  <option
                                                    key={item.templateName}
                                                    value={item.id}
                                                  >
                                                    {item.templateName}
                                                  </option>
                                                )
                                              )} */}
                                                  </AvField>
                                                </div>
                                                <div className="mb-3">
                                                  <AvField
                                                    name="created_at"
                                                    label="Transaction Date"
                                                    type="date"
                                                    errorMessage="Invalid Value"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    value={transactionDate}
                                                    onChange={
                                                      handleTransactionDate
                                                    }
                                                  ></AvField>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="mb-3">
                                                  <label>
                                                    Select Transaction Dates
                                                  </label>
                                                  <DatePicker
                                                    multiple
                                                    id="multiDatePicker"
                                                    value={transactionDates}
                                                    onChange={(index, values) =>
                                                      setTransactionDates(
                                                        values.validatedValue
                                                      )
                                                    }
                                                    plugins={[
                                                      <DatePanel key="datePanel" />,
                                                      <Toolbar
                                                        position="bottom"
                                                        sort={[
                                                          "deselect",
                                                          "close",
                                                        ]}
                                                        names={{
                                                          deselect: "Clear",
                                                          close: "Close",
                                                        }}
                                                        key="toolbar"
                                                      />,
                                                    ]}
                                                    style={{
                                                      width: "100%",
                                                      boxSizing: "border-box",
                                                      height: "38px",
                                                    }}
                                                    containerStyle={{
                                                      width: "100%",
                                                    }}
                                                    // render={<InputIcon />}
                                                  />
                                                </div>
                                                <div className="mb-3">
                                                  <AvField
                                                    name="channel"
                                                    label="Select Channel"
                                                    type="select"
                                                    className="form-select"
                                                    errorMessage="Invalid value"
                                                    sort="true"
                                                    validate={{
                                                      required: { value: true },
                                                    }}
                                                    value={channel}
                                                    onChange={e => {
                                                      setChannel(e.target.value)
                                                    }}
                                                  >
                                                    <option disabled value="">
                                                      Select
                                                    </option>

                                                    <option value="email">
                                                      Email
                                                    </option>
                                                    <option value="whatsapp">
                                                      Whatsapp
                                                    </option>
                                                  </AvField>
                                                </div>
                                              </>
                                            )}

                                            {isBulkEmail ? (
                                              <>
                                                <div className="d-flex gap-3 mb-3">
                                                  <AvField
                                                    type="radio"
                                                    name="userOptions"
                                                    id="all"
                                                    label="Send to All"
                                                    value="all"
                                                    checked={
                                                      selectedUserOption ===
                                                      "all"
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                    onChange={e => {
                                                      setSelectedUserOption(
                                                        e.target.value
                                                      )
                                                    }}
                                                  />
                                                  <AvField
                                                    type="radio"
                                                    name="userOptions"
                                                    label="Select Users"
                                                    id="selectUser"
                                                    value="selectUser"
                                                    checked={
                                                      selectedUserOption ===
                                                      "selectUser"
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                    onChange={e => {
                                                      setSelectedUserOption(
                                                        e.target.value
                                                      )
                                                    }}
                                                  />
                                                  <AvField
                                                    type="radio"
                                                    name="userOptions"
                                                    label="Exclude Users"
                                                    value="excludeUser"
                                                    id="excludeUser"
                                                    checked={
                                                      selectedUserOption ===
                                                      "excludeUser"
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                    onChange={e => {
                                                      setSelectedUserOption(
                                                        e.target.value
                                                      )
                                                    }}
                                                  />
                                                </div>
                                                {selectedUserOption ==
                                                "excludeUser" ? (
                                                  <div className="mb-3">
                                                    <label>Exclude Users</label>
                                                    <ReactMultiSelect
                                                      options={payoutEmailData}
                                                      multiOptionLabel={true}
                                                      optionLabelKeys={[
                                                        "user_name",
                                                        "email",
                                                        "phone",
                                                      ]}
                                                      isDisabled={
                                                        !payoutEmailData
                                                      }
                                                      selectedOption={
                                                        excludedUsers
                                                      }
                                                      setSelectedOption={
                                                        setExcludedUsers
                                                      }
                                                    />
                                                  </div>
                                                ) : null}
                                                {selectedUserOption ==
                                                "selectUser" ? (
                                                  <div className="mb-3">
                                                    <label>Select Users</label>
                                                    <ReactMultiSelect
                                                      options={payoutEmailData}
                                                      multiOptionLabel={true}
                                                      optionLabelKeys={[
                                                        "user_name",
                                                        "email",
                                                        "phone",
                                                      ]}
                                                      isDisabled={
                                                        !payoutEmailData
                                                      }
                                                      selectedOption={
                                                        selectedUserToSendComm
                                                      }
                                                      setSelectedOption={
                                                        setSelectedUserToSendComm
                                                      }
                                                    />
                                                  </div>
                                                ) : null}
                                              </>
                                            ) : null}
                                          </div>
                                        </Col>
                                      ) : null}
                                      {sendEmailModal === 2 ? (
                                        transactionEmailData?.total === 0 ? (
                                          <p className="font-size-15 text-muted mb-2">
                                            No Transactions found!
                                          </p>
                                        ) : (
                                          <Col className="col-12">
                                            {/* <h4>{selectedProject?.project_name}</h4> */}
                                            {!isBulkEmail ? (
                                              <p className="font-size-15 text-muted mb-3">
                                                Project Name:{" "}
                                                <b>
                                                  {transactionEmailData.project_name ||
                                                    selectedProject?.project_name}
                                                </b>
                                                <br />
                                                Transaction Type:{" "}
                                                <b>
                                                  {humanize(
                                                    transactionEmailData?.type
                                                  )}
                                                </b>
                                                <br />
                                                Date:{" "}
                                                <b>
                                                  {moment(
                                                    transactionDate
                                                  ).format("DD MMMM, YYYY")}
                                                </b>
                                                <br />
                                                Total Emails:{" "}
                                                <b>
                                                  {transactionEmailData?.total}
                                                </b>
                                              </p>
                                            ) : (
                                              <p className="font-size-15 text-muted mb-3 text-capitalize">
                                                Transaction Dates:{" "}
                                                <b>
                                                  {transactionDates.map(
                                                    (date, index) => {
                                                      if (
                                                        index !=
                                                        transactionDates.length -
                                                          1
                                                      ) {
                                                        return (
                                                          moment(date).format(
                                                            "DD MMMM, YYYY"
                                                          ) + "; "
                                                        )
                                                      } else {
                                                        return moment(
                                                          date
                                                        ).format(
                                                          "DD MMMM, YYYY"
                                                        )
                                                      }
                                                    }
                                                  )}
                                                </b>
                                                <br />
                                                Total {channel} :{" "}
                                                <b>
                                                  {transactionEmailData?.total}
                                                </b>
                                              </p>
                                            )}
                                            <p
                                              onClick={() =>
                                                JSONToCSVConvertor(
                                                  emailData,
                                                  isDistributorEmail
                                                    ? `Distributor Payout ${
                                                        channel == "whatsapp"
                                                          ? "Whatsapp"
                                                          : "Email"
                                                      } Summary`
                                                    : `Payout ${
                                                        channel == "whatsapp"
                                                          ? "Whatsapp"
                                                          : "Email"
                                                      } Summary`,
                                                  true
                                                )
                                              }
                                              className="mb-5 font-size-15"
                                            >
                                              <a className="link">
                                                Download Summary (CSV)
                                              </a>
                                            </p>
                                            <div className="d-flex gap-1">
                                              <input
                                                type="checkbox"
                                                id="emailConsent"
                                                checked={emailConsent}
                                                onChange={event =>
                                                  setEmailConsent(
                                                    event.target.checked
                                                  )
                                                }
                                              />{" "}
                                              <label
                                                className="mb-0 font-size-14 text-muted"
                                                htmlFor="emailConsent"
                                              >
                                                I have reviewed the {channel}{" "}
                                                summary
                                              </label>
                                            </div>
                                          </Col>
                                        )
                                      ) : null}
                                    </Row>
                                    {sendEmailModal === 1 ? (
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                              disabled={
                                                !isBulkEmail &&
                                                !isDistributorEmail
                                                  ? transactionEmailData?.data
                                                      ?.length
                                                    ? false
                                                    : true
                                                  : false
                                              }
                                            >
                                              {isBulkEmail || isDistributorEmail
                                                ? "Submit"
                                                : "Download"}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    ) : null}
                                  </ModalBody>
                                  {sendEmailModal === 2 ? (
                                    <ModalFooter className="d-block">
                                      {/* <div className="d-flex gap-1">
                                      <input
                                        type="checkbox"
                                        id="emailConsent"
                                        checked={emailConsent}
                                        onChange={e =>
                                          setEmailConsent(event.target.checked)
                                        }
                                      />{" "}
                                      <label
                                        className="mb-0 font-size-12"
                                        htmlFor="emailConsent"
                                      >
                                        I have reviewed the email summary.
                                      </label>
                                    </div> */}
                                      <div
                                        className={
                                          "d-flex justify-content-between align-items-center"
                                        }
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() => setSendEmailModal(1)}
                                        >
                                          Back
                                        </button>
                                        <button
                                          type="submit"
                                          className="btn btn-success save-user"
                                          disabled={!emailConsent}
                                        >
                                          {channel == "whatsapp"
                                            ? " Send Whatsapp"
                                            : " Send Emails"}
                                        </button>
                                      </div>
                                    </ModalFooter>
                                  ) : null}
                                </AvForm>
                              </Modal>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}`}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default Repayment
