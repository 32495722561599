import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { toast } from "react-toastify"
import { axiosInstance } from "ConfigAxioxinstance"
import BenposProjectCard from "components/Common/BenposProjectCard"
import { useParams } from "react-router-dom"
import HeaderWithBackBtn from "components/Common/HeaderWithBackBtn"
import AddRemarkBtn from "components/Remark/AddRemarkBtn"

const BondReconciliation = () => {
  const [loading, setLoading] = useState(false)
  const params = useParams()
  const { benposdate, investmentId } = params

  const [bondDistributionData, setBondDistributionData] = useState([])

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker("/benpos-master")
    setAccessControlData(permissions)
  }, [])

  const filterUrl = `${investmentId ? `?investmentId=${investmentId}` : ""}${
    benposdate ? `&benpos_date=${benposdate}` : ""
  }`

  const masterData = async () => {
    setLoading(true)
    const getUrl = `/benpos-report/reconciliation${filterUrl}`

    try {
      const response = await axiosInstance.get(getUrl)

      if (response) {
        const data = response?.data?.data || response?.data
        // const data = {
        //   common: 150,
        //   system: 50,
        //   benpos: 50,
        //   sold: {
        //     completed: 200,
        //     cum_interest: 150,
        //     ex_interest: 50,
        //   },
        //   dtr: {
        //     transferred: 150,
        //     others: 50,
        //   },
        //   total_bonds: 250,
        // }
        setBondDistributionData(data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    masterData()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BENPOS_BOND_RECONCILIATION ? (
          <Container fluid>
            <HeaderWithBackBtn title={"Bond Reconciliation"} />
            <hr />
            <div className="d-flex justify-content-end mb-2">
              <AddRemarkBtn
                benpos_date={benposdate}
                investmentId={investmentId}
                report_type={"BOND_RECONCILICATION"}
              />
            </div>
            <BenposProjectCard
              investmentId={investmentId}
              benpos_date={benposdate}
            />
            <Row>
              <h5 className="mb-3">Overview</h5>
              <Col lg={4}>
                <Card className="mini-stats-wid" style={{ height: "16rem" }}>
                  <CardBody>
                    <h5>Matching Status</h5>
                    <hr className="my-1" />
                    <div className="d-flex flex-column">
                      <div className="m-2">
                        <p className="text-muted mb-2">COMMON</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.common}
                        </h6>
                      </div>
                      <div className="m-2">
                        <p className="text-muted mb-2">SYSTEM</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.system}
                        </h6>
                      </div>
                      <div className="m-2">
                        <p className="text-muted mb-2">BENPOS</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.benpos}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid" style={{ height: "16rem" }}>
                  <CardBody>
                    <h5>Sale Status</h5>
                    <hr className="my-1" />
                    <div className="d-flex flex-column">
                      <div className="m-2">
                        <p className="text-muted mb-2">SOLD (COMPLETED)</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.sold?.completed}
                        </h6>
                      </div>
                      <div className="m-2">
                        <p className="text-muted mb-2">SOLD (CUM-INTEREST)</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.sold?.cum_interest}
                        </h6>
                      </div>
                      <div className="m-2">
                        <p className="text-muted mb-2">SOLD (EX-INTEREST)</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.sold?.ex_interest}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid" style={{ height: "16rem" }}>
                  <CardBody>
                    <h5>Inventory Status</h5>
                    <hr className="my-1" />
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-column">
                        <div className="m-2">
                          <p className="text-muted mb-2">
                            TOTAL BONDS (All Tranches)
                          </p>
                          <h6 className="mb-0 font-size-16">
                            {bondDistributionData?.total_bonds}
                          </h6>
                        </div>
                      </div>
                      <div className="m-2">
                        <p className="text-muted mb-2">TRANSFERRED</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.dtr?.transferred}
                        </h6>
                      </div>
                      <div className="m-2">
                        <p className="text-muted mb-2">NOT TRANSFERRED</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.dtr?.others}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col lg={3}>
                <Card className="mini-stats-wid" style={{ height: "16rem" }}>
                  <CardBody>
                    <h5>Total Bonds</h5>
                    <hr className="my-1" />
                    <div className="d-flex flex-column">
                      <div className="m-2">
                        <p className="text-muted mb-2">All Tranches</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.total_bonds}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
              {/* <h5>Bond Distribution Overview</h5>
              <Col lg={4}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">COMMON</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.common}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">SYSTEM</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.system}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">BENPOS</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.benpos}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">SOLD (Completed)</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.sold?.completed}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">SOLD (CUM-INTEREST)</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.sold?.cum_interest}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">SOLD (EX-INTEREST)</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.sold?.ex_interest}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">TRANSFERRED</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.dtr?.transferred}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">NOT TRANSFERRED</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.dtr?.others}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">TOTAL BONDS</p>
                        <h6 className="mb-0 font-size-16">
                          {bondDistributionData?.total_bonds}
                        </h6>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
            <Row className="mw-100">
              <h5>Validations</h5>
              <Card className="m-2 p-4">
                <Row className="font-size-16 fw-bold">
                  <Col md={3} className="mb-1">
                    Checks
                  </Col>
                  <Col md={7}>Description</Col>
                  <Col md={2}>Result</Col>
                  <hr />
                </Row>
                <Row className="font-size-14 mb-3 mt-2">
                  <Col md={3}>Total Bonds</Col>
                  <Col md={7}>
                    COMMON + BENPOS == Total Bonds (All Tranches)
                    <br />
                    <span className="text-secondary">
                      {bondDistributionData?.common +
                        bondDistributionData?.benpos}{" "}
                      == {bondDistributionData?.total_bonds}
                    </span>
                  </Col>
                  <Col md={2}>
                    <span className="rounded-pill py-1 px-3">
                      {bondDistributionData?.common +
                        bondDistributionData?.benpos ===
                      bondDistributionData?.total_bonds ? (
                        <i className="bx bx-check-circle text-success font-size-24" />
                      ) : (
                        <i className="bx bx-x-circle text-danger font-size-24" />
                      )}
                    </span>
                  </Col>
                </Row>
                <Row className="font-size-14 mb-3">
                  <Col md={3}>Sold Bonds</Col>
                  <Col md={7}>
                    COMMON + SYSTEM == Sold (Completed)
                    <br />
                    <span className="text-secondary">
                      {" "}
                      {bondDistributionData?.common +
                        bondDistributionData?.system}{" "}
                      == {bondDistributionData?.sold?.completed}
                    </span>
                  </Col>
                  <Col md={2}>
                    <span className="rounded-pill py-1 px-3">
                      {bondDistributionData?.common +
                        bondDistributionData?.system ===
                      bondDistributionData?.sold?.completed ? (
                        <i className="bx bx-check-circle text-success font-size-24" />
                      ) : (
                        <i className="bx bx-x-circle text-danger font-size-24" />
                      )}
                    </span>
                  </Col>
                </Row>
                <Row className="font-size-14 mb-3">
                  <Col md={3}>Transferred Bonds</Col>
                  <Col md={7}>
                    COMMON == Transferred == Sum of Cum-Interest Trades
                    <br />
                    <span className="text-secondary">
                      {bondDistributionData?.common} =={" "}
                      {bondDistributionData?.dtr?.transferred} =={" "}
                      {bondDistributionData?.sold?.cum_interest}
                    </span>
                  </Col>
                  <Col md={2}>
                    <span className="rounded-pill py-1 px-3">
                      {bondDistributionData?.common ===
                        bondDistributionData?.dtr?.transferred &&
                      bondDistributionData?.common ===
                        bondDistributionData?.sold?.cum_interest ? (
                        <i className="bx bx-check-circle text-success font-size-24" />
                      ) : (
                        <i className="bx bx-x-circle text-danger font-size-24" />
                      )}
                    </span>
                  </Col>
                </Row>
                <Row className="font-size-14 mb-3">
                  <Col md={3}>Others</Col>
                  <Col md={7}>
                    SYSTEM == Not Transferred == Sum of Ex-Interest Trades
                    <br />
                    <span className="text-secondary">
                      {bondDistributionData?.system} =={" "}
                      {bondDistributionData?.dtr?.others} =={" "}
                      {bondDistributionData?.sold?.ex_interest}
                    </span>
                  </Col>
                  <Col md={2}>
                    <span className="rounded-pill py-1 px-3">
                      {bondDistributionData?.system ===
                        bondDistributionData?.dtr?.others &&
                      bondDistributionData?.system ===
                        bondDistributionData?.sold?.ex_interest ? (
                        <i className="bx bx-check-circle text-success font-size-24" />
                      ) : (
                        <i className="bx bx-x-circle text-danger font-size-24" />
                      )}
                    </span>
                  </Col>
                </Row>
              </Card>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

export default BondReconciliation
