import React from "react"
import Select, { components } from "react-select"
import PropTypes from "prop-types"
import { getOptionLabel, getSearchOptionLabel } from "./common"

export default function ReactMultiSelect({
  options,
  setSelectedOption,
  selectedOption,
  transaction,
  investment,
  multiOptionLabel,
  optionLabelKeys,
  isDisabled = false,
  searchValue,
  setSearchValue,
  hideSelectedOptions = true,
  type,
}) {
  const customStyles = {
    option: (provided, { isSelected }) => ({
      ...provided,
      backgroundColor: isSelected ? "#f0f0f0" : "white",
      color: "black",
      ":hover": {
        backgroundColor: "#f0f0f0",
      },
    }),
  }

  const CustomOption = props => {
    const { data, isSelected } = props
    CustomOption.propTypes = {
      data: PropTypes.object,
      isSelected: PropTypes.any,
    }
    let label = ""

    switch (type) {
      case "investment_lot":
        label = data.investment_lot_name
        break
      default:
        label = ""
    }

    return (
      <components.Option {...props}>
        <div className="d-flex justify-content-between align-items-center">
          <span>{label}</span>
          {isSelected && (
            <span className="text-white ms-2 font-size-12 text-center rounded-circle d-flex justify-content-center align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 30 30"
              >
                <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path>
              </svg>
            </span>
          )}
        </div>
      </components.Option>
    )
  }

  if (!hideSelectedOptions) {
    return (
      <Select
        value={selectedOption}
        onChange={setSelectedOption}
        options={options}
        getOptionLabel={option =>
          multiOptionLabel
            ? getSearchOptionLabel({ option, optionLabelKeys })
            : getOptionLabel({
                option,
                transaction,
                investment,
                keys: optionLabelKeys,
              })
        }
        getOptionValue={option => option.id}
        isMulti
        isDisabled={isDisabled}
        inputValue={searchValue}
        onInputChange={setSearchValue}
        hideSelectedOptions={hideSelectedOptions}
        styles={customStyles}
        components={{ Option: CustomOption }}
      />
    )
  } else {
    return (
      <Select
        value={selectedOption}
        onChange={setSelectedOption}
        options={options}
        getOptionLabel={option => {
          if (multiOptionLabel) {
            return getSearchOptionLabel({ option, optionLabelKeys })
          } else {
            return getOptionLabel({
              option,
              transaction,
              investment,
              keys: optionLabelKeys,
            })
          }
        }}
        getOptionValue={option => option.id}
        isMulti={true}
        isDisabled={isDisabled}
        inputValue={searchValue}
        onInputChange={setSearchValue}
      />
    )
  }
}

ReactMultiSelect.propTypes = {
  options: PropTypes.array,
  setSelectedOption: PropTypes.func,
  selectedOption: PropTypes.array,
  transaction: PropTypes.bool,
  investment: PropTypes.bool,
  multiOptionLabel: PropTypes.bool,
  optionLabelKeys: PropTypes.array,
  isDisabled: PropTypes.bool,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  hideSelectedOptions: PropTypes.bool,
  type: PropTypes.string,
}
