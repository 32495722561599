import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { axiosInstance } from "ConfigAxioxinstance"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import { useParams, Link } from "react-router-dom"
import BenposProjectCard from "components/Common/BenposProjectCard"
import AuditTrail from "common/AuditTrail/AuditTrail"
import Unauthorized from "common/Unauthorized"
import HeaderWithBackBtn from "components/Common/HeaderWithBackBtn"
import RemarkCard from "../../components/Remark/RemarkCard"
import ReportStatus from "./ReportStatus"
import { csvDownloadData } from "constants/common"
import { JSONToCSVConvertor } from "common/jsontocsv"

const InterestAndRepaymentDashboard = () => {
  const [loading, setLoading] = useState(false)
  const [reportsData, setReportsData] = useState([])
  const [approversList, setApproversList] = useState([])
  const [accessControlData, setAccessControlData] = useState([])
  const [selectedLogFilter, setSelectedLogFilter] = useState("")
  const [auditTrailModal, setAuditTrailModal] = useState(false)
  const [showApproversData, setShowApproversData] = useState({})
  const [approvalDetailsModal, setApprovalDetailsModal] = useState(false)
  const [selectedApprover, setSelectedApprover] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const toggleApprovalDetailsModal = () => {
    setApprovalDetailsModal(!approvalDetailsModal)
  }

  const dollarIndianLocale = Intl.NumberFormat("en-IN")
  const handleAmount = amount => {
    return dollarIndianLocale.format(amount)
  }

  const handleDoc = async file => {
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    const permissions = permissionChecker("/benpos-master")
    setAccessControlData(permissions)
  }, [])

  const params = useParams()
  const { benposdate, investmentId } = params

  const masterData = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(
        `/interest-approval-policy?investmentId=${investmentId}&benpos_date=${benposdate}`
      )

      const approvalData = response?.data?.data || response?.data

      const reportsData = [
        {
          path: `/benpos-master?type=validation&investmentId=${investmentId}&benpos-date=${benposdate}`,
          label: "Validation Report",
          isLocked: false,
        },
        {
          path: `/benpos-master/bond-reconciliation/${investmentId}/${benposdate}`,
          label: "Bond Reconciliation",
          isLocked: false,
        },
        {
          path: `/benpos-master/exception-report/${investmentId}/${benposdate}`,
          label: "Exception Report",
          isLocked: false,
        },
        {
          path: `/benpos-master/tranche-allocation/${investmentId}/${benposdate}`,
          label: "Tranche & TDS Allocation",
          isLocked: false,
        },
        {
          path: `/benpos-master?type=interest&investmentId=${investmentId}&benpos-date=${benposdate}`,
          label: "Interest Calculation",
          policies: approvalData.filter(
            item => item.report_type === "INTEREST_CALCULATION"
          ),
          isLocked: false,
        },
        {
          path: `/benpos-master/bank-transfer/${investmentId}/${benposdate}?start_date=${
            approvalData.find(item => item?.interest_approval)
              ?.interest_approval?.start_date
          }&end_date=${
            approvalData.find(item => item?.interest_approval)
              ?.interest_approval?.end_date
          }`,
          label: "Bank Transfer File",
          policies: approvalData.filter(
            item => item.report_type === "BANK_TRANSFER_FILE"
          ),
          isLocked: approvalData.some(
            item =>
              item.report_type === "INTEREST_CALCULATION" &&
              item?.interest_approval?.status !== "APPROVED"
          ),
        },
      ]

      setReportsData(reportsData)

      const approverList = approvalData.reduce((acc, item) => {
        const reportType = item?.report_type

        if (!acc[reportType]) {
          acc[reportType] = []
        }

        acc[reportType].push(item)

        return acc
      }, {})

      setApproversList(approverList)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    masterData()
  }, [])

  const getApprovalCount = report => {
    return report.policies.filter(
      item => item.interest_approval?.status !== "APPROVED"
    )?.length
  }

  const handleDownloadBenposFile = async (userId, fileName) => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(
        `/user-benpos?investmentId=${investmentId}&benpos_date=${benposdate}&userId=${userId}`
      )
      const benposColumns = [
        {
          dataField: "project_name",
          text: "Project Name",
        },
        {
          dataField: "benpos_date",
          text: "BenPos Date",
        },
        {
          dataField: "investor_name",
          text: "Investor Name",
        },
        {
          dataField: "pan_number",
          text: "PAN Number",
        },
        {
          dataField: "debentures",
          text: "Debentures",
        },
        {
          dataField: "bank_name",
          text: "Bank Name",
        },
        {
          dataField: "bank_account_number",
          text: "Bank Account Number",
        },
        {
          dataField: "bank_ifsc",
          text: "Bank IFSC",
        },
        {
          dataField: "demat_id",
          text: "Demat Id",
        },
      ]

      response?.data?.data?.forEach(el => {
        el.project_name = selectedProject?.project_name
      })
      const downloadableArr = csvDownloadData(
        benposColumns,
        response?.data?.data
      )
      JSONToCSVConvertor(downloadableArr, fileName?.replace(".csv", ""), true)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.BENPOS_VIEW ? (
          <Container fluid>
            <HeaderWithBackBtn title={"Interest Processing"} />
            <hr />
            <BenposProjectCard
              benpos_date={benposdate}
              investmentId={investmentId}
              onLoadComplete={setSelectedProject}
            />
            <Row className="my-4 d-flex align-items-stretch">
              <Col md={7} className="d-flex flex-column">
                <Card style={{ maxHeight: "80vh", overflow: "auto" }}>
                  <CardBody>
                    <h5>Reports</h5>
                    <hr className="my-3" />
                    <div className="d-flex flex-column">
                      {reportsData &&
                        reportsData.map((report, index) => {
                          return (
                            <div
                              key={report.label}
                              className={`p-3 d-flex justify-content-between align-items-center flex-column ${
                                index + 1 !== reportsData.length &&
                                "border-bottom"
                              }`}
                            >
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <div className="d-flex align-items-center gap-2">
                                  <span className="bg-light p-2 rounded-2">
                                    <svg
                                      viewBox="0 0 24 24"
                                      width="26px"
                                      fill={"#777"}
                                    >
                                      <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                                    </svg>
                                  </span>
                                  <div className="ms-3">
                                    <h5 className="my-2">{report.label}</h5>
                                    {report.policies &&
                                    report.policies.length ? (
                                      <Link
                                        className="mb-0 text-muted d-inline-flex align-items-center justify-content-between gap-1"
                                        onClick={() => {
                                          if (report.isLocked) {
                                            return
                                          }
                                          setShowApproversData(prev => ({
                                            ...prev,
                                            [index]: !prev[index],
                                          }))
                                        }}
                                      >
                                        <span>
                                          {getApprovalCount(report) === 0
                                            ? "All Approved"
                                            : `${getApprovalCount(
                                                report
                                              )} Approvals Pending`}
                                        </span>
                                        {!report?.isLocked && (
                                          <i
                                            className={`bx bx-chevron-${
                                              showApproversData[index]
                                                ? "up"
                                                : "down"
                                            }`}
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        )}
                                      </Link>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div className="d-flex flex-column gap-2 align-items-end">
                                  <ReportStatus
                                    report={report}
                                    reportsData={reportsData}
                                  />
                                </div>
                              </div>
                              {showApproversData[index] && report.policies && (
                                <div className="pt-3 w-100">
                                  <div className="mt-1 d-flex justify-content-between align-items-center">
                                    <h6 className="text-muted font-size-14">
                                      Approval Status
                                    </h6>
                                    <>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          setSelectedLogFilter(
                                            `current_state.report_type=${report.policies[0].report_type}`
                                          )
                                          setAuditTrailModal(!auditTrailModal)
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-content-paste font-size-20"
                                          id="logsToolTip"
                                        />
                                      </Link>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="logsToolTip"
                                      >
                                        Logs
                                      </UncontrolledTooltip>
                                    </>
                                  </div>
                                  <div className="">
                                    {report.policies.map(
                                      (policy, policyIndex) => (
                                        <div
                                          key={policy.userId + policyIndex}
                                          className={`d-flex justify-content-between align-items-center py-2 ${
                                            policyIndex + 1 !==
                                              report.policies.length &&
                                            "border-bottom"
                                          } `}
                                        >
                                          <div>
                                            <p className="mb-0 font-size-13 d-flex align-items-center gap-1">
                                              <i className="mdi mdi-account-outline fs-3"></i>{" "}
                                              <span>
                                                {policy.user.user_name} (
                                                {policy.user.role.role_name})
                                              </span>
                                            </p>
                                            {policy?.interest_approval ? (
                                              <p
                                                className="mb-0 font-size-12 text-primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setSelectedApprover(policy)
                                                  toggleApprovalDetailsModal()
                                                }}
                                              >
                                                View Details
                                              </p>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                          <div>
                                            <span
                                              className={`badge badge-soft-${
                                                policy?.interest_approval
                                                  ?.status === "APPROVED"
                                                  ? "success"
                                                  : policy?.interest_approval
                                                      ?.status === "REJECTED"
                                                  ? "danger"
                                                  : "warning"
                                              } font-size-12`}
                                            >
                                              {policy?.interest_approval
                                                ?.status || "PENDING"}
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        })}
                    </div>
                  </CardBody>
                </Card>
                <Modal
                  isOpen={approvalDetailsModal}
                  toggle={toggleApprovalDetailsModal}
                  size={"md"}
                >
                  <ModalHeader toggle={toggleApprovalDetailsModal} tag="h5">
                    {selectedApprover?.report_type === "INTEREST_CALCULATION"
                      ? "Interest Calculation"
                      : "Bank Transfer File"}
                  </ModalHeader>
                  <ModalBody>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="d-flex align-items-center">
                        <h6 className="mb-0 font-size-14">
                          {selectedApprover?.user?.user_name}
                        </h6>
                        <span className="text-muted ms-1">
                          ({selectedApprover?.user?.role?.role_name})
                        </span>
                      </div>
                      <div>
                        {selectedApprover?.interest_approval?.status ===
                        "APPROVED" ? (
                          <span
                            className={`badge badge-soft-success font-size-12`}
                          >
                            APPROVED
                          </span>
                        ) : (
                          <span
                            className={`badge badge-soft-danger font-size-12`}
                          >
                            REJECTED
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mb-3">
                      <h6 className="mb-2 font-size-14">Comments</h6>
                      <p className="text-muted mb-0">
                        {selectedApprover?.interest_approval?.comment ||
                          "No comments provided"}
                      </p>
                    </div>

                    {selectedApprover?.interest_approval?.status ===
                      "APPROVED" && (
                      <div>
                        {selectedApprover?.report_type ===
                          "INTEREST_CALCULATION" && (
                          <h6 className="font-size-14 mb-2">Other Details</h6>
                        )}

                        {selectedApprover?.interest_approval?.data
                          ?.calculation_file && (
                          <Link
                            to="#"
                            className="text-primary d-flex align-items-center"
                            onClick={() =>
                              handleDoc(
                                selectedApprover?.interest_approval?.data
                                  ?.calculation_file
                              )
                            }
                          >
                            <div
                              className="mb-3 py-1 px-2 rounded-pill border d-inline-block border-primary text-primary font-size-12"
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="18px"
                                fill="#556ee6"
                              >
                                <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                              </svg>{" "}
                              Uploaded Interest Calculation File
                            </div>
                          </Link>
                        )}

                        {selectedApprover?.interest_approval?.data
                          ?.calculated_values?.length > 0 && (
                          <div className="row g-3">
                            {selectedApprover.interest_approval.data.calculated_values.map(
                              item => (
                                <div
                                  key={item.investmentLotId}
                                  className="col-md-4"
                                >
                                  <div className="border rounded p-2">
                                    <p className="text-muted d-block mb-0">
                                      {item.investmentLot.investment_lot_name}
                                    </p>
                                    <p className="fw-bold mb-0">
                                      {handleAmount(item.gross_interest)}
                                    </p>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}

                        {selectedApprover?.report_type ===
                          "BANK_TRANSFER_FILE" && (
                          <>
                            <div className="mb-3">
                              <h6 className="mb-2 font-size-14">
                                Transfer Date
                              </h6>
                              <p className="text-muted mb-0">
                                {
                                  selectedApprover?.interest_approval?.data
                                    ?.transfer_date
                                }
                              </p>
                            </div>
                            <div className="mb-3">
                              <h6 className="mb-2 font-size-14">Client Name</h6>
                              <p className="text-muted mb-0">
                                {
                                  selectedApprover?.interest_approval?.data
                                    ?.bankInvestmentAccount?.client_name
                                }
                              </p>
                            </div>
                            <div className="mb-3">
                              <h6 className="mb-2 font-size-14">Narration</h6>
                              <p className="text-muted mb-0">
                                {
                                  selectedApprover?.interest_approval?.data
                                    ?.bankInvestmentAccount?.narration
                                }
                              </p>
                            </div>
                            <div className="mb-3">
                              <Link
                                to="#"
                                className="text-primary d-flex align-items-center"
                                onClick={() =>
                                  handleDownloadBenposFile(
                                    selectedApprover?.userId,
                                    `Uploaded Benpos File (${selectedApprover?.user?.user_name})`
                                  )
                                }
                              >
                                <div
                                  className="mb-3 py-1 px-2 rounded-pill border d-inline-block border-primary text-primary font-size-12"
                                  style={{ cursor: "pointer" }}
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="18px"
                                    fill="#556ee6"
                                  >
                                    <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                                  </svg>{" "}
                                  Uploaded Benpos File
                                </div>
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </ModalBody>
                </Modal>
              </Col>
              <Col
                md={5}
                className="h-100 overflow-hidden"
                style={{ maxHeight: "80vh" }}
              >
                <Card
                  className="h-100"
                  style={{
                    maxHeight: "38vh",
                  }}
                >
                  <CardBody>
                    <h5>Approvers</h5>
                    <hr />
                    {approversList?.INTEREST_CALCULATION?.length &&
                    approversList?.BANK_TRANSFER_FILE?.length ? (
                      <div
                        className="d-flex flex-column mt-3 p-1"
                        style={{ maxHeight: "25vh", overflowY: "auto" }}
                      >
                        <b>Interest Calculation</b>
                        <div className="px-1 pt-1">
                          {approversList?.INTEREST_CALCULATION?.map(
                            (approver, index) => {
                              return (
                                <p
                                  key={approver?.user?.id + index}
                                  className="mb-0 d-flex align-items-center gap-1"
                                >
                                  <i className="mdi mdi-account-outline fs-4"></i>
                                  {approver?.user?.user_name} (
                                  {approver?.user?.role?.role_name})
                                </p>
                              )
                            }
                          )}
                        </div>
                        <b className="mt-2">Bank Transfer File</b>
                        <div className="px-1 pt-1">
                          {approversList?.BANK_TRANSFER_FILE?.map(
                            (approver, index) => {
                              return (
                                <p
                                  key={approver?.user?.id + index}
                                  className="mb-0 d-flex align-items-center gap-1"
                                >
                                  <i className="mdi mdi-account-outline fs-4"></i>
                                  {approver?.user?.user_name} (
                                  {approver?.user?.role?.role_name})
                                </p>
                              )
                            }
                          )}
                        </div>
                      </div>
                    ) : (
                      <>No Approvers Found!</>
                    )}
                  </CardBody>
                </Card>
                <RemarkCard investmentId={investmentId} />
              </Col>
            </Row>

            <AuditTrail
              filterUrl={
                selectedLogFilter +
                `&current_state.benpos_date=${benposdate}&current_state.investmentId=${investmentId}`
              }
              modal={auditTrailModal}
              setModal={setAuditTrailModal}
            />
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

const UserDetails = ({ item }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <p className="mb-0 font-size-14">{item.user.user_name}</p>
        <p className="font-size-12 text-muted">
          {item.user?.role?.department?.replaceAll("_", " ")}
        </p>
      </div>
      {item.interest_approval?.status ? (
        <p
          className={`mb-0 badge badge-pill font-size-11 badge-soft-${
            item.interest_approval?.status == "APPROVED" ? "success" : "danger"
          }`}
        >
          {item.interest_approval?.status}
        </p>
      ) : (
        <p className={`mb-0 badge badge-pill font-size-11 badge-soft-primary`}>
          PENDING
        </p>
      )}
    </div>
  )
}
UserDetails.propTypes = {
  item: PropTypes.any,
}

export default InterestAndRepaymentDashboard
