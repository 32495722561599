import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { JSONToCSVConvertor } from "common/jsontocsv"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import {
  createFullAdress,
  distributorUsers,
  humanize,
  picUrl,
} from "../../constants/common"
import { validateEmail } from "../../constants/common"
import Loader from "../../common/Loader"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./styles.css"
import { AccessId, kycStatusType } from "constants/ConstantFields"
import { DealManagementAccess } from "common/AccessManagement"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { csvDownloadData } from "constants/common"
import ReactSelect from "constants/ReactSelect"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"

const UserListingTable = ({ rmId }) => {
  const [orders, setData] = useState([])
  const [file, setFile] = useState(null)
  const [phone, setPhone] = useState()
  const [loading, setLoading] = useState(false)
  const [rmManager, setRmManager] = useState([])
  const [finalSelectedRows, setFinalSelectedRows] = useState([])
  const [role, setRole] = useState([])
  const [editNiltds, setEditNilTds] = useState(false)
  const [nilTdsValue, setNilTdsValue] = useState()
  const [vpaConfirmModal, setVpaConfirmModal] = useState()
  const [vpaObj, setVpaObj] = useState({})
  const [selectedRole, setSelectedRole] = useState()

  const [assignParentModal, setAssignParentModal] = useState()
  const [selectedParent, setSelectedParent] = useState(null)

  const [users, setUsers] = useState([])
  const [searchValue, setSearchValue] = useState("")

  const [userName, setUserName] = useState("")
  const [panNumber, setPanNumber] = useState("")
  const [panName, setPanName] = useState("")
  const [panEmail, setPanEmail] = useState("")
  const [dobFromPan, setDobFromPan] = useState("")
  const [addressFromPan, setAddressFromPan] = useState("")
  const [panDetails, setPanDetails] = useState(null)
  const [zipCodeFromPan, setZipCodeFromPan] = useState("")
  const [cityFromPan, setCityFromPan] = useState("")
  const [stateFromPan, setStateFromPan] = useState("")
  const [countryFromPan, setCountryFromPan] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [panType, setPanType] = useState("")
  const [parentUser, setParentUser] = useState(null)

  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [userFilter, setUserFilter] = useState("")
  const [distributorFilter, setDistributorFilter] = useState({})
  const [rmFilter, setRmFilter] = useState({})
  const [total, setTotal] = useState(0)

  const masterData = async () => {
    setLoading(true)

    const filterUrl = `${userFilter.length >= 3 ? `&name=${userFilter}` : ``}${
      rmFilter?.id ? `&relManagerId=${rmFilter.id}` : ""
    }${distributorFilter?.id ? `&createdById=${distributorFilter.id}` : ""}`

    const usersGetUrl = `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[1][permissions]=OPS_USER&$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}${
      rmId ? `&relManagerId=${rmId}` : ""
    }`

    try {
      const userResponse = await axiosInstance.get(usersGetUrl)

      if (userResponse) {
        setTotal(userResponse.data.total)
        setData(userResponse?.data?.data || userResponse?.data)
        let pages = Math.ceil(
          (userResponse.data?.total || userResponse.total) /
            (userResponse.data?.limit || userResponse.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
      setLoading(false)
    }
    // setSelectedRow({})
    setLoading(false)
  }

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "distributor":
        setDistributorFilter(e)
        break
      case "rm":
        setRmFilter(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(async () => {
    masterData()
  }, [limit, skip, distributorFilter, rmFilter])

  useEffect(() => {
    if (!userFilter || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const [deleteItem, setDeleteItem] = useState("")

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToExpand: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setFinalSelectedRows([...finalSelectedRows, row])
      } else {
        setFinalSelectedRows(finalSelectedRows.filter(r => r.id !== row.id))
      }
    },
    onSelectAll: (isSelect, rows) => {
      setFinalSelectedRows(isSelect ? rows : [])
    },
    selected: finalSelectedRows.map(row => row.id),
  }

  const [selectAllFinal, setSelectAllFinal] = useState()

  useEffect(() => {
    if (finalSelectedRows?.length && deleteItem) {
      let arr = finalSelectedRows.filter(item => item.id != deleteItem)
      // let arr = selectAll.filter(item => !keys.includes(item))
      setSelectAllFinal(arr)
      setDeleteItem("")
    }
  }, [deleteItem])

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [isEditBroker, setIsEditBroker] = useState(false)
  const [isDistributor, setIsDistributor] = useState(false)
  const [selectedDistributor, setSelectedDistributor] = useState({})
  const [selectedRmManager, setSelectedRmManager] = useState({})
  const [distributorList, setDistributorList] = useState([])

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const UserListingColumns = (download = false) => [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          {accessControlData?.permissions?.EXTERNAL_USERS_DELETE ? (
            <Link
              className="text-danger"
              onClick={() => handleDeleteOrder(order)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                {accessControlData?.permissions?.EXTERNAL_USERS_DELETE}
              </UncontrolledTooltip>
            </Link>
          ) : (
            "NA"
          )}
        </>
      ),
    },
    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "user_pan.name",
      text: "Name on PAN",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "distributor_kyc.name",
      text: "Legal Entity Name",
      sort: true,
    },
    {
      dataField: "user_pan.pan_number",
      text: "Pan Number",
      sort: true,
    },
    {
      dataField: "role.role_name",
      text: "Role Name",
      sort: true,
    },
    {
      dataField: "role.department",
      text: "Department",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => humanize(row?.role?.department),
    },
    {
      dataField: download ? "createdByName" : "createdBy.distributor_kyc.name",
      text: "Distributor Legal Entity Name",
      sort: true,
      formatter: (cellContent, row) =>
        row?.createdBy?.distributor_kyc?.name || "",
    },
    {
      dataField: "createdBy.user_name",
      text: "Distributor Name",
      sort: true,
    },
    {
      dataField: "createdBy.email",
      text: "Distributor Email",
      sort: true,
    },
    {
      dataField: "createdById",
      text: "Distributor Id",
      sort: true,
    },
    {
      dataField: "dob",
      text: "DOB",
      sort: true,
    },
    {
      dataField: "relManager.user_name",
      text: "Res Manager",
      sort: true,
      formatter: (cellContent, row) => handleRes(row.relManager),
    },
    {
      dataField: "parent.user_name",
      text: "Parent Name",
      sort: true,
    },
    {
      dataField: "parent.email",
      text: "Parent email",
      sort: true,
    },
    {
      dataField: "parentId",
      text: "Parent Id",
      sort: true,
    },
    {
      dataField: "address",
      text: "Full Address",
      sort: true,
    },
    {
      dataField: "address_line_1",
      text: "Address Line 1",
      sort: true,
    },
    {
      dataField: "address_line_2",
      text: "Address Line 2",
      sort: true,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
    },
    {
      dataField: "zip_code",
      text: "Pin Code",
      sort: true,
    },
    {
      dataField: "res_status",
      text: "Res Status",
      sort: true,
    },
    // {
    //   dataField: "nil_tds_applicable",
    //   text: "Nil TDS Applicable",
    //   sort: true,
    // },
    {
      dataField: "profile_picture",
      text: "Profile Pic",
      sort: true,
      formatter: (cellContent, row) => handleimg(row.profile_picture),
    },

    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      dataField: "kyc_status",
      text: "KYC Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        const status = kycStatusType.find(obj => obj.id == cellContent)
        return (
          <span
            className={`badge badge-pill font-size-12 badge-soft-${
              status ? status?.colorClassName : "secondary"
            }`}
          >
            {status?.statusText}
          </span>
        )
      },
    },
    {
      dataField: "is_email_verified",
      text: "Email Verification",
      sort: true,
    },
    {
      dataField: "is_phone_verified",
      text: "Phone Verification",
      sort: true,
    },

    {
      dataField: "whtsup_tnc",
      text: "Whatsapp tnc",
      sort: true,
    },

    {
      dataField: "self_ref_code",
      text: "Referral Code",
      sort: true,
    },

    {
      dataField: "va_account_id",
      text: "VPA Account Id",
      sort: true,
    },

    {
      dataField: "va_account_number",
      text: "VPA Account No.",
      sort: true,
    },
    {
      dataField: "va_bank_name",
      text: "VPA Bank Name",
      sort: true,
    },
    {
      dataField: "va_ifsc",
      text: "VPA IFSC",
      sort: true,
    },

    {
      dataField: "va_name",
      text: "VPA Name",
      sort: true,
    },

    {
      dataField: "va_user_id",
      text: "VPA User Id",
      sort: true,
    },
    {
      dataField: "va_created_at",
      text: "VPA Created Date",
      sort: true,
      formatter: (cellContent, row) =>
        row.va_created_at ? handleValidDate(row.va_created_at) : null,
    },
    {
      dataField: "id",
      text: "User Id",
      sort: true,
    },
  ]

  const handlePhoneChange = phone => {
    setPhone(phone)
  }

  const handlePanInput = async e => {
    setDobFromPan("")
    setAddressFromPan("")
    setPanDetails(null)
    setZipCodeFromPan("")
    setCityFromPan("")
    setCountryFromPan("")
    setStateFromPan("")
    setAddressLine1("")
    setAddressLine2("")
    const panInput = e.target.value
    if (panInput?.length == 10) {
      setPanNumber(panInput)
      const panData = {
        pan: panInput.toUpperCase(),
        consent: "Y",
        consent_text:
          "MY CONSENT MY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENTMY CONSENT",
      }
      setLoading(true)
      try {
        const response = await axiosInstance.post(`pan-verification`, panData)
        if (response) {
          setPanName(response?.data?.name)
          setUserName(response?.data?.name)
          setPanType(response?.data?.pan_type)
          if (!parentUser?.id) {
            // if (response?.data?.email) setPanEmail(response?.data?.email)
            // if (response?.data?.phone) {
            //   setPhone("91" + response?.data?.phone)
            // }
          }
          const fullAdress = createFullAdress(
            response?.data?.address_line_1,
            response?.data?.address_line_2,
            response?.data?.zip_code,
            response?.data?.city,
            response?.data?.state,
            response?.data?.country
          )
          setDobFromPan(response?.data?.dob)
          setAddressFromPan(fullAdress)
          setPanDetails(response?.data?.pan_details)
          setZipCodeFromPan(response?.data?.zip_code)
          setCityFromPan(response?.data?.city)
          setStateFromPan(response?.data?.state)
          setCountryFromPan(response?.data?.country)
          setAddressLine1(response?.data?.address_line_1)
          setAddressLine2(response?.data?.address_line_2)
        }
      } catch (error) {
        toast.error(error.message)
      }
      setLoading(false)
    }
  }

  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await axiosInstance.get(
          `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[1][permissions]=OPS_USER
            &name=${e}&$limit=50&$skip=0&is_registered=true&parentId=null`
        )
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  const handleDeleteOrder = async order => {
    setLoading(true)
    var r = confirm(`Are you sure want to delete this user`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(`users/${order.id}`)
        if (response) {
          masterData()
          toast.success("Successfully Deleted")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    setLoading(false)
  }

  const handleimg = img => {
    const date1 = img ? (
      <img
        src={img.indexOf("google") == -1 ? picUrl + img : img}
        alt="profile pic"
        style={{ maxWidth: "100px", maxHeight: "120px" }}
      ></img>
    ) : (
      "Profile pic not available"
    )
    return date1
  }

  const handleRes = overview => {
    const overviewSorted = overview?.user_name
    return overviewSorted
  }

  const handleOnChange = e => {
    const { name, value } = e.target
    switch (name) {
      case "panName":
        setUserName(value)
        break
      case "panEmail":
        setPanEmail(value)
        break
      default:
        break
    }
  }

  useEffect(() => {
    setOrderList(orders)
  }, [orders])

  useEffect(() => {
    if (!isEmpty(orders)) {
      if (!!isEdit) {
        setOrderList(orders)
        setIsEdit(false)
      }
      if (!!isEditBroker) {
        setOrderList(orders)
        setIsEditBroker(false)
      }
    }
  }, [orders])

  const toggle = () => {
    setModal(!modal)
  }

  const toggleAssignParentModal = () => {
    setAssignParentModal(!assignParentModal)
  }

  useEffect(() => {
    if (!modal) {
      setOrderList("")
      setPanEmail("")
      setPanName("")
      setUserName("")
      setPanNumber("")
      setPhone("")
      setDobFromPan("")
      setAddressFromPan("")
      setPanDetails(null)
      setZipCodeFromPan("")
      setCityFromPan("")
      setCountryFromPan("")
      setStateFromPan("")
      setAddressLine1("")
      setAddressLine2("")
      setParentUser(null)
    }
  }, [modal])

  const handleValidOrderSubmit = async (e, values) => {
    setLoading(true)
    if (isEditBroker) {
      if (!selectedRole?.id) {
        setLoading(false)
        toast.error("Please select a role")
        return false
      }
      const updateOrder =
        selectedRole.id === "null"
          ? {
              roleId: null,
              permissions: "USER",
            }
          : {
              roleId: selectedRole.id,
              permissions: "OPS_USER",
            }
      if (finalSelectedRows?.length) {
        for (let i = 0; i < finalSelectedRows.length; i++) {
          try {
            const response = await axiosInstance.patch(
              `users/${finalSelectedRows[i].id}`,
              updateOrder
            )
            if (response) {
              toast.success("Update Successful!")
            }
            toggle()
          } catch (error) {
            toast.error(error.message)
            setLoading(false)
            break
          }
        }
        // window.location.reload()
      } else {
        try {
          const response = await axiosInstance.patch(
            `users/${orderList.id}`,
            updateOrder
          )
          if (response) {
            // window.location.reload()
            toast.success("Successfully Updated")
          }
          toggle()
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
        }
      }
      setLoading(false)
      setFinalSelectedRows([])
    } else if (isEdit) {
      if (!selectedRmManager?.id) {
        toast.error("please select a RM")
        setLoading(false)
        return false
      }
      const updateOrder = {
        relManagerId: selectedRmManager.id || orderList.relManagerId,
      }

      if (finalSelectedRows?.length) {
        try {
          for (let i = 0; i < finalSelectedRows.length; i++) {
            const response = await axiosInstance.patch(
              `users/${finalSelectedRows[i].id}`,
              updateOrder
            )
          }
          toast.success("Successfully Updated")
          toggle()
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
        }
      } else {
        try {
          const response = await axiosInstance.patch(
            `users/${orderList.id}`,
            updateOrder
          )
          if (response) {
            toast.success("Successfully Updated")
            toggle()
          }
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
        }
      }
      setLoading(false)
      setFinalSelectedRows([])
    } else if (isDistributor) {
      if (!selectedDistributor?.id) {
        toast.error("please select a distributor")
        setLoading(false)
        return false
      }
      const updateOrder = {
        createdById:
          selectedDistributor.id === "null" ? null : selectedDistributor.id,
      }
      if (selectedDistributor?.id != "null") {
        updateOrder["relManagerId"] = selectedDistributor.relManagerId
      }
      if (finalSelectedRows?.length) {
        try {
          for (let i = 0; i < finalSelectedRows.length; i++) {
            const response = await axiosInstance.patch(
              `users/${finalSelectedRows[i].id}`,
              updateOrder
            )
          }
          toast.success("Successfully Updated")
          toggle()
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
        }
      } else {
        try {
          const response = await axiosInstance.patch(
            `users/${orderList.id}`,
            updateOrder
          )
          if (response) {
            // window.location.reload()
            toast.success("Successfully Updated")
            toggle()
          }
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
        }
      }
      setLoading(false)
      setFinalSelectedRows([])
    } else if (editNiltds) {
      const updateOrder = {
        nil_tds_applicable: nilTdsValue,
      }
      if (finalSelectedRows?.length) {
        try {
          for (let i = 0; i < finalSelectedRows.length; i++) {
            const response = await axiosInstance.patch(
              `users/${finalSelectedRows[i].id}`,
              updateOrder
            )
          }
          toast.success("Successfully Updated")
          toggle()
        } catch (error) {
          toast.error(error.message)
          setLoading(false)
        }
      }
      setFinalSelectedRows([])
    } else {
      if (!phone) {
        toast.error("Please enter phone number")
        setLoading(false)
        return
      }

      if (!validateEmail(panEmail)) {
        toast.error("Please enter valid email id")
        setLoading(false)
        return false
      }
      if (phone?.length < 12) {
        toast.error("Please enter valid phone number")
        setLoading(false)
        return false
      }
      const newUserDob = moment(dobFromPan, "DD/MM/YYYY").format("YYYY-MM-DD")
      const newOrder = {
        user_name: userName,
        email: panEmail,
        is_phone_verified: true,
        is_email_verified: true,
        tnc: true,
        phone: phone ? (phone.charAt(0) !== "+" ? "+" + phone : phone) : null,
        dob:
          panNumber && newUserDob && newUserDob != "Invalid date"
            ? newUserDob
            : undefined,
        whtsup_tnc: true,
        address: panNumber && addressFromPan ? addressFromPan : undefined,
        pan_details: panNumber && panDetails ? panDetails : undefined,
        zip_code: panNumber && zipCodeFromPan ? zipCodeFromPan : undefined,
        city: panNumber && cityFromPan ? cityFromPan : undefined,
        state: panNumber && stateFromPan ? stateFromPan : undefined,
        country: panNumber && countryFromPan ? countryFromPan : undefined,
        address_line_1: panNumber && addressLine1 ? addressLine1 : undefined,
        address_line_2: panNumber && addressLine2 ? addressLine2 : undefined,
        pan_number: panNumber.toUpperCase(),
        parentId: parentUser?.id ? parentUser.id : null,
      }
      // save new order

      try {
        const response = await axiosInstance.post(
          `users?mark_complete=true`,
          newOrder
        )
        if (response) {
          if (panType && panNumber) {
            const userPan = {
              pan_number: panNumber.toUpperCase(),
              pan_status: "VALID",
              pan_type: panType,
              name: panName,
              userId: response.data.id,
            }
            // try {
            //   const userObj = {
            //     userId: response.data.id,
            //   }
            //   await axiosInstance.post("mark-registration-complete", userObj)
            // } catch (error) {
            //   toast.error(error?.message)
            // }
            try {
              const panResponse = await axiosInstance.post(`user-pan`, userPan)
              if (panResponse) {
                toast.success("User PAN Successfully Added")
                setLoading(false)
                setPhone("")
                setPanEmail("")
                setPanName("")
                setUserName("")
                setPanNumber("")
                setDobFromPan("")
                setAddressFromPan("")
                setPanDetails(null)
                setZipCodeFromPan("")
                setCityFromPan("")
                setCountryFromPan("")
                setStateFromPan("")
                setAddressLine1("")
                setAddressLine2("")
              }
            } catch (error) {
              throw error
            }
          }
          toggle()
          toast.success("User Successfully Added")
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        throw error
      }
    }
    masterData()
  }

  const handleOrderClicks = () => {
    setOrderList("")
    setSelectedDistributor({})
    setSelectedRmManager({})
    setPanEmail("")
    setPanName("")
    setUserName("")
    setPanNumber("")
    setPhone()
    setIsEdit(false) // Manage RM
    setEditNilTds(false)
    setIsEditBroker(false) // Set role
    setIsDistributor(false) // Manage Distributor
    setDobFromPan("")
    setAddressFromPan("")
    setPanDetails(null)
    setZipCodeFromPan("")
    setCityFromPan("")
    setCountryFromPan("")
    setStateFromPan("")
    setAddressLine1("")
    setAddressLine2("")
    setUsers([])
    toggle()
  }

  const handleValidDate = date => {
    const date1 = moment(date).format("DD MMM Y hh:mm a")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  // let downloadableArr = []

  // let arr
  // if (orders) {
  //   let csvTableHeaders = UserListingColumns(true)
  //   var str = JSON.stringify(orders)
  //   arr = JSON.parse(str)
  //   arr.forEach(
  //     el => (el.createdByName = el?.createdBy?.distributor_kyc?.name || "")
  //   )
  //   downloadableArr = csvDownloadData(csvTableHeaders, arr)
  // }

  useEffect(async () => {
    try {
      const response = await axiosInstance.get(
        `users?$sort[created_at]=-1&permissions=OPS_USER`
      )
      const userResponse = response?.data?.data || response?.data
      if (userResponse) {
        const distributor = userResponse?.filter(
          ele =>
            ele.role?.department == "Distributor" &&
            (rmId ? ele.relManagerId === rmId : true)
        )
        setDistributorList(distributor)
        const relationship_manager = userResponse?.filter(
          ele => ele.roleId === 1
        )
        setRmManager(relationship_manager)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    try {
      const response = await axiosInstance.get(`role?$sort[created_at]=-1`)

      if (response) {
        setRole(response?.data?.data || response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }, [])

  const handleMultipleIds = async str => {
    if (!finalSelectedRows.length) {
      toast.error("Please select atleast a user")
      return false
    }
    if (finalSelectedRows.length > 1) {
      toast.error("Please select only one user")
      return
    }
    switch (str) {
      case "broker":
        setIsEditBroker(true)
        setIsEdit(false)
        setIsDistributor(false)
        setEditNilTds(false)
        break
      case "rm":
        setLoading(true)
        const rmRes = await distributorUsers()
        if (rmRes) {
          const relationship_manager = rmRes?.data?.data.filter(
            ele => ele.roleId === 1
          )
          setRmManager(relationship_manager)
        } else {
          toast.error("something went wrong")
        }
        setLoading(false)
        setIsEdit(true)
        setEditNilTds(false)
        setIsDistributor(false)
        setIsEditBroker(false)
        break
      case "distributor":
        setLoading(true)
        const distributorRes = await distributorUsers()
        if (distributorRes) {
          const distributorFromUser = distributorRes?.data?.data.filter(
            obj => obj.role?.department === "Distributor"
          )
          setDistributorList(distributorFromUser)
        } else {
          toast.error("something went wrong")
        }
        setLoading(false)
        setEditNilTds(false)
        setIsDistributor(true)
        setEditNilTds(false)
        setIsEditBroker(false)
        setIsEdit(false)
        break
      case "set_nil_tds":
        setEditNilTds(true)
        setIsDistributor(false)
        setIsEditBroker(false)
        setIsEdit(false)
        break
      default:
        break
    }
    toggle()
  }

  const openAssignParentModal = () => {
    if (!finalSelectedRows.length) {
      toast.error("Please select a user")
      return false
    }
    if (finalSelectedRows.length > 1) {
      toast.error("Please select only one user")
      return false
    }
    setUsers([])
    setAssignParentModal(true)
  }

  const assignParent = async () => {
    if (!selectedParent?.id) {
      toast.error("Please select a parent user")
      return
    }
    try {
      setLoading(true)
      const parentObj = {
        parentId: selectedParent?.id === "null" ? null : selectedParent?.id,
      }
      const response = await axiosInstance.patch(
        `users/${finalSelectedRows[0]?.id}`,
        parentObj
      )
      if (response) {
        toast.success("Parent assigned successfully!")
      }
    } catch (error) {
      toast.error(error.message)
      console.log({ error })
    } finally {
      setLoading(false)
      setAssignParentModal(false)
      setSelectedParent(false)
      setFinalSelectedRows([])
      masterData()
    }
  }

  const openVpaConfirmModal = () => {
    if (!finalSelectedRows.length) {
      toast.error("Please select a user")
      return false
    }
    if (finalSelectedRows.length > 1) {
      toast.error("Please select only one user")
      return false
    }
    setVpaObj({ userId: finalSelectedRows[0].id })
    setVpaConfirmModal(true)
  }

  const createNewVpa = async () => {
    if (!finalSelectedRows.length) {
      toast.error("Please select a user")
      return false
    }

    try {
      setLoading(true)
      for (let i = 0; i < finalSelectedRows.length; i++) {
        const obj = {
          user_name: finalSelectedRows[i].user_name,
          email: finalSelectedRows[i].email,
          phone: finalSelectedRows[i].phone,
          userId: finalSelectedRows[i].id,
          va_account_id: finalSelectedRows[i].va_account_id,
        }
        const response = await axiosInstance.post(`create-new-vpa`, obj)
      }
      toast.success("Successfully Created VPA")
      setFinalSelectedRows([])
      masterData()
    } catch (e) {
      toast.error(e?.message || "Something Went Wrong")
    } finally {
      setLoading(false)
      closeVpaConfirmModal()
    }
  }
  const closeVpaConfirmModal = () => {
    setVpaConfirmModal(false)
  }

  const createVpa = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.post(`create-vpa-from-admin`, vpaObj)

      if (response) {
        masterData()
        toast.success(" VPA Successfully Created")
        setLoading(false)
      }
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
    closeVpaConfirmModal()
  }

  useEffect(async () => {
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      try {
        const response = await axiosInstance.post(
          `/file-upload/?type=profile_pic`,
          formData
        )
        if (response) {
          setDp(response.data[0].name)
          setLoading(false)
        }
      } catch (error) {
        toast.error(error?.message)
        setLoading(false)
      }
    }
  }, [file])

  const downloadData = async fileName => {
    try {
      setLoading(true)
      const filterUrl = `${
        userFilter.length >= 3 ? `&name=${userFilter}` : ``
      }${rmFilter?.id ? `&relManagerId=${rmFilter.id}` : ""}${
        distributorFilter?.id ? `&createdById=${distributorFilter.id}` : ""
      }`

      const url = rmId
        ? `/users?$sort[created_at]=-1&permissions=USER&relManagerId=${rmId}&$sort[created_at]=-1${filterUrl}`
        : `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[1][permissions]=OPS_USER&$sort[created_at]=-1${filterUrl}`

      const res = await axiosInstance.get(url)
      if (res) {
        const data = res.data?.data
        const csvTableHeaders = UserListingColumns(true)
        const str = JSON.stringify(data)
        const arr = JSON.parse(str)
        arr.forEach(
          el => (el.createdByName = el?.createdBy?.distributor_kyc?.name || "")
        )
        const downladableArr = csvDownloadData(csvTableHeaders, arr)
        JSONToCSVConvertor(downladableArr, fileName, true)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error?.message)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}

      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {accessControlData?.permissions?.EXTERNAL_USERS_VIEW ? (
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={rmId ? "My Users" : accessControlData?.pageName}
            />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  {!rmId && (
                    <div className="col-auto">
                      {accessControlData?.permissions?.EXTERNAL_USERS_ADD && (
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={handleOrderClicks}
                        >
                          <i className="mdi mdi-plus me-1" />{" "}
                          {accessControlData?.permissions?.EXTERNAL_USERS_ADD}
                        </Button>
                      )}
                      {accessControlData?.permissions
                        ?.EXTERNAL_USERS_MANAGE_DISTRIBUTOR && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded  mb-2 me-2"
                          style={{ marginRight: "3px" }}
                          onClick={() => handleMultipleIds("distributor")}
                          disabled={finalSelectedRows?.length != 1}
                        >
                          <i className="mdi mdi-pencil me-1" />{" "}
                          {
                            accessControlData?.permissions
                              ?.EXTERNAL_USERS_MANAGE_DISTRIBUTOR
                          }
                        </Button>
                      )}
                      {accessControlData?.permissions
                        ?.EXTERNAL_USERS_SET_ROLE && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded  mb-2 me-2"
                          style={{ marginRight: "3px" }}
                          onClick={() => handleMultipleIds("broker")}
                          disabled={finalSelectedRows.length != 1}
                        >
                          <i className="mdi mdi-pencil me-1" />{" "}
                          {
                            accessControlData?.permissions
                              ?.EXTERNAL_USERS_SET_ROLE
                          }
                        </Button>
                      )}
                      {accessControlData?.permissions
                        ?.EXTERNAL_USERS_MANAGE_RM && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded  mb-2 me-2"
                          style={{ marginRight: "3px" }}
                          onClick={() => handleMultipleIds("rm")}
                          disabled={finalSelectedRows.length != 1}
                        >
                          <i className="mdi mdi-pencil me-1" />{" "}
                          {
                            accessControlData?.permissions
                              ?.EXTERNAL_USERS_MANAGE_RM
                          }
                        </Button>
                      )}
                      {accessControlData?.permissions
                        ?.EXTERNAL_USERS_ASSIGN_PARENT && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded  mb-2 me-2"
                          style={{ marginRight: "3px" }}
                          onClick={openAssignParentModal}
                          disabled={finalSelectedRows.length != 1}
                        >
                          <i className="mdi mdi-pencil me-1" />{" "}
                          {
                            accessControlData?.permissions
                              ?.EXTERNAL_USERS_ASSIGN_PARENT
                          }
                        </Button>
                      )}
                      {/* {accessControlData?.permissions
                        ?.EXTERNAL_USERS_SET_NIL_TDS && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded  mb-2 me-2"
                          style={{ marginRight: "3px" }}
                          onClick={() => handleMultipleIds("set_nil_tds")}
                          disabled={finalSelectedRows.length != 1}
                        >
                          <i className="mdi mdi-pencil me-1" />{" "}
                          {
                            accessControlData?.permissions
                              ?.EXTERNAL_USERS_SET_NIL_TDS
                          }
                        </Button>
                      )} */}
                      {accessControlData?.permissions
                        ?.EXTERNAL_USERS_CREATE_VPA && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded  mb-2 me-2"
                          style={{ marginRight: "3px" }}
                          onClick={openVpaConfirmModal}
                          disabled={finalSelectedRows.length != 1}
                        >
                          <i className="mdi mdi-pencil me-1" />{" "}
                          {
                            accessControlData?.permissions
                              ?.EXTERNAL_USERS_CREATE_VPA
                          }
                        </Button>
                      )}
                      {accessControlData?.permissions
                        ?.EXTERNAL_USERS_DOWNLOAD && (
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded  mb-2 me-2"
                          onClick={() => downloadData("Users")}
                          style={{ marginRight: "3px" }}
                        >
                          <i className="mdi mdi-arrow-down-bold-circle me-1" />{" "}
                          {
                            accessControlData?.permissions
                              ?.EXTERNAL_USERS_DOWNLOAD
                          }
                        </Button>
                      )}
                    </div>
                  )}
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={UserListingColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md={4}>
                              {/* <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block"> */}
                              <div className="position-relative">
                                <label>Search User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Name, Email, Phone, Pan Name, Pan, Legal Entity Name `}
                                  value={userFilter || ""}
                                />
                                {/* <i className="bx bx-search-alt"></i> */}
                              </div>
                              {/* </div> */}
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Distributor</label>
                                <ReactSelect
                                  users={[
                                    {
                                      id: "null",
                                      user_name: "None",
                                      email: "",
                                      phone: "",
                                    },
                                    ...distributorList,
                                  ]}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "distributor")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={[
                                    "user_name",
                                    "email",
                                    "phone",
                                  ]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            {!rmId && (
                              <Col md={3}>
                                <div className="mb-3">
                                  <label>Relationship Manager</label>
                                  <ReactSelect
                                    users={[
                                      {
                                        id: "null",
                                        user_name: "None",
                                        email: "",
                                        phone: "",
                                      },
                                      ...rmManager,
                                    ]}
                                    setSelectedOption={e =>
                                      handleFilterChange(e, "rm")
                                    }
                                    multiOptionLabel={true}
                                    optionLabelKeys={[
                                      "user_name",
                                      "email",
                                      "phone",
                                    ]}
                                    isClearable={true}
                                  />
                                </div>
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                columns={UserListingColumns()}
                                data={orders}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                              <Modal isOpen={modal} toggle={toggle}>
                                <ModalHeader toggle={toggle} tag="h4">
                                  {isEdit
                                    ? "Manage Relationship Manager"
                                    : isDistributor
                                    ? "Manage Distributor"
                                    : isEditBroker
                                    ? "User Role"
                                    : editNiltds
                                    ? "Set Nil TDS"
                                    : "Add User"}
                                </ModalHeader>
                                <ModalBody>
                                  {loading && <Loader />}
                                  <AvForm
                                    onValidSubmit={handleValidOrderSubmit}
                                  >
                                    <Row form>
                                      <Col className="col-12">
                                        {editNiltds ? (
                                          <div className="mb-3">
                                            <AvField
                                              name="nil_tds_applicable"
                                              label="Set Nil TDS Applicable"
                                              type="select"
                                              className="form-select"
                                              errorMessage="Invalid Value"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              style={{ widht: "100%" }}
                                              onChange={e => {
                                                setNilTdsValue(e.target.value)
                                              }}
                                            >
                                              <option disabled value="">
                                                Select
                                              </option>
                                              <option value="true">True</option>
                                              <option value="false">
                                                False
                                              </option>
                                            </AvField>
                                          </div>
                                        ) : null}
                                        {isEdit ? (
                                          <div className="mb-3">
                                            <label>Relationship Manager</label>
                                            <ReactSelect
                                              users={rmManager}
                                              setSelectedOption={
                                                setSelectedRmManager
                                              }
                                            />
                                          </div>
                                        ) : null}
                                        {isDistributor ? (
                                          <div className="mb-3">
                                            <label>Distributor</label>
                                            <ReactSelect
                                              users={[
                                                {
                                                  id: "null",
                                                  user_name: "None",
                                                  email: "",
                                                  phone: "",
                                                },
                                                ...distributorList,
                                              ]}
                                              setSelectedOption={
                                                setSelectedDistributor
                                              }
                                            />
                                          </div>
                                        ) : null}
                                        {isEditBroker ? (
                                          <div className="mb-3">
                                            <ReactSelect
                                              users={[
                                                {
                                                  id: "null",
                                                  role_name: "None",
                                                  department: "None",
                                                },
                                                ...role,
                                              ]}
                                              roles={true}
                                              setSelectedOption={
                                                setSelectedRole
                                              }
                                            />
                                          </div>
                                        ) : null}
                                        {!isEdit &&
                                        !isEditBroker &&
                                        !editNiltds &&
                                        !isDistributor ? (
                                          <div>
                                            <div className="mb-3">
                                              <AvField
                                                style={{
                                                  textTransform: "uppercase",
                                                }}
                                                name="pan_number"
                                                label="Enter Investor's PAN"
                                                type="text"
                                                errorMessage="Invalid PAN"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={panNumber || ""}
                                                maxLength="10"
                                                onChange={handlePanInput}
                                              ></AvField>
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="panName"
                                                label="User Name (will be autofilled from PAN)"
                                                autoComplete="off"
                                                type="text"
                                                errorMessage="Invalid User Name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userName || ""}
                                                onChange={handleOnChange}
                                              ></AvField>
                                            </div>
                                            <div className="my-3">
                                              <label>
                                                Select Parent User (if
                                                applicable)
                                              </label>
                                              <ReactSelect
                                                users={users}
                                                searchValue={searchValue}
                                                setSearchValue={
                                                  handleUserSearch
                                                }
                                                setSelectedOption={e => {
                                                  setPanEmail(e?.email || "")
                                                  setPhone(e?.phone || "91")
                                                  setParentUser(e)
                                                }}
                                                multiOptionLabel={false}
                                                optionLabelKeys={[
                                                  "user_pan?.name",
                                                  "email",
                                                  "phone",
                                                  "user_pan?.pan_number",
                                                ]}
                                                isClearable={true}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <AvField
                                                name="panEmail"
                                                label="Email (will be autofilled from Parent User if available)"
                                                type="text"
                                                errorMessage="Invalid Email"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={panEmail || ""}
                                                onChange={handleOnChange}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <p
                                                style={{
                                                  fontWeight: "500",
                                                  marginBottom: "0.5rem",
                                                }}
                                              >
                                                Mobile No. (will be autofilled
                                                from Parent User if available)
                                              </p>
                                              <div>
                                                <PhoneInput
                                                  inputClass="form-controls"
                                                  countryCodeEditable={false}
                                                  prefix="+"
                                                  value={phone || ""}
                                                  country={"in"}
                                                  placeholder="mobile"
                                                  onChange={phone =>
                                                    handlePhoneChange(phone)
                                                  }
                                                />
                                              </div>
                                            </div>
                                            {/* <div className="mb-3">
                                            <AvField
                                              name="user_name"
                                              label="Display Name"
                                              autoComplete="off"
                                              type="text"
                                              errorMessage="Invalid Name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.user_name || ""}
                                            ></AvField>
                                          </div>
                                          <div className="mb-3">
                                            <AvField
                                              name="email"
                                              label="Email"
                                              type="text"
                                              errorMessage="Invalid Email"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={orderList.email || ""}
                                            />
                                          </div>
                                          <div className="mb-3">
                                            <p>Phone</p>
                                            <div>
                                              <PhoneInput
                                                inputClass="form-controls"
                                                countryCodeEditable={false}
                                                prefix="+"
                                                value={phone}
                                                country={"in"}
                                                placeholder="phone"
                                                onChange={phone =>
                                                  setPhone(phone)
                                                }
                                              />
                                            </div>
                                          </div> */}
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            {orderList.relManagerId ||
                                            editNiltds
                                              ? "Update Now"
                                              : "Add Now"}
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
                              <Modal
                                isOpen={assignParentModal}
                                toggle={toggleAssignParentModal}
                              >
                                <ModalHeader
                                  toggle={toggleAssignParentModal}
                                  tag="h4"
                                >
                                  Assign Parent
                                </ModalHeader>
                                <ModalBody>
                                  <Row>
                                    <Col>
                                      <div className="mb-3">
                                        <label>Select Parent User</label>
                                        <ReactSelect
                                          // users={[
                                          //   {
                                          //     id: "null",
                                          //     user_pan: {
                                          //       name: "None",
                                          //       pan_number: "",
                                          //     },
                                          //     email: "",
                                          //     phone: "",
                                          //   },
                                          //   ...users,
                                          // ]}
                                          users={users}
                                          searchValue={searchValue}
                                          setSearchValue={handleUserSearch}
                                          setSelectedOption={e => {
                                            setSelectedParent(e)
                                          }}
                                          multiOptionLabel={false}
                                          optionLabelKeys={[
                                            "user_pan?.name",
                                            "email",
                                            "phone",
                                            "user_pan?.pan_number",
                                          ]}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end mt-3">
                                        <button
                                          type="button"
                                          className="btn btn-success save-user"
                                          onClick={assignParent}
                                        >
                                          Confirm
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </ModalBody>
                              </Modal>
                              <Modal
                                isOpen={vpaConfirmModal}
                                backdrop="static"
                                keyboard={false}
                              >
                                {/* <ModalHeader tag="h4">Are you sure ?</ModalHeader> */}
                                <ModalBody>
                                  <Row>
                                    <Col>
                                      <div className="p-2">
                                        <h4>
                                          Are you sure you want to create{" "}
                                          {vpaObj?.userId
                                            ? "VPA"
                                            : `New VPA for ${finalSelectedRows.length} user`}{" "}
                                          ?
                                        </h4>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <div className="text-end mt-3">
                                        <button
                                          type="button"
                                          className="btn btn-danger me-2"
                                          onClick={closeVpaConfirmModal}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-success save-user"
                                          onClick={
                                            vpaObj?.userId
                                              ? createVpa
                                              : createNewVpa
                                          }
                                        >
                                          Confirm
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}${
                                finalSelectedRows.length
                                  ? ` | Selected rows: ${finalSelectedRows.length}`
                                  : ""
                              }
                    `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
    </React.Fragment>
  )
}

UserListingTable.propTypes = {
  orders: PropTypes.array,
  rmId: PropTypes.string,
}

export default withRouter(UserListingTable)
