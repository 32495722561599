import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { axiosInstance } from "ConfigAxioxinstance"
import { toast } from "react-toastify"
import { JSONToCSVConvertor } from "common/jsontocsv"
import {
  csvDownloadData,
  generateFinancialYearList,
  handleAmount,
  handleValidDate,
  humanize,
  investmentData,
  maskEmail,
  maskPhoneNumber,
} from "constants/common"
import ReactSelect from "constants/ReactSelect"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  UncontrolledTooltip,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "common/Loader"
import { permissionChecker } from "common/PermissionChecker"
import Unauthorized from "common/Unauthorized"
import { kycStatusType } from "constants/ConstantFields"

const NilTdsCertificate = () => {
  const [data, setData] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [investments, setInvestments] = useState([])

  const [distributorList, setDistributorList] = useState([])
  const [selectedDistrinutor, setSelectedDistrinutor] = useState(null)

  const [rmList, setRmList] = useState([])
  const [selectedRm, setSelectedRm] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)

  const localUser = JSON.parse(localStorage.getItem("userInfo"))
  const isRm = localUser?.role?.department == "Relationship_Manager"
  const isDist = localUser?.role?.department === "Distributor"

  const [userFilter, setUserFilter] = useState(null)
  const [selectedFinacialYearFilter, setSelectedFinacialYearFilter] = useState()
  const [investmentFilter, setInvestmentFilter] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [users, setUsers] = useState([])
  const [searchValue, setSearchValue] = useState("")

  const [verifyModel, setVerifyModel] = useState(false)

  const [formValues, setFormValues] = useState({
    investment: null,
    user: null,
    financialYear: {
      id: "",
      startDate: "",
      endDate: "",
    },
    file: null,
    file_url: null,
  })

  const [accessControlData, setAccessControlData] = useState([])

  useEffect(() => {
    const permissions = permissionChecker()
    setAccessControlData(permissions)
  }, [])

  const filterUrl = `${userFilter?.length >= 3 ? `&name=${userFilter}` : ``}${
    investmentFilter?.id ? `&investmentId=${investmentFilter.id}` : ""
  }${
    selectedFinacialYearFilter?.id
      ? `&start_date=${selectedFinacialYearFilter.startDate}&end_date=${selectedFinacialYearFilter.endDate}`
      : ""
  }${isRm ? `&relManagerId=${localUser.id}` : ""}${
    isDist ? `&distributorId=${localUser.id}` : ""
  }${selectedDistrinutor ? `&distributorId=${selectedDistrinutor.id}` : ""}${
    selectedRm ? `&relManagerId=${selectedRm.id}` : ""
  }${selectedStatus ? `&status=${selectedStatus.id}` : ""}`

  const masterData = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(
        `/investor-nil-tds-certificate?$limit=${limit}&$skip=${skip}&$sort[created_at]=-1${filterUrl}`
      )

      if (response) {
        setData(response.data?.data || response.data)
        setTotal(response.data.total)
        let pages = Math.ceil(
          (response.data?.total || response.total) /
            (response.data?.limit || response.limit)
        )
        setTotalPage(pages)
      }
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getFilters = async () => {
    try {
      const investmentRes = await investmentData("Approved", "Closed", "Exited")
      const investments = investmentRes?.data?.data || investmentRes?.data
      setInvestments(investments)
      if (!isDist) {
        const opsUsersRes = await axiosInstance.get(
          `/users?$sort[created_at]=-1&permissions=OPS_USER`
        )
        if (opsUsersRes) {
          const opsUsers = opsUsersRes.data.data
          setDistributorList(
            opsUsers?.filter(user => {
              if (user?.role?.department === "Distributor") {
                user.user_name = user.distributor_kyc?.name
                if (isRm && user.relManagerId != localUser.id) {
                  return false
                }
                return true
              }
            })
          )
          setRmList(opsUsers?.filter(ele => ele.roleId == 1))
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getFilters()
  }, [])

  const toggle = () => {
    setModal(!modal)
  }

  const verifyToggle = () => {
    setVerifyModel(!verifyModel)
  }

  useEffect(async () => {
    masterData()
  }, [
    investmentFilter,
    limit,
    skip,
    selectedFinacialYearFilter,
    selectedDistrinutor,
    selectedRm,
    selectedStatus,
  ])

  const handleNext = prev => {
    setSkip(prev + limit)
    let page = (prev + limit) / limit + 1
    setCurrentPage(page)
  }

  const handlePrevious = prev => {
    setSkip(prev - limit)
    let page = (prev - limit) / limit + 1
    setCurrentPage(page)
  }

  const handleFilterChange = async (e, key) => {
    switch (key) {
      case "user":
        setUserFilter(e.target.value)
        break
      case "projectType":
        setInvestmentFilter(e)
        break
      case "distributor":
        setSelectedDistrinutor(e)
        break
      case "rm":
        setSelectedRm(e)
        break
      case "status":
        setSelectedStatus(e)
        break
      case "limit":
        setLimit(Number(e.target.value))
        break
      default:
        break
    }
    setSkip(0)
    setCurrentPage(1)
  }

  const onChangePagination = e => {
    const { value } = e.target
    if (value < 1 || value > totalPage) {
      return
    }
    setCurrentPage(value)
    if (value) {
      setSkip((value - 1) * limit)
    }
  }

  useEffect(() => {
    if (userFilter?.length == 0 || userFilter?.length >= 3) {
      masterData()
    }
  }, [userFilter])

  const handleRowClick = selected => {
    setSelectedRow(selected)
    setIsEdit(true)

    setFormValues({
      investment: selected.investment,
      user: selected.user,
      financialYear: {
        id: `${moment(selected.start_date).format("YYYY")}-${moment(
          selected.end_date
        ).format("YYYY")}`,
        startDate: selected.start_date,
        endDate: selected.end_date,
      },
      file: null,
      file_url: selected.file_url,
    })
    toggle()
  }

  const handleDeleteOrder = async order => {
    var r = confirm(`Are you sure want to delete?`)
    if (r == true) {
      try {
        const response = await axiosInstance.delete(
          `/investor-nil-tds-certificate/${order.id}`
        )
        if (response) {
          toast.success("Successfully Deleted")
          await masterData()
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const handleUpdateButtonClick = item => {
    setSelectedRow(item)
    verifyToggle()
  }

  const Columns = (download = false) => {
    let cols = []
    if (!download) {
      if (getPermissionHandler("update")) {
        cols.push({
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, order) => (
            <>
              <Link
                to="#"
                className="text-success"
                onClick={() => handleUpdateButtonClick(order)}
              >
                <Button
                  style={{
                    backgroundColor:
                      order.status == "VERIFIED" ? "green" : "grey",
                  }}
                >
                  {getPermissionHandler("update")}
                </Button>
              </Link>
            </>
          ),
        })
      }
      cols.push({
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            <div className="d-flex gap-3">
              <>
                {order.status != "VERIFIED" && getPermissionHandler("edit") ? (
                  <>
                    {!(isDist || isRm) || order.status === "RE_SUBMIT" ? (
                      <Link
                        to="#"
                        className="text-success"
                        onClick={() => handleRowClick(order)}
                      >
                        <i
                          className="mdi mdi-pencil font-size-18"
                          id="edittooltip"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="edittooltip"
                        >
                          {getPermissionHandler("edit")}
                        </UncontrolledTooltip>
                      </Link>
                    ) : (
                      ""
                    )}
                  </>
                ) : null}
                {order.status != "VERIFIED" &&
                getPermissionHandler("delete") ? (
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => handleDeleteOrder(order)}
                  >
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                    />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      {getPermissionHandler("delete")}
                    </UncontrolledTooltip>
                  </Link>
                ) : (
                  "NA"
                )}
              </>
            </div>
          </>
        ),
      })
    }
    cols.push(
      {
        dataField: "investment.project_name",
        text: "Project Name",
        sort: true,
      },
      { dataField: "user.user_pan.name", text: "Name on PAN", sort: true },
      {
        dataField: "user.email",
        text: "Email",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => <div>{maskEmail(cellContent)}</div>,
      },
      {
        dataField: "user.phone",
        text: "Phone",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <div>{maskPhoneNumber(cellContent)}</div>
        ),
      },
      { dataField: "user.user_name", text: "User Name", sort: true },
      {
        dataField: "start_date",
        text: "Financial Year",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, row) => {
          const year = moment(cell).format("YYYY")
          return `${year} - ${Number(year) + 1}`
        },
      },
      {
        dataField: "file_url",
        text: "NIL TDS Declaration",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, order) => (
          <>
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-primary"
                onClick={() => handleDoc(cellContent)}
              >
                <svg viewBox="0 0 24 24" fill={"#556ee6"} width="20px">
                  <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                </svg>
              </Link>
            </div>
          </>
        ),
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => {
          const status = kycStatusType.find(obj => obj.id == cellContent)
          return (
            <span
              className={`badge badge-pill font-size-12 badge-soft-${
                status ? status?.colorClassName : "secondary"
              }`}
            >
              {status?.statusText}
            </span>
          )
        },
      },
      {
        dataField: "comment",
        text: "Comment",
        sort: true,
      }
    )

    if (!isDist && !isRm) {
      cols.push(
        {
          dataField: "user.relManager.user_name",
          text: "RM Name",
          sort: true,
        },
        {
          dataField: "user.relManager.email",
          text: "RM Email",
          sort: true,
        },
        {
          dataField: "user.relManager.phone",
          text: "RM Phone",
          sort: true,
        }
      )
    }
    if (!isDist) {
      cols.push(
        {
          dataField: "user.createdBy.user_name",
          text: "Distributor Name",
          sort: true,
        },
        {
          dataField: "user.createdBy.email",
          text: "Distributor Email",
          sort: true,
          // // eslint-disable-next-line react/display-name
          // formatter: (cellContent, row) => <div>{maskEmail(cellContent)}</div>,
        },
        {
          dataField: "user.createdBy.phone",
          text: "Distributor Phone",
          sort: true,
          // // eslint-disable-next-line react/display-name
          // formatter: (cellContent, row) => (
          //   <div>{maskPhoneNumber(cellContent)}</div>
          // ),
        }
      )
    }

    cols.push({
      dataField: "created_at",
      text: "Created At",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => handleValidDate(cell),
    })
    return cols
  }

  const handleDoc = async file => {
    setLoading(true)
    try {
      const response = await axiosInstance.post("/get-signed-url", {
        fileName: file,
      })
      if (response) {
        window.open(response?.data)
      }
    } catch (error) {
      toast.error(error?.message)
    }
    setLoading(false)
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const handleUserSearch = async e => {
    setSearchValue(e)
    if (e.length >= 3) {
      setLoading(true)
      if (e) {
        const usersRes = await axiosInstance.get(
          `/users?$sort[created_at]=-1&$or[0][permissions]=USER&$or[1][permissions]=OPS_USER
              &name=${e}&$limit=50&$skip=0&is_registered=true&pan_type=Person&pan_type=Hindu Undivided Family (HUF)${
            isRm ? `&relManagerId=${localUser.id}` : ""
          }${isDist ? `&createdById=${localUser.id}` : ""}`
        )
        const users = usersRes?.data?.data || usersRes?.data
        if (users) {
          setUsers(users)
        } else {
          toast.error("something went wrong")
        }
      } else {
        setUsers([])
      }
      setLoading(false)
    }
  }

  const handleValidOrderSubmit = async (e, values) => {
    try {
      if (!formValues.investment) {
        toast.error("Please select investment")
        return
      }
      if (!formValues.user) {
        toast.error("Please select user")
        return
      }
      if (!formValues.financialYear) {
        toast.error("Please select financial year")
        return
      }
      if (!isEdit) {
        if (!formValues.file) {
          toast.error("Please select file")
          return
        }
      }

      setLoading(true)
      let file_url = formValues.file_url
      if (formValues.file) {
        const formData = new FormData()
        formData.append("file", formValues.file)
        formData.append("investmentId", formValues.investment.id)
        const uploadResponse = await axiosInstance.post(
          `/file-upload/?type=nil_tds_certificate`,
          formData
        )
        file_url = uploadResponse?.data[0].name
      }
      const payload = {
        investmentId: formValues.investment.id,
        userId: formValues.user.id,
        start_date: formValues.financialYear.startDate,
        end_date: formValues.financialYear.endDate,
        file_url,
        status: "IN_VERIFICATION",
      }

      setFormValues({
        ...formValues,
        file_url,
      })
      const response = isEdit
        ? await axiosInstance.patch(
            `/investor-nil-tds-certificate/${selectedRow.id}`,
            payload
          )
        : await axiosInstance.post("/investor-nil-tds-certificate", payload)
      toggle()
      toast.success(`${isEdit ? "Updated" : "Created"} Successfully`)
      await masterData()
    } catch (error) {
      if (error?.response?.data?.errors[0]?.validatorKey == "not_unique") {
        toast.error(
          "NIL TDS Declaration already exists for this project, user and financial year"
        )
      } else {
        const msg =
          humanize(error?.response?.data?.errors[0]?.message) || error.message
        toast.error(msg)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleUploadClick = () => {
    setFormValues({
      investment: null,
      user: null,
      financialYear: null,
      file: null,
      file_url: null,
    })
    setIsEdit(false)
    toggle()
  }

  const sortedFinancialYear = () => {
    const years = generateFinancialYearList()
    return years.sort((a, b) => {
      return new Date(b.startDate) - new Date(a.startDate)
    })
  }

  const handleVerify = async (e, values) => {
    try {
      setLoading(true)
      const payload = {
        status: selectedRow.status,
        comment: selectedRow.comment,
      }
      const response = await axiosInstance.patch(
        `/investor-nil-tds-certificate/${selectedRow.id}`,
        payload
      )
      toast.success("Updated Successfully")
      verifyToggle()
      await masterData()
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setLoading(false)
    }
  }

  const getPermissionHandler = type => {
    switch (type) {
      case "view":
        return (
          accessControlData?.permissions?.KYC_MANAGEMENT_NIL_TDS_VIEW ||
          accessControlData?.permissions
            ?.SALES_AND_INVESTOR_SERVICE_MY_INVESTOR_NIL_TDS_CERTIFICATE_VIEW ||
          accessControlData?.permissions
            ?.DISTRIBUTOR_PORTAL_NIL_TDS_CERTIFICATE_VIEW
        )
      case "upload":
        return (
          accessControlData?.permissions?.KYC_MANAGEMENT_NIL_TDS_ADD ||
          accessControlData?.permissions
            ?.SALES_AND_INVESTOR_SERVICE_MY_INVESTOR_NIL_TDS_CERTIFICATE_UPLOAD ||
          accessControlData?.permissions
            ?.DISTRIBUTOR_PORTAL_NIL_TDS_CERTIFICATE_UPLOAD
        )
      case "update":
        return accessControlData?.permissions?.KYC_MANAGEMENT_NIL_TDS_UPDATE
      case "edit":
        return (
          accessControlData?.permissions?.KYC_MANAGEMENT_NIL_TDS_EDIT ||
          accessControlData?.permissions
            ?.SALES_AND_INVESTOR_SERVICE_MY_INVESTOR_NIL_TDS_CERTIFICATE_EDIT ||
          accessControlData?.permissions
            ?.DISTRIBUTOR_PORTAL_NIL_TDS_CERTIFICATE_EDIT
        )
      case "delete":
        return (
          accessControlData?.permissions?.KYC_MANAGEMENT_NIL_TDS_DELETE ||
          accessControlData?.permissions
            ?.SALES_AND_INVESTOR_SERVICE_MY_INVESTOR_NIL_TDS_CERTIFICATE_DELETE ||
          accessControlData?.permissions
            ?.DISTRIBUTOR_PORTAL_NIL_TDS_CERTIFICATE_DELETE
        )
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <MetaTags>
          <title>Earnnest-Admin</title>
        </MetaTags>
        {getPermissionHandler("view") ? (
          <Container fluid>
            <Breadcrumbs breadcrumbItem={accessControlData?.pageName} />
            <Row>
              <Col xs="12">
                <Row className="mb-2 row justify-content-between">
                  <Col md={2}>
                    <select
                      className="form-select w-75"
                      value={limit}
                      onChange={e => handleFilterChange(e, "limit")}
                    >
                      {[10, 30, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="col-auto">
                    {getPermissionHandler("upload") ? (
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded mb-2 me-2"
                        onClick={handleUploadClick}
                      >
                        <i className="mdi mdi-plus me-1" />{" "}
                        {getPermissionHandler("upload")}
                      </Button>
                    ) : null}
                  </div>
                </Row>
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={Columns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2 row">
                            <Col md={3}>
                              <div className="position-relative">
                                <label>Search User</label>
                                <input
                                  onChange={e => handleFilterChange(e, "user")}
                                  id="search-bar-0"
                                  type="text"
                                  className="form-control rounded custom-input-height"
                                  placeholder={`Name, Email, Phone, Pan Name or Pan`}
                                  value={userFilter || ""}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Project Name</label>
                                <ReactSelect
                                  users={investments}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "projectType")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["project_name"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Financial Year</label>
                                <ReactSelect
                                  users={sortedFinancialYear()}
                                  setSelectedOption={
                                    setSelectedFinacialYearFilter
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["id"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                            {!isDist ? (
                              <Col md={3}>
                                <div className="mb-3">
                                  <label>Distributor</label>
                                  <ReactSelect
                                    users={distributorList}
                                    setSelectedOption={e =>
                                      handleFilterChange(e, "distributor")
                                    }
                                    multiOptionLabel={true}
                                    optionLabelKeys={[
                                      "user_name",
                                      "email",
                                      "phone",
                                    ]}
                                    isClearable={true}
                                  />
                                </div>
                              </Col>
                            ) : null}
                            {!isRm && !isDist ? (
                              <Col md={3}>
                                <div className="mb-3">
                                  <label>Relationship Manager</label>
                                  <ReactSelect
                                    users={rmList}
                                    setSelectedOption={e =>
                                      handleFilterChange(e, "rm")
                                    }
                                    multiOptionLabel={true}
                                    optionLabelKeys={[
                                      "user_name",
                                      "email",
                                      "phone",
                                    ]}
                                    isClearable={true}
                                  />
                                </div>
                              </Col>
                            ) : null}
                            <Col md={3}>
                              <div className="mb-3">
                                <label>Status</label>
                                <ReactSelect
                                  users={kycStatusType.filter(
                                    item => item.id != "NOT_SUBMIT"
                                  )}
                                  setSelectedOption={e =>
                                    handleFilterChange(e, "status")
                                  }
                                  multiOptionLabel={true}
                                  optionLabelKeys={["statusText"]}
                                  isClearable={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <BootstrapTable
                                keyField="id"
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                columns={Columns()}
                                data={data}
                                wrapperClasses={"table-responsive mb-4"}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                {...toolkitProps.baseProps}
                              />
                            </Col>
                          </Row>
                          <Row className="justify-content-md-space-between justify-content-center align-items-center">
                            <Col className="col-12 col-md-auto mb-3">
                              {`Showing ${total ? skip + 1 + " to" : ""} ${
                                limit > total || limit + skip > total
                                  ? total
                                  : limit + skip
                              } rows of ${total}
                    `}
                            </Col>
                            <Col>
                              <Row className="justify-content-md-end justify-content-center align-items-center">
                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(limit)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<<"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() => handlePrevious(skip)}
                                      disabled={currentPage == 1}
                                    >
                                      {"<"}
                                    </Button>
                                  </div>
                                </Col>
                                <Col className="col-md-auto d-none d-md-block">
                                  Page{" "}
                                  <strong>{`${
                                    currentPage ? currentPage : 1
                                  } of ${totalPage ? totalPage : 1}`}</strong>
                                </Col>
                                <Col className="col-md-auto">
                                  <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={total == 0 ? 1 : totalPage}
                                    value={currentPage || 1}
                                    defaultValue={1}
                                    onChange={onChangePagination}
                                    disabled={total == 0}
                                  />
                                </Col>

                                <Col className="col-md-auto">
                                  <div className="d-flex gap-1">
                                    <Button
                                      color="primary"
                                      onClick={() => handleNext(skip)}
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">"}
                                    </Button>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        handleNext((totalPage - 2) * limit)
                                      }
                                      disabled={
                                        currentPage == totalPage || total == 0
                                      }
                                    >
                                      {">>"}
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <Unauthorized />
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {isEdit ? "Edit" : "Upload"} NIL TDS Declaration
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidOrderSubmit}>
            <Row>
              <div className="mb-3">
                <label>Project Name</label>
                <ReactSelect
                  users={investments}
                  setSelectedOption={e => {
                    setFormValues({
                      ...formValues,
                      investment: e,
                    })
                  }}
                  selectedOption={formValues.investment}
                  multiOptionLabel={true}
                  optionLabelKeys={["project_name"]}
                  isDisabled={isEdit}
                />
              </div>
              <div className="mb-3">
                <label>User</label>
                <ReactSelect
                  users={users}
                  searchValue={searchValue}
                  setSearchValue={handleUserSearch}
                  selectedOption={formValues.user}
                  setSelectedOption={e => {
                    setFormValues({
                      ...formValues,
                      user: e,
                    })
                  }}
                  multiOptionLabel={false}
                  optionLabelKeys={[
                    "user_pan?.name",
                    "email",
                    "phone",
                    "user_pan?.pan_number",
                  ]}
                  isDisabled={isEdit}
                />
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label>Financial Year</label>
                  <ReactSelect
                    users={sortedFinancialYear()}
                    setSelectedOption={e => {
                      setFormValues({
                        ...formValues,
                        financialYear: e,
                      })
                    }}
                    selectedOption={formValues.financialYear}
                    multiOptionLabel={true}
                    optionLabelKeys={["id"]}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">
                  Select NIL TDS Declaration File
                </label>
                <Input
                  className="form-control"
                  type="file"
                  accept=".pdf"
                  id="formFile"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={e => {
                    setFormValues({
                      ...formValues,
                      file: e.target.files[0],
                    })
                  }}
                />
                {formValues.file_url && !formValues.file ? (
                  <div className="mt-2">
                    <Link
                      to="#"
                      className="text-primary d-flex gap-1 align-items-center"
                      onClick={() => handleDoc(formValues.file_url)}
                    >
                      <svg viewBox="0 0 24 24" fill={"#556ee6"} width="20px">
                        <path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
                      </svg>
                      {formValues.file_url.split("/").pop()}
                    </Link>
                  </div>
                ) : null}
              </div>
            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    {isEdit ? "Update" : "Submit"}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
      {/* Verify Modal */}
      <Modal isOpen={verifyModel} toggle={verifyToggle}>
        <ModalHeader toggle={verifyToggle} tag="h4">
          Update Status
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleVerify}>
            <Row form>
              <div className="mb-3">
                <AvField
                  name="approval_status"
                  label="Status"
                  type="select"
                  className="form-select"
                  errorMessage="Invalid Status"
                  onChange={e =>
                    setSelectedRow({ ...selectedRow, status: e.target.value })
                  }
                  value={selectedRow.status || ""}
                  validate={{
                    required: { value: true },
                  }}
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="IN_VERIFICATION">In Verification</option>
                  <option value="VERIFIED">Verified</option>
                  <option value="RE_SUBMIT">Re Submit</option>
                </AvField>
              </div>
              {selectedRow.status == "RE_SUBMIT" ? (
                <div className="mb-3">
                  <AvField
                    name="comment"
                    label="Comment"
                    type="textarea"
                    errorMessage="Invalid Value"
                    onChange={e =>
                      setSelectedRow({
                        ...selectedRow,
                        comment: e.target.value,
                      })
                    }
                    value={selectedRow.comment || ""}
                    validate={{
                      required: { value: true },
                    }}
                  ></AvField>
                </div>
              ) : null}
            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default NilTdsCertificate
