import React from "react"
import PropTypes from "prop-types"
import { Col, Row, Button } from "reactstrap"
import { picUrl } from "constants/common"

const AttachmentPreview = ({ files = [], readOnly = false, onFileDelete }) => {
  const renderFilePreview = (file, index) => {
    const fileUrl =
      file instanceof File
        ? URL.createObjectURL(file)
        : picUrl + (file.path || file.file_url || "")

    const fileName =
      file instanceof File ? file.name : file.name || file.file_name

    const isImage =
      file.type?.startsWith("image/") || file.mime_type?.startsWith("image/")

    return (
      <Col key={index} xs={4} className="mb-3 position-relative">
        {isImage ? (
          <img
            src={fileUrl}
            alt={fileName}
            onClick={() => window.open(fileUrl)}
            className="img-fluid rounded"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        ) : (
          <div
            className="d-flex flex-column align-items-center justify-content-center p-3 border rounded cursor-pointer"
            onClick={() => window.open(fileUrl)}
            style={{ cursor: "pointer" }}
          >
            <i
              className="mdi mdi-file-document-outline mb-2"
              style={{ fontSize: "26px" }}
            />
            <p className="text-center text-truncate w-100">{fileName}</p>
          </div>
        )}

        {!readOnly && (
          <Button
            color="danger"
            className="position-absolute rounded-circle"
            style={{
              width: "25px",
              height: "25px",
              padding: 0,
              top: "10px",
              right: "20px",
            }}
            onClick={e => {
              e.stopPropagation()
              onFileDelete(file)
            }}
            aria-label="Delete attachment"
          >
            <i className="mdi mdi-close font-size-16" />
          </Button>
        )}
      </Col>
    )
  }

  if (!files || files.length === 0) return null

  return (
    <Col className="col-12 mb-3">
      <Row>{files.map(renderFilePreview)}</Row>
    </Col>
  )
}

AttachmentPreview.propTypes = {
  files: PropTypes.array,
  onFileDelete: PropTypes.func,
  readOnly: PropTypes.bool,
}

export default AttachmentPreview
